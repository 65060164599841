.sidebar-left,
.sidebar-right {
    color: $body-color;
    padding-top: 120px;
    z-index: $zindex-fixed;
}

.sidebar-page-title {
    left: 40px;
    position: absolute;
    top: 55px;
}

.sidebar-left .sidebar-page-title {
    left: 120px;
}

.btn-layered {
    background: none;
    border: 0;
    margin: 0;
    padding: 0;
}
