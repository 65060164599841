.sidebar .network-create-account-form {
    margin-left: 3rem;
    margin-right: 3rem;
}

.user-profiles {
    .user-profiles-wrap {
        margin-bottom: 20px;
    }

    .user-profile__chip {
        display: inline-flex;
        background: #e0e0e0;
        border-radius: 16px;
        padding: 5px 12px;
        cursor: pointer;
        margin-right: 5px;

        svg {
            width: 10px;
            margin-left: 10px;
            position: relative;
            top: 5px
        }
    }

    .loading {
        margin-left: 10px;
    }

    .user-profiles__search {
        margin-bottom: 20px;
    }
}

.user-profiles__button-wrap {
    button {
        color: white;
        margin-bottom: 20px;
    }

    .delete-btn {
        background: #c50000;
        margin-left: 10px;
    }
}

.account-home {
    .account-home__profile {
        display: flex;
        align-items: center;

        .account-home__profile__icon {
            display: flex;
            font-size: 100px;
            margin-right: 20px;

            .profile-picture {
                background-size: cover;
                width: 150px;
                height: 150px;
            }
        }
        
        .account-home__profile__info {
            font-weight: 600;
            font-size: 14px;

            div {
                margin-bottom: 10px;
            }

            p {
                margin-bottom: -5px;
            }

            span {
                color: $orange;
                font-size: 20px;
            }
        }
    }

    .account-home__menu {
        margin-top: 40px;

        a, .link {
            cursor: pointer;
            font-size: 20px;
            background: $orange;
            color: $white;
            display: block;
            padding: 10px;
            margin-bottom: 20px;
            border-radius: 5px;

            svg {
                margin-right: 10px;
            }

            .notifications-count {
                background: white;
                border-radius: 50%;
                color: $orange;
                width: 25px;
                height: 25px;
                font-weight: 600;
                margin-left: 10px;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                font-size: 16px;
            }
        }
    }
}

.user-profile-form {
    button {
        width: 140px;
        height: 40px;

        svg {
            color: white;
        }
    }
}

.data-import-btn {
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
        margin-left: 15px;
    }
}

.data-upload-form {
    select {
        font-size: 16px;
    }
    
    font-size: 16px;
    font-family: "acumin-pro-semi-condensed", $font-family-sans-serif;
}

.data-upload-form__errors {
    height: 150px;
    overflow-y: scroll;
}

.notifications {
    .notifications__header {
        margin-bottom: 15px;

        span {
            color: $orange;
            font-weight: 600;
        }
    }

    .notifications__list {
        margin-bottom: 15px;
    }

    .notification {
        border: 1px solid #b1b9cb;
        border-radius: .25rem;
        padding: 10px;
        padding-bottom: 0px;
        margin-bottom: 14px;

        &.notification--unread {
            border: 2px solid $orange;

            .notification__date {
                color: $orange;
            }
        }
    }

    .notification__top {
        display: flex;
        justify-content: space-between;
        padding-bottom: 5px;
        border-bottom: 1px solid #b1b9cb;
        margin-bottom: 15px;
        padding-right: 5px;
    }
    
    .notification__actions {
        display: flex;
    }

    .notification__date {
        font-weight: 600;
    }

    .notification__read {
        color: $orange;
        cursor: pointer;
        margin-right: 10px;

        svg {
            width: 16px;
        }
    }

    .notification__message {
        margin-bottom: 10px;
    }

    .notification__delete {
        color: #bf2323;
        font-size: 20px;
        position: relative;
        top: -4px;
        cursor: pointer;
    }
}

.data-upload-form {
    .data-upload-form__instructions {
        margin-bottom: 40px;

        .btn {
            color: white;
        }

        div {
            margin-bottom: 40px;
        }
    }

    #data-description {
        margin-bottom: 40px;
    }
}

.code {
    background: lightgray;
    font-family: monospace;
    font-size: 12px;
    word-wrap: break-word;
    padding: 10px;
}