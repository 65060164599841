.courses-page {
    @media (max-width: 767px) {
        .main-column-content-inner {
            padding-left: 20px !important;
            padding-right: 20px !important;
        }
    }
    .sidebar-right {
        padding-top: 50px;
    }

    h2 {
        text-align: left !important;
    }

    table.dataTable {
        table-layout: fixed;

        thead {
            background: $orange;
            color: white;

            th {
                border-bottom: 0px;
                font-size: 18px;
                padding: 10px;

                select {
                    max-width: 120px;
                }
            }
        }

        @media (max-width: 991px) {
            th{
                &::before {
                    content: '';
                    display: block; 
                    width: 150px;
                }
                
                &:last-of-type::before {
                    width: 300px
                }
            }
            
        }
    }

    .top {
        #DataTables_Table_0_filter {
            display: flex;
            align-items: center;
            padding-top: 30px;
            z-index: 1000;
            position: relative;

            label {
                display: flex;
                align-items: center;
            }
        }
    }

    .bottom {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .paging {
            display: flex;
            flex-direction: column;
        }

        #DataTables_Table_0_length {
            display: flex;
            align-items: center;

            label {
                width: 150px;
                display: flex;
                align-items: center;
                margin-top: 10px;
                
                select {
                    margin: 0px 10px;
                }
            }
        }
    }

    .dataTables_scrollBody::-webkit-scrollbar {
        display: none;
    }

    .dataTables_wrapper .dataTables_paginate .paginate_button {
        padding: 0px;

        &:hover:not(.disabled, .active) {
            opacity: 0.5;
            background: none;
            border: 1px solid white;
        }
    }

    .courses-display-as {
        position: relative;
        top: 55px;
        z-index: 1;

        @media (max-width: 640px) {
            top: 25px;
        }

        .filter-page-header-btn {
            margin-bottom: 0px;
            &.grey {
                background: rgb(192, 192, 192);

                &::before {
                    background: grey;
                }
            }
        }
    }

    .courses-map {
        position: relative;
        z-index: 1;
        height: 500px;
        width: 100%;
        top: 75px;

        #map {
            position: absolute;
            top: 0;
            bottom: 0;
            width: 100%;
        }
    }

    .courses-orange-box {
        color: white;
        background: $orange;
        font-weight: 100;
        padding: 15px;
        margin-top: 60px;

        p {
            margin-bottom: 0px;
        }
    }
}

.courses-title-page {
    .button-wrap {
        flex-direction: column;

        div {
            width: 100% !important;
            max-width: 100%;
            padding: 5px 15px !important;
        }
    }

    .continent-select {
        display: block !important;
    }
}

.course-modal {
    .modal-header {
        justify-content: space-between;
        border-bottom: none;
        padding-bottom: 0;
    }
    
    .modal-body {
        max-height: 600px;
        overflow-y: auto;

        h4 {
            color: $orange;
        }
    }

    .courses-data div {
        margin-bottom: 15px;
    }
}
