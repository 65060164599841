.search-input-wrapper {
    position: relative;

    .column-search & {
        margin-bottom: 30px;
    }

    .search-icon {
        @include font-size(20px);
        color: #240708;
        display: inline-block;
        position: absolute;
        left: 1rem;
        top: 35px;
    }

    .search-input-reset, .search-input-save, .show-data-index-btn, .show-resource-tree-btn {
        cursor: pointer;
        color: $primary;
        position: absolute;
        right: 0;
        font-weight: 600;

        svg {
            width: 12px;
            margin-left: 5px;
        }
    }
}

.search-input {
    padding-left: 3rem;
}

.search-criteria-list {
    li {
        background: rgba(#b1b9cb, 0.3);
        color: #240708;
        border-radius: 4px;
        font-family: "acumin-pro-semi-condensed", $font-family-sans-serif;
        font-weight: 500;
        letter-spacing: (10 / 1000) * 1em;
        font-size: 14px;

        .close-icon {
            @include font-size(14px);
            color: #808e92;
            cursor: pointer;
            user-select: none;
        }

        &.vector > span,
        &.species > span {
            font-style: italic;
        }
    }
}

.common-search-list {
    li {
        font-family: "acumin-pro-semi-condensed", $font-family-sans-serif;
        font-weight: 500;

        em {
            cursor: pointer;
            transition: color 200ms ease-in;
        }

        &:hover {
            em {
                color: $orange;
            }
        }
    }
}

.search-autocomplete {
    background: #fff;
    border: 1px solid #b1b9cb;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    padding: 0;
    position: relative;
    top: -3px;

    svg {
        padding: 10px 0px;
    }
}

.search-autocomplete-list {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
        a,
        button {
            @include font-size(18px);
            background: #fff;
            border: 0;
            border-radius: 0;
            color: #a9adb2;
            display: block;
            font-family: "acumin-pro-semi-condensed", $font-family-sans-serif;
            font-weight: 500;
            margin: 0;
            padding: 1rem;
            text-align: left;
            text-decoration: none;

            .data-name {
                display: inline-block;
                margin-bottom: 0;
                margin-left: .5rem;
                margin-top: 0;
                padding:0;

                &.vector,
                &.vectorspecies {
                    font-style: italic;
                }
            }

            &:hover {
                background: rgba(#bcbec2, 0.12);
                color: #a9adb2;
            }

            span.highlight {
                color: #4F3738;
            }
        }
    }
}

.search-back-to-top {
    background: $primary;
    color: white;
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 15px;
    border-radius: 5px;
    position: fixed;
    bottom: 10px;
    right: 25px;
    cursor: pointer;
}

.datasets-link {
    @include font-size(20px);
    font-weight: 600;
    user-select: none;
    text-decoration: underline;
}


.column-search .main-column-content-inner {
    padding: 0px !important;

    .global-search-wrapper {
        border-top: 1px solid #ebebeb;
    }

    .search-input-wrapper {
        margin-top: 120px;
        padding: 0px 3rem;

        .search-icon {
            left: 4rem;
        }
    }

    .search-popular, .search-criteria {
        padding: 0px 3rem;
    }

    .search-criteria {
        margin-top: 3rem;
    }

    .search-input-reset {
        right: 3rem;
    }

    .search-input-save {
        right: 3rem;
        bottom: -3rem;
    }

    .info-list {
        .list-inline-item.resource-search-item {
            a {
                display: flex;
                align-items: center;

                svg {
                    font-size: 20px;
                    color: black;
                }
            }

            &:hover {
                a {
                    svg {
                        color:white;
                    }
                }
            }
        }
    }
}