@import url("https://use.typekit.net/iub2phd.css");
@import url("https://use.typekit.net/wxe4etj.css");

body {
    font-family: acumin-pro-condensed, sans-serif;
    font-weight: 400;
    font-style: normal;
    background-color:$dark-blue;
    background-attachment: fixed;
    // display: flex;
    // flex-direction: column;
    // max-width: 100vw;
    // min-height: 100%;
    overflow: hidden;
    top: 0 !important;
}

// html {
//     min-height: 100%;
//     overflow-x: hidden;
//     //-webkit-overflow-scrolling: touch;
//     max-width: 100vw;
// }

html, body {
  margin: 0;
  background: $dark-blue;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html, body {
    color: map-get($theme-colors, "secondary");
}

.lead {
    font-size: 2rem;
}

h1 {
    font-size: 6rem;
    color: map-get($theme-colors, "primary");
}

h4 {
    letter-spacing: (20 / 1000) * 1em;
}

.fill {
    width: 100%;
    height: 100%;
    margin: 0;
}

.no-select {
    user-select: none;
}

.cursor-grab {
    cursor: grab;
}

.cursor-grabbing {
    cursor: grabbing;
}

.cursor-pointer {
    cursor: pointer;
}

.orange {
    color: $orange;
}

.blue {
    color: $blue;
}

.green {
    color: $green;
}

.font-smooth {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.alert {
    font-family: "acumin-pro-semi-condensed", $font-family-sans-serif;
}

.globe-wrapper {
    overflow: hidden;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: left 1s, right 1s;
}

.globe-progress-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    bottom: 0;
}
