
.profile-header {
    border-top-left-radius: $border-radius;
    position: relative;
    //top: 0;
    width: 100%;
    z-index: $zindex-fixed;
    padding: 1.5rem;

    @media (max-width: 575px) {
        padding: 0.5rem;
    }
}

.profile-title {
    color: #fff;
    font-weight: 700;
    text-indent: 1rem;
    text-transform: uppercase;
    letter-spacing: (20 / 1000) * 1em;
    margin-bottom: 0;

    @media (max-width: 768px) {
        margin-left: 90px;
        margin-bottom: 3px;
    }
}

.close-link {
    background: none;
    border: 0;
    color: #fff;
    font-size: .875rem;
    font-weight: 700;
    letter-spacing: (80 / 1000) * 1em;
    text-transform: uppercase;

    &:hover {
        background: none;
        border: 0;
        color: #fff;
        text-decoration: none;
    }
}

.profile-tabs {
    position: relative;
    //top: 81px;
    width: 100%;
    z-index: $zindex-fixed;
    background-color: #fff;
    border-bottom: 1px solid $light-grey;

    .nav-link {
        color: #000;
        font-size: 0.875rem;
        font-weight: 600;
        letter-spacing: (80 / 1000) * 1em;
        text-align: center;
        text-transform: uppercase;

        @media (max-width: 575px) {
            padding: 10px;
        }

        &.active {
            border-bottom: 2px solid map-get($theme-colors, "primary");
        }
    }

    .search-input-save {
        bottom: -3rem;
    }
}

#profile-tabs-tabpane-resources,
#profile-tabs-tabpane-data {
    @media (max-width: 575px) {
        .search-input {
            padding: 2px 0 4px 3rem;
            height: initial;
        }
        .search-input-wrapper .search-icon {
            top: 9px;
            font-size: 1rem;
        }
    }
}

.tab-content {
    color: $dark-blue;
    margin: 0;
    padding: 0;

    #profile-tabs-tabpane-profile {
        .col {
            max-width: calc(100% - 100px);
        }
    }
}

.tab-pane {
    font-family: "acumin-pro-semi-condensed", $font-family-sans-serif;
    font-size: 1rem;
    font-weight: 400;
    height: calc(100vh - 153px);
    overflow-y: scroll;
    margin: 0;

    @media (max-width: 767px) {
        height: initial;
        overflow-y: auto;
    }
}

.profile-sidebar {
    border-bottom: 1px solid $light-grey;
    border-right: 1px solid $light-grey;
    display: block;
    position: relative;
    width: 100px;

    @media (max-width: 575px) {
        width: 60px;
    }
}

.sideways-text {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    color: map-get($theme-colors, "primary");
    display: block;
    font-family: "acumin-pro-condensed", $font-family-sans-serif;
    font-size: .875rem;
    font-weight: 700;
    letter-spacing: (60 / 1000) * 1em;
    text-align: center;
    text-transform: uppercase;
    // text-orientation: sideways;
    // writing-mode: vertical-rl;
    transform: rotate(-90deg) translate(-50%, -50%) translate3d(0, 0, 0) perspective(1px) scale(1.1);
    transform-origin: 0 0; // top left
}

.profile-inner {
    border-bottom: 1px solid $light-grey;
    padding: 3rem;

    .profile-content-wrap {
        height: 150px;
        overflow: hidden;
        margin-bottom: 20px;

        &.read-more {
            height: 100%;
        }
    }

    .profile-read-more {
        cursor: pointer;
        color: $orange;
    }
}

.info-list {
    .list-inline-item {
        background-color: transparent;
        border: 1px solid $light-grey;
        border-radius: 2px;
        font-family: "acumin-pro-condensed", $font-family-sans-serif;
        font-size: .9375rem;
        font-weight: 500;
        letter-spacing: 0;
        margin-bottom: 0.5rem;
        transition: background-color 100ms $material-decelerate;
    }
}

.orange-item {
    cursor: pointer;

    a {
        color: $dark-blue;
        display: block;
        padding-bottom: 0.5rem;
        padding-left: 0.75rem;
        padding-right: 1rem;
        padding-top: 0.5rem;
    }

    svg {
        color: $orange;
    }

    &:hover {
        background-color: $orange;
        color: #fff;
        
        a {
            color: #fff;
            text-decoration: none;
        }

        svg {
            color: #fff;
        }
    }
}

.blue-item {
    cursor: pointer;

    svg {
        color: $blue;
    }

    &:hover {
        background-color: $blue;
        color: #fff;

        svg {
            color: #fff;
        }
    }
}

.green-item {
    cursor: pointer;

    svg {
        color: $green;
    }

    &:hover {
        background-color: $green;
        color: #fff;

        svg {
            color: #fff;
        }
    }
}

.resource {
    @include font-size(18px);
    border: 1px solid #b1b9cb;
    border-radius: .25rem;
    font-family: "acumin-pro-condensed", $font-family-sans-serif;
    font-weight: 500;
    letter-spacing: (20 / 1000) * 1em;
}

.profile-page-wrap {
    .loading-loader {
        display: none;
    }
}

.profile-page-wrap.loading {
    position: relative;

    .loading-loader {
        display: flex;
        align-items: center;
        justify-content: center;
        background: white;
        position: absolute;
        height: 100%;
        width: 100%;
        top:0;
        left:0;
        z-index: 1;
    }
}

.profile-user-content {
    display: flex;

    @media (max-width: 575px) {
        display: block;
    }

    .profile-user-content__profile-picture {
        margin-right: 2rem;

        .profile-user-content__image {
            border: 1px solid $orange;
            border-radius: 5px;
            width: 120px;
            height: 120px;
            display: flex;
            align-items: center;
            justify-content: center;

            .profile-picture {
                background-size: cover;
                width: 100%;
                height: 100%;
            }

            svg {
                font-size: 80px;
            }
        }

        .profile-user-content__social {
            a {
                margin-left: 4px;
                color: #387998;
                text-decoration: underline;

                &:hover {
                    color: $orange;
                }
            }

            .user-social__researchgate {
                svg {
                    color: #40ba9b;
                }
            }
            .user-social__twitter {
                svg {
                    color: #1da1f2;
                }
            }
            .user-social__linkedin {
                svg {
                    color: #2977c9;
                }
            }
            .user-social__orcid {
                svg {
                    color: #a6ce39;
                }
            }
        }

        .profile-user-content__message {
            margin-top: -30px;
        }
    }
}

.column-profile {
    #profile-tabs-tabpane-resources {
        .search-input-save {
            bottom: -2.75rem;
        }
        .search-input-reset{
            bottom: -4rem;
        }
    }
    .profile-title {
        margin-left: 0px;
    }

    @media (max-width: 500px) {
        .category-criteria .resource-category-selector {
            .dropdown-menu.show a {
                white-space: initial;
            }
        }
        
    }

    @media (max-width: 400px) {
        .category-criteria {
            flex-direction: column;
    
            .resource-category-selector {
                justify-content: initial;
                
                .dropdown-menu.show a {
                    white-space: initial;
                }
            }
        }
    }
}

.message-modal {
    h3 {
        color: $orange;
    }
}
