@media all and (-ms-high-contrast:none) {
    .basket-items .basket-item__remove svg {
        margin-top: 7px;
    }
    .basket-item .basket-item__title svg {
        min-width: 12px;
    }
    .filter-page-menu {
        width: 125px;
    }
    .courses-title-page .main-column-content .title-page .button-wrap.row {
        display: block;
    }
}