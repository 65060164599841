@import "./theme";

@import "~bootstrap/scss/bootstrap.scss";

// Material Design
// https://material.io/design/motion/speed.html#easing
$material-standard:   cubic-bezier(0.4, 0.0, 0.2, 1);
$material-decelerate: cubic-bezier(0.0, 0.0, 0.2, 1);
$material-accelerate: cubic-bezier(0.4, 0.0,   1, 1);

@import "./Global";
@import "./App";
@import "./TitlePage";
@import "./InfoPage";
@import "./Data";
@import "./Network";
@import "./News";
@import "./Resources";
@import "./Sidebar";
@import "./SideMenu";
@import "./forms";
@import "./search";
@import "./NotFoundPage";
@import "./ResourceMatrixTable";
@import "./Institutions";
@import "./Courses";
@import "./FilterPage";
@import "./ie11";
