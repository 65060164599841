.title-page {
    position: relative;
    width: 100%;

    .courses-title-page & {
        padding-left: 1.5rem !important;
        margin-top: 0px;
        padding-top: 140px !important;

        @media (max-width: 1380px) {
            padding-top: 250px !important;
        }

        @media (max-width: 1200px) {
            padding-top: 390px !important;

            .title-page-logo {
                display: none;
            }
        }
    }

    @media (max-height: 450px) {
        margin-top: 60px;
    }

    @media (max-width: 768px) {
        padding-left: 1.5rem !important;
        margin-top: 160px;
    }

    @media (max-width: 576px) {
        margin-top: 0px;
    }

    .main-title {
        font-size: 6vw;
        letter-spacing: -0.32px;
        background: linear-gradient(to right, rgb(247, 78, 80), map-get($theme-colors, "primary"));
        display: inline;
        background-clip: text;
        text-fill-color: transparent;
        -webkit-text-fill-color: transparent;
        font-weight: 700;
        padding-left: 3rem;

        .courses-title-page & {
            font-size: 45px;
        }

        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
            background: none;
            color: map-get($theme-colors, "primary");
        }

        @media (max-width: 1380px) {
            padding-left: 0px;
        }

        @media (max-width: 1200px) {
            font-size: 5vw;
        }

        @media (max-width: 768px) {
            font-size: 60px;
        }

        @media (max-width: 576px) {
            font-size: 45px;
        }
    }

    .title-page-logo {
        height: 74px;
        width: auto;
        opacity: 1;
        max-width: 100%;

        .courses-title-page & {
            height: 74px;
        }

        @media (max-width: 1200px) {
            height: 55px;
        }

        @media (max-width: 768px) {
            height: 74px;
        }
    }

    .title-content {
        padding-left: 3rem;

        .courses-title-page & {
            padding-left: 0px !important;
            padding-top: 20px;
        }

        @media (max-width: 1380px) {
            padding-left: 0px;
        }

        @media (max-width: 1200px) {
            padding-top: 20px;
        }

        @media (max-width: 768px) {
            padding-left: 0px !important;
        }
    }

    .lead {
        font-size: 1.5vw;
        font-family: acumin-pro-semi-condensed, sans-serif;
        font-weight: 400;
        font-style: normal;
        padding-right: 7rem;

        .courses-title-page & {
            font-size: 17px;
            padding-right: 0px;
        }

        @media (max-width: 1380px) {
            font-size: 17px;
            padding-right: 0px;
        }
    }

    .button-wrap {
        padding-left: 3rem;

        .courses-title-page & {
            padding-left: 0px !important;
        }

        @media (max-width: 1380px) {
            padding-left: 0px;
        }
        
        @media (max-width: 768px) {
            padding-left: 0px !important;
        }
    }

    .blue-frontier {
        margin-top: 10px;
        padding-left: 3rem;

        .courses-title-page & {
            padding-left: 0px !important;
        }

        @media (max-width: 1380px) {
            padding-left: 0px;
        }
        
        @media (max-width: 768px) {
            padding-left: 0px !important;
        }
    }

    .btn {
        font-size: 24px;
        letter-spacing: 0,32px;
        font-family: acumin-pro-semi-condensed, sans-serif;
        font-weight: 400;
        font-style: normal;
        padding: 24px;

        .courses-title-page & {
            font-size: 16px;
            padding: 12px;

            svg {
                width: 8px;
            }
        }

        @media (max-width: 1380px) {
            font-size: 18px;
        }

        @media (max-width: 1200px) {
            padding: 12px;
        }

        @media (max-width: 992px) {
            font-size: 16px;

            svg {
                display: none
            }
        }

        @media (max-width: 768px) {
            svg {
                width: 8px;
                display: block;
            }
        }
    }

    .home-banner-wrap {
        min-height: 150px;

        .courses-title-page & {
            min-height: 75px;
        }

        @media (max-width: 1380px) {
            min-height: 120px;
        }

        @media (max-width: 1200px) {
            min-height: 100px;
        }

        @media (max-width: 992px) {
            min-height: 75px;
        }
    }

    .home-banner {
        padding-left: 3rem;
        margin-top: 20px;

        .courses-title-page & {
            padding-left: 0px;
        }

        @media (max-width: 1380px) {
            padding-left: 0px;
        }

        @media (max-width: 768px) {
            padding-left: 0px;
        }

        a {
            display: block;
        }

        img {
            width: 100%;
        }
    }

    .continent-select {
        .dropdown-menu {
            &.show {
                width: 100%;
            }
        }
    }
}

.title-page-column {
    &.courses-title-page {
        .main-column-content {
            overflow-y: auto !important;
            margin-top: 65px;
            padding-bottom: 65px;
        }
    }

    .main-column-content {
        @media (max-width: 768px) {
            overflow-y: auto !important;
            margin-top: 65px;
            padding-bottom: 65px;
        }
    }
}

.inner-title-page {
    background: #161a2f;
}
