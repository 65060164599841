@import url(https://use.typekit.net/iub2phd.css);
@import url(https://use.typekit.net/wxe4etj.css);
/*!
 * Bootstrap v4.6.0 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root {
  --blue: #00b0ff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #ff7442;
  --yellow: #ffc107;
  --green: #00cf69;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #ff7442;
  --secondary: #dfe1e0;
  --success: #00cf69;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #dfe1e0;
  --dark: #303449;
  --ternary: #f86029;
  --darker: #161a2f;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #161a2f;
  text-align: left;
  background-color: #fff; }

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
          text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  -webkit-text-decoration-skip-ink: none;
          text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #ff7442;
  text-decoration: none;
  background-color: transparent; }
  a:hover {
    color: #f54100;
    text-decoration: underline; }

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([class]):hover {
    color: inherit;
    text-decoration: none; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit;
  text-align: -webkit-match-parent; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus:not(:focus-visible) {
  outline: 0; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

[role="button"] {
  cursor: pointer; }

select {
  word-wrap: normal; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }
  @media (max-width: 1200px) {
    legend {
      font-size: calc(1.275rem + 0.3vw) ; } }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-family: "acumin-pro-condensed", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 600;
  line-height: 1.2; }

h1, .h1 {
  font-size: 2.5rem; }
  @media (max-width: 1200px) {
    h1, .h1 {
      font-size: calc(1.375rem + 1.5vw) ; } }

h2, .h2 {
  font-size: 2.5rem; }
  @media (max-width: 1200px) {
    h2, .h2 {
      font-size: calc(1.375rem + 1.5vw) ; } }

h3, .h3 {
  font-size: 1.625rem; }
  @media (max-width: 1200px) {
    h3, .h3 {
      font-size: calc(1.2875rem + 0.45vw) ; } }

h4, .h4 {
  font-size: 1.125rem; }

h5, .h5 {
  font-size: 1.25rem; }

h6, .h6 {
  font-size: 1rem; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2; }
  @media (max-width: 1200px) {
    .display-1 {
      font-size: calc(1.725rem + 5.7vw) ; } }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2; }
  @media (max-width: 1200px) {
    .display-2 {
      font-size: calc(1.675rem + 5.1vw) ; } }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2; }
  @media (max-width: 1200px) {
    .display-3 {
      font-size: calc(1.575rem + 3.9vw) ; } }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2; }
  @media (max-width: 1200px) {
    .display-4 {
      font-size: calc(1.475rem + 2.7vw) ; } }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

small,
.small {
  font-size: 80%;
  font-weight: 400; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d; }
  .blockquote-footer::before {
    content: "\2014\00A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.625rem;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #6c757d; }

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-wrap: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem; }
  kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 700; }

pre {
  display: block;
  font-size: 87.5%;
  color: #212529; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll; }

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px; } }

@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 720px; } }

@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 960px; } }

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1140px; } }

.row {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  -webkit-flex-basis: 0;
          flex-basis: 0;
  -webkit-flex-grow: 1;
          flex-grow: 1;
  max-width: 100%; }

.row-cols-1 > * {
  -webkit-flex: 0 0 100%;
          flex: 0 0 100%;
  max-width: 100%; }

.row-cols-2 > * {
  -webkit-flex: 0 0 50%;
          flex: 0 0 50%;
  max-width: 50%; }

.row-cols-3 > * {
  -webkit-flex: 0 0 33.33333%;
          flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.row-cols-4 > * {
  -webkit-flex: 0 0 25%;
          flex: 0 0 25%;
  max-width: 25%; }

.row-cols-5 > * {
  -webkit-flex: 0 0 20%;
          flex: 0 0 20%;
  max-width: 20%; }

.row-cols-6 > * {
  -webkit-flex: 0 0 16.66667%;
          flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-auto {
  -webkit-flex: 0 0 auto;
          flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  -webkit-flex: 0 0 8.33333%;
          flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  -webkit-flex: 0 0 16.66667%;
          flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  -webkit-flex: 0 0 25%;
          flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  -webkit-flex: 0 0 33.33333%;
          flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  -webkit-flex: 0 0 41.66667%;
          flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  -webkit-flex: 0 0 50%;
          flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  -webkit-flex: 0 0 58.33333%;
          flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  -webkit-flex: 0 0 66.66667%;
          flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  -webkit-flex: 0 0 75%;
          flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  -webkit-flex: 0 0 83.33333%;
          flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  -webkit-flex: 0 0 91.66667%;
          flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  -webkit-flex: 0 0 100%;
          flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  -webkit-order: -1;
          order: -1; }

.order-last {
  -webkit-order: 13;
          order: 13; }

.order-0 {
  -webkit-order: 0;
          order: 0; }

.order-1 {
  -webkit-order: 1;
          order: 1; }

.order-2 {
  -webkit-order: 2;
          order: 2; }

.order-3 {
  -webkit-order: 3;
          order: 3; }

.order-4 {
  -webkit-order: 4;
          order: 4; }

.order-5 {
  -webkit-order: 5;
          order: 5; }

.order-6 {
  -webkit-order: 6;
          order: 6; }

.order-7 {
  -webkit-order: 7;
          order: 7; }

.order-8 {
  -webkit-order: 8;
          order: 8; }

.order-9 {
  -webkit-order: 9;
          order: 9; }

.order-10 {
  -webkit-order: 10;
          order: 10; }

.order-11 {
  -webkit-order: 11;
          order: 11; }

.order-12 {
  -webkit-order: 12;
          order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    -webkit-flex-basis: 0;
            flex-basis: 0;
    -webkit-flex-grow: 1;
            flex-grow: 1;
    max-width: 100%; }
  .row-cols-sm-1 > * {
    -webkit-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-sm-2 > * {
    -webkit-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-sm-3 > * {
    -webkit-flex: 0 0 33.33333%;
            flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-sm-4 > * {
    -webkit-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-sm-5 > * {
    -webkit-flex: 0 0 20%;
            flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-sm-6 > * {
    -webkit-flex: 0 0 16.66667%;
            flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-auto {
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    -webkit-flex: 0 0 8.33333%;
            flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    -webkit-flex: 0 0 16.66667%;
            flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    -webkit-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    -webkit-flex: 0 0 33.33333%;
            flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    -webkit-flex: 0 0 41.66667%;
            flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    -webkit-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    -webkit-flex: 0 0 58.33333%;
            flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    -webkit-flex: 0 0 66.66667%;
            flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    -webkit-flex: 0 0 75%;
            flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    -webkit-flex: 0 0 83.33333%;
            flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    -webkit-flex: 0 0 91.66667%;
            flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    -webkit-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    -webkit-order: -1;
            order: -1; }
  .order-sm-last {
    -webkit-order: 13;
            order: 13; }
  .order-sm-0 {
    -webkit-order: 0;
            order: 0; }
  .order-sm-1 {
    -webkit-order: 1;
            order: 1; }
  .order-sm-2 {
    -webkit-order: 2;
            order: 2; }
  .order-sm-3 {
    -webkit-order: 3;
            order: 3; }
  .order-sm-4 {
    -webkit-order: 4;
            order: 4; }
  .order-sm-5 {
    -webkit-order: 5;
            order: 5; }
  .order-sm-6 {
    -webkit-order: 6;
            order: 6; }
  .order-sm-7 {
    -webkit-order: 7;
            order: 7; }
  .order-sm-8 {
    -webkit-order: 8;
            order: 8; }
  .order-sm-9 {
    -webkit-order: 9;
            order: 9; }
  .order-sm-10 {
    -webkit-order: 10;
            order: 10; }
  .order-sm-11 {
    -webkit-order: 11;
            order: 11; }
  .order-sm-12 {
    -webkit-order: 12;
            order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    -webkit-flex-basis: 0;
            flex-basis: 0;
    -webkit-flex-grow: 1;
            flex-grow: 1;
    max-width: 100%; }
  .row-cols-md-1 > * {
    -webkit-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-md-2 > * {
    -webkit-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-md-3 > * {
    -webkit-flex: 0 0 33.33333%;
            flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-md-4 > * {
    -webkit-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-md-5 > * {
    -webkit-flex: 0 0 20%;
            flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-md-6 > * {
    -webkit-flex: 0 0 16.66667%;
            flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-auto {
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    -webkit-flex: 0 0 8.33333%;
            flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    -webkit-flex: 0 0 16.66667%;
            flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    -webkit-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    -webkit-flex: 0 0 33.33333%;
            flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    -webkit-flex: 0 0 41.66667%;
            flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    -webkit-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    -webkit-flex: 0 0 58.33333%;
            flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    -webkit-flex: 0 0 66.66667%;
            flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    -webkit-flex: 0 0 75%;
            flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    -webkit-flex: 0 0 83.33333%;
            flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    -webkit-flex: 0 0 91.66667%;
            flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    -webkit-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    -webkit-order: -1;
            order: -1; }
  .order-md-last {
    -webkit-order: 13;
            order: 13; }
  .order-md-0 {
    -webkit-order: 0;
            order: 0; }
  .order-md-1 {
    -webkit-order: 1;
            order: 1; }
  .order-md-2 {
    -webkit-order: 2;
            order: 2; }
  .order-md-3 {
    -webkit-order: 3;
            order: 3; }
  .order-md-4 {
    -webkit-order: 4;
            order: 4; }
  .order-md-5 {
    -webkit-order: 5;
            order: 5; }
  .order-md-6 {
    -webkit-order: 6;
            order: 6; }
  .order-md-7 {
    -webkit-order: 7;
            order: 7; }
  .order-md-8 {
    -webkit-order: 8;
            order: 8; }
  .order-md-9 {
    -webkit-order: 9;
            order: 9; }
  .order-md-10 {
    -webkit-order: 10;
            order: 10; }
  .order-md-11 {
    -webkit-order: 11;
            order: 11; }
  .order-md-12 {
    -webkit-order: 12;
            order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    -webkit-flex-basis: 0;
            flex-basis: 0;
    -webkit-flex-grow: 1;
            flex-grow: 1;
    max-width: 100%; }
  .row-cols-lg-1 > * {
    -webkit-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-lg-2 > * {
    -webkit-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-lg-3 > * {
    -webkit-flex: 0 0 33.33333%;
            flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-lg-4 > * {
    -webkit-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-lg-5 > * {
    -webkit-flex: 0 0 20%;
            flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-lg-6 > * {
    -webkit-flex: 0 0 16.66667%;
            flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-auto {
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    -webkit-flex: 0 0 8.33333%;
            flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    -webkit-flex: 0 0 16.66667%;
            flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    -webkit-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    -webkit-flex: 0 0 33.33333%;
            flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    -webkit-flex: 0 0 41.66667%;
            flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    -webkit-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    -webkit-flex: 0 0 58.33333%;
            flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    -webkit-flex: 0 0 66.66667%;
            flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    -webkit-flex: 0 0 75%;
            flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    -webkit-flex: 0 0 83.33333%;
            flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    -webkit-flex: 0 0 91.66667%;
            flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    -webkit-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    -webkit-order: -1;
            order: -1; }
  .order-lg-last {
    -webkit-order: 13;
            order: 13; }
  .order-lg-0 {
    -webkit-order: 0;
            order: 0; }
  .order-lg-1 {
    -webkit-order: 1;
            order: 1; }
  .order-lg-2 {
    -webkit-order: 2;
            order: 2; }
  .order-lg-3 {
    -webkit-order: 3;
            order: 3; }
  .order-lg-4 {
    -webkit-order: 4;
            order: 4; }
  .order-lg-5 {
    -webkit-order: 5;
            order: 5; }
  .order-lg-6 {
    -webkit-order: 6;
            order: 6; }
  .order-lg-7 {
    -webkit-order: 7;
            order: 7; }
  .order-lg-8 {
    -webkit-order: 8;
            order: 8; }
  .order-lg-9 {
    -webkit-order: 9;
            order: 9; }
  .order-lg-10 {
    -webkit-order: 10;
            order: 10; }
  .order-lg-11 {
    -webkit-order: 11;
            order: 11; }
  .order-lg-12 {
    -webkit-order: 12;
            order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    -webkit-flex-basis: 0;
            flex-basis: 0;
    -webkit-flex-grow: 1;
            flex-grow: 1;
    max-width: 100%; }
  .row-cols-xl-1 > * {
    -webkit-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-xl-2 > * {
    -webkit-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-xl-3 > * {
    -webkit-flex: 0 0 33.33333%;
            flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-xl-4 > * {
    -webkit-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-xl-5 > * {
    -webkit-flex: 0 0 20%;
            flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-xl-6 > * {
    -webkit-flex: 0 0 16.66667%;
            flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-auto {
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    -webkit-flex: 0 0 8.33333%;
            flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    -webkit-flex: 0 0 16.66667%;
            flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    -webkit-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    -webkit-flex: 0 0 33.33333%;
            flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    -webkit-flex: 0 0 41.66667%;
            flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    -webkit-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    -webkit-flex: 0 0 58.33333%;
            flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    -webkit-flex: 0 0 66.66667%;
            flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    -webkit-flex: 0 0 75%;
            flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    -webkit-flex: 0 0 83.33333%;
            flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    -webkit-flex: 0 0 91.66667%;
            flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    -webkit-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    -webkit-order: -1;
            order: -1; }
  .order-xl-last {
    -webkit-order: 13;
            order: 13; }
  .order-xl-0 {
    -webkit-order: 0;
            order: 0; }
  .order-xl-1 {
    -webkit-order: 1;
            order: 1; }
  .order-xl-2 {
    -webkit-order: 2;
            order: 2; }
  .order-xl-3 {
    -webkit-order: 3;
            order: 3; }
  .order-xl-4 {
    -webkit-order: 4;
            order: 4; }
  .order-xl-5 {
    -webkit-order: 5;
            order: 5; }
  .order-xl-6 {
    -webkit-order: 6;
            order: 6; }
  .order-xl-7 {
    -webkit-order: 7;
            order: 7; }
  .order-xl-8 {
    -webkit-order: 8;
            order: 8; }
  .order-xl-9 {
    -webkit-order: 9;
            order: 9; }
  .order-xl-10 {
    -webkit-order: 10;
            order: 10; }
  .order-xl-11 {
    -webkit-order: 11;
            order: 11; }
  .order-xl-12 {
    -webkit-order: 12;
            order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #161a2f; }
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6; }
  .table tbody + tbody {
    border-top: 2px solid #dee2e6; }

.table-sm th,
.table-sm td {
  padding: 0.3rem; }

.table-bordered {
  border: 1px solid #dee2e6; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6; }
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 2px; }

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05); }

.table-hover tbody tr:hover {
  color: #161a2f;
  background-color: rgba(0, 0, 0, 0.075); }

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #ffd8ca; }

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #ffb79d; }

.table-hover .table-primary:hover {
  background-color: #ffc5b1; }
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #ffc5b1; }

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #f6f7f6; }

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #eeefef; }

.table-hover .table-secondary:hover {
  background-color: #e9ebe9; }
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #e9ebe9; }

.table-success,
.table-success > th,
.table-success > td {
  background-color: #b8f2d5; }

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #7ae6b1; }

.table-hover .table-success:hover {
  background-color: #a2eec8; }
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #a2eec8; }

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb; }

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #86cfda; }

.table-hover .table-info:hover {
  background-color: #abdde5; }
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #abdde5; }

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffeeba; }

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #ffdf7e; }

.table-hover .table-warning:hover {
  background-color: #ffe8a1; }
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #ffe8a1; }

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f5c6cb; }

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #ed969e; }

.table-hover .table-danger:hover {
  background-color: #f1b0b7; }
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #f1b0b7; }

.table-light,
.table-light > th,
.table-light > td {
  background-color: #f6f7f6; }

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #eeefef; }

.table-hover .table-light:hover {
  background-color: #e9ebe9; }
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #e9ebe9; }

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c5c6cc; }

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #9395a0; }

.table-hover .table-dark:hover {
  background-color: #b7b9c0; }
  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: #b7b9c0; }

.table-ternary,
.table-ternary > th,
.table-ternary > td {
  background-color: #fdd2c3; }

.table-ternary th,
.table-ternary td,
.table-ternary thead th,
.table-ternary tbody + tbody {
  border-color: #fbac90; }

.table-hover .table-ternary:hover {
  background-color: #fcbfaa; }
  .table-hover .table-ternary:hover > td,
  .table-hover .table-ternary:hover > th {
    background-color: #fcbfaa; }

.table-darker,
.table-darker > th,
.table-darker > td {
  background-color: #bebfc5; }

.table-darker th,
.table-darker td,
.table-darker thead th,
.table-darker tbody + tbody {
  border-color: #868893; }

.table-hover .table-darker:hover {
  background-color: #b1b2b9; }
  .table-hover .table-darker:hover > td,
  .table-hover .table-darker:hover > th {
    background-color: #b1b2b9; }

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075); }

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075); }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0.075); }

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55; }

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6; }

.table-dark {
  color: #fff;
  background-color: #343a40; }
  .table-dark th,
  .table-dark td,
  .table-dark thead th {
    border-color: #454d55; }
  .table-dark.table-bordered {
    border: 0; }
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05); }
  .table-dark.table-hover tbody tr:hover {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-sm > .table-bordered {
      border: 0; } }

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-md > .table-bordered {
      border: 0; } }

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-lg > .table-bordered {
      border: 0; } }

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-xl > .table-bordered {
      border: 0; } }

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; }
  .table-responsive > .table-bordered {
    border: 0; }

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-family: "acumin-pro-semi-condensed", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.625rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .form-control {
      transition: none; } }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #495057; }
  .form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #ffd2c2;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(255, 116, 66, 0.25); }
  .form-control::-webkit-input-placeholder {
    color: #90939a;
    opacity: 1; }
  .form-control:-ms-input-placeholder {
    color: #90939a;
    opacity: 1; }
  .form-control::placeholder {
    color: #90939a;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1; }

input[type="date"].form-control,
input[type="time"].form-control,
input[type="datetime-local"].form-control,
input[type="month"].form-control {
  -webkit-appearance: none;
          appearance: none; }

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

.col-form-label-lg {
  padding-top: calc(2rem + 1px);
  padding-bottom: calc(2rem + 1px);
  font-size: 1.25rem;
  line-height: 1; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  font-size: 1.125rem;
  line-height: 1.5;
  color: #161a2f;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.form-control-lg {
  height: calc(1em + 4rem + 2px);
  padding: 2rem 1rem;
  font-size: 1.25rem;
  line-height: 1;
  border-radius: 0.25rem; }

select.form-control[size], select.form-control[multiple] {
  height: auto; }

textarea.form-control {
  height: auto; }

.form-group {
  margin-bottom: 1.5rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
  .form-check-input[disabled] ~ .form-check-label,
  .form-check-input:disabled ~ .form-check-label {
    color: #6c757d; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-align-items: center;
          align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #00cf69; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(0, 207, 105, 0.9);
  border-radius: 0.625rem; }
  .form-row > .col > .valid-tooltip,
  .form-row > [class*="col-"] > .valid-tooltip {
    left: 5px; }

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #00cf69;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2300cf69' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus {
    border-color: #00cf69;
    box-shadow: 0 0 0 0.2rem rgba(0, 207, 105, 0.25); }

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #00cf69;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2300cf69' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat; }
  .was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
    border-color: #00cf69;
    box-shadow: 0 0 0 0.2rem rgba(0, 207, 105, 0.25); }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #00cf69; }

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #00cf69; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    border-color: #00cf69; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #03ff83;
  background-color: #03ff83; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(0, 207, 105, 0.25); }

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #00cf69; }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #00cf69; }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #00cf69;
  box-shadow: 0 0 0 0.2rem rgba(0, 207, 105, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.625rem; }
  .form-row > .col > .invalid-tooltip,
  .form-row > [class*="col-"] > .invalid-tooltip {
    left: 5px; }

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #dc3545;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e") center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat; }
  .was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545; }

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    border-color: #dc3545; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #e4606d;
  background-color: #e4606d; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #dc3545; }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.form-inline {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row wrap;
          flex-flow: row wrap;
  -webkit-align-items: center;
          align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 576px) {
    .form-inline label {
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center;
      -webkit-justify-content: center;
              justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: -webkit-flex;
      display: flex;
      -webkit-flex: 0 0 auto;
              flex: 0 0 auto;
      -webkit-flex-flow: row wrap;
              flex-flow: row wrap;
      -webkit-align-items: center;
              align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group,
    .form-inline .custom-select {
      width: auto; }
    .form-inline .form-check {
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center;
      -webkit-justify-content: center;
              justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      -webkit-flex-shrink: 0;
              flex-shrink: 0;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      -webkit-align-items: center;
              align-items: center;
      -webkit-justify-content: center;
              justify-content: center; }
    .form-inline .custom-control-label {
      margin-bottom: 0; } }

.btn {
  display: inline-block;
  font-family: "acumin-pro-semi-condensed", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 600;
  color: #161a2f;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1.125rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .btn {
      transition: none; } }
  .btn:hover {
    color: #161a2f;
    text-decoration: none; }
  .btn:focus, .btn.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(255, 116, 66, 0.25); }
  .btn.disabled, .btn:disabled {
    opacity: 0.65; }
  .btn:not(:disabled):not(.disabled) {
    cursor: pointer; }

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none; }

.btn-primary {
  color: #212529;
  background-color: #ff7442;
  border-color: #ff7442; }
  .btn-primary:hover {
    color: #fff;
    background-color: #ff581c;
    border-color: #ff4e0f; }
  .btn-primary:focus, .btn-primary.focus {
    color: #fff;
    background-color: #ff581c;
    border-color: #ff4e0f;
    box-shadow: 0 0 0 0.2rem rgba(222, 104, 62, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    color: #212529;
    background-color: #ff7442;
    border-color: #ff7442; }
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #ff4e0f;
    border-color: #ff4502; }
    .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(222, 104, 62, 0.5); }

.btn-secondary {
  color: #212529;
  background-color: #dfe1e0;
  border-color: #dfe1e0; }
  .btn-secondary:hover {
    color: #212529;
    background-color: #cbcecd;
    border-color: #c5c8c7; }
  .btn-secondary:focus, .btn-secondary.focus {
    color: #212529;
    background-color: #cbcecd;
    border-color: #c5c8c7;
    box-shadow: 0 0 0 0.2rem rgba(195, 197, 197, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #212529;
    background-color: #dfe1e0;
    border-color: #dfe1e0; }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #212529;
    background-color: #c5c8c7;
    border-color: #bec2c0; }
    .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(195, 197, 197, 0.5); }

.btn-success {
  color: #fff;
  background-color: #00cf69;
  border-color: #00cf69; }
  .btn-success:hover {
    color: #fff;
    background-color: #00a956;
    border-color: #009c4f; }
  .btn-success:focus, .btn-success.focus {
    color: #fff;
    background-color: #00a956;
    border-color: #009c4f;
    box-shadow: 0 0 0 0.2rem rgba(38, 214, 128, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    color: #fff;
    background-color: #00cf69;
    border-color: #00cf69; }
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #009c4f;
    border-color: #008f49; }
    .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(38, 214, 128, 0.5); }

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8; }
  .btn-info:hover {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b; }
  .btn-info:focus, .btn-info.focus {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b;
    box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #117a8b;
    border-color: #10707f; }
    .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5); }

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107; }
  .btn-warning:hover {
    color: #212529;
    background-color: #e0a800;
    border-color: #d39e00; }
  .btn-warning:focus, .btn-warning.focus {
    color: #212529;
    background-color: #e0a800;
    border-color: #d39e00;
    box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: #212529;
    background-color: #d39e00;
    border-color: #c69500; }
    .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5); }

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545; }
  .btn-danger:hover {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130; }
  .btn-danger:focus, .btn-danger.focus {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130;
    box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #bd2130;
    border-color: #b21f2d; }
    .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5); }

.btn-light {
  color: #212529;
  background-color: #dfe1e0;
  border-color: #dfe1e0; }
  .btn-light:hover {
    color: #212529;
    background-color: #cbcecd;
    border-color: #c5c8c7; }
  .btn-light:focus, .btn-light.focus {
    color: #212529;
    background-color: #cbcecd;
    border-color: #c5c8c7;
    box-shadow: 0 0 0 0.2rem rgba(195, 197, 197, 0.5); }
  .btn-light.disabled, .btn-light:disabled {
    color: #212529;
    background-color: #dfe1e0;
    border-color: #dfe1e0; }
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: #212529;
    background-color: #c5c8c7;
    border-color: #bec2c0; }
    .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(195, 197, 197, 0.5); }

.btn-dark {
  color: #fff;
  background-color: #303449;
  border-color: #303449; }
  .btn-dark:hover {
    color: #fff;
    background-color: #212432;
    border-color: #1c1e2a; }
  .btn-dark:focus, .btn-dark.focus {
    color: #fff;
    background-color: #212432;
    border-color: #1c1e2a;
    box-shadow: 0 0 0 0.2rem rgba(79, 82, 100, 0.5); }
  .btn-dark.disabled, .btn-dark:disabled {
    color: #fff;
    background-color: #303449;
    border-color: #303449; }
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #1c1e2a;
    border-color: #171923; }
    .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(79, 82, 100, 0.5); }

.btn-ternary {
  color: #fff;
  background-color: #f86029;
  border-color: #f86029; }
  .btn-ternary:hover {
    color: #fff;
    background-color: #f34608;
    border-color: #e64308; }
  .btn-ternary:focus, .btn-ternary.focus {
    color: #fff;
    background-color: #f34608;
    border-color: #e64308;
    box-shadow: 0 0 0 0.2rem rgba(249, 120, 73, 0.5); }
  .btn-ternary.disabled, .btn-ternary:disabled {
    color: #fff;
    background-color: #f86029;
    border-color: #f86029; }
  .btn-ternary:not(:disabled):not(.disabled):active, .btn-ternary:not(:disabled):not(.disabled).active,
  .show > .btn-ternary.dropdown-toggle {
    color: #fff;
    background-color: #e64308;
    border-color: #da3f07; }
    .btn-ternary:not(:disabled):not(.disabled):active:focus, .btn-ternary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-ternary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(249, 120, 73, 0.5); }

.btn-darker {
  color: #fff;
  background-color: #161a2f;
  border-color: #161a2f; }
  .btn-darker:hover {
    color: #fff;
    background-color: #0a0c15;
    border-color: #06070c; }
  .btn-darker:focus, .btn-darker.focus {
    color: #fff;
    background-color: #0a0c15;
    border-color: #06070c;
    box-shadow: 0 0 0 0.2rem rgba(57, 60, 78, 0.5); }
  .btn-darker.disabled, .btn-darker:disabled {
    color: #fff;
    background-color: #161a2f;
    border-color: #161a2f; }
  .btn-darker:not(:disabled):not(.disabled):active, .btn-darker:not(:disabled):not(.disabled).active,
  .show > .btn-darker.dropdown-toggle {
    color: #fff;
    background-color: #06070c;
    border-color: #020204; }
    .btn-darker:not(:disabled):not(.disabled):active:focus, .btn-darker:not(:disabled):not(.disabled).active:focus,
    .show > .btn-darker.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(57, 60, 78, 0.5); }

.btn-outline-primary {
  color: #ff7442;
  border-color: #ff7442; }
  .btn-outline-primary:hover {
    color: #212529;
    background-color: #ff7442;
    border-color: #ff7442; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 116, 66, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #ff7442;
    background-color: transparent; }
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #212529;
    background-color: #ff7442;
    border-color: #ff7442; }
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 116, 66, 0.5); }

.btn-outline-secondary {
  color: #dfe1e0;
  border-color: #dfe1e0; }
  .btn-outline-secondary:hover {
    color: #212529;
    background-color: #dfe1e0;
    border-color: #dfe1e0; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(223, 225, 224, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #dfe1e0;
    background-color: transparent; }
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #212529;
    background-color: #dfe1e0;
    border-color: #dfe1e0; }
    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(223, 225, 224, 0.5); }

.btn-outline-success {
  color: #00cf69;
  border-color: #00cf69; }
  .btn-outline-success:hover {
    color: #fff;
    background-color: #00cf69;
    border-color: #00cf69; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 207, 105, 0.5); }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #00cf69;
    background-color: transparent; }
  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #00cf69;
    border-color: #00cf69; }
    .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(0, 207, 105, 0.5); }

.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8; }
  .btn-outline-info:hover {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #17a2b8;
    background-color: transparent; }
  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
    .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107; }
  .btn-outline-warning:hover {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #ffc107;
    background-color: transparent; }
  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
    .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545; }
  .btn-outline-danger:hover {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #dc3545;
    background-color: transparent; }
  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
    .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

.btn-outline-light {
  color: #dfe1e0;
  border-color: #dfe1e0; }
  .btn-outline-light:hover {
    color: #212529;
    background-color: #dfe1e0;
    border-color: #dfe1e0; }
  .btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(223, 225, 224, 0.5); }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #dfe1e0;
    background-color: transparent; }
  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #dfe1e0;
    border-color: #dfe1e0; }
    .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(223, 225, 224, 0.5); }

.btn-outline-dark {
  color: #303449;
  border-color: #303449; }
  .btn-outline-dark:hover {
    color: #fff;
    background-color: #303449;
    border-color: #303449; }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(48, 52, 73, 0.5); }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #303449;
    background-color: transparent; }
  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #303449;
    border-color: #303449; }
    .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(48, 52, 73, 0.5); }

.btn-outline-ternary {
  color: #f86029;
  border-color: #f86029; }
  .btn-outline-ternary:hover {
    color: #fff;
    background-color: #f86029;
    border-color: #f86029; }
  .btn-outline-ternary:focus, .btn-outline-ternary.focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 96, 41, 0.5); }
  .btn-outline-ternary.disabled, .btn-outline-ternary:disabled {
    color: #f86029;
    background-color: transparent; }
  .btn-outline-ternary:not(:disabled):not(.disabled):active, .btn-outline-ternary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-ternary.dropdown-toggle {
    color: #fff;
    background-color: #f86029;
    border-color: #f86029; }
    .btn-outline-ternary:not(:disabled):not(.disabled):active:focus, .btn-outline-ternary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-ternary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(248, 96, 41, 0.5); }

.btn-outline-darker {
  color: #161a2f;
  border-color: #161a2f; }
  .btn-outline-darker:hover {
    color: #fff;
    background-color: #161a2f;
    border-color: #161a2f; }
  .btn-outline-darker:focus, .btn-outline-darker.focus {
    box-shadow: 0 0 0 0.2rem rgba(22, 26, 47, 0.5); }
  .btn-outline-darker.disabled, .btn-outline-darker:disabled {
    color: #161a2f;
    background-color: transparent; }
  .btn-outline-darker:not(:disabled):not(.disabled):active, .btn-outline-darker:not(:disabled):not(.disabled).active,
  .show > .btn-outline-darker.dropdown-toggle {
    color: #fff;
    background-color: #161a2f;
    border-color: #161a2f; }
    .btn-outline-darker:not(:disabled):not(.disabled):active:focus, .btn-outline-darker:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-darker.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(22, 26, 47, 0.5); }

.btn-link {
  font-weight: 400;
  color: #ff7442;
  text-decoration: none; }
  .btn-link:hover {
    color: #f54100;
    text-decoration: underline; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: underline; }
  .btn-link:disabled, .btn-link.disabled {
    color: #6c757d;
    pointer-events: none; }

.btn-lg, .btn-group-lg > .btn {
  padding: 1.25rem 1rem;
  font-size: 1.125rem;
  line-height: 1.5;
  border-radius: 0.25rem; }

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.25rem; }

.btn-block {
  display: block;
  width: 100%; }
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.fade {
  transition: opacity 0.15s linear; }
  @media (prefers-reduced-motion: reduce) {
    .fade {
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none; } }

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative; }

.dropdown-toggle {
  white-space: nowrap; }
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent; }
  .dropdown-toggle:empty::after {
    margin-left: 0; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #fff;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid 1px solid rgba(255, 255, 255, 0.4);
  border-radius: 8px; }

.dropdown-menu-left {
  right: auto;
  left: 0; }

.dropdown-menu-right {
  right: 0;
  left: auto; }

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0; }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto; } }

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0; }
  .dropdown-menu-md-right {
    right: 0;
    left: auto; } }

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0; }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto; } }

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0; }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto; } }

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem; }

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropright .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-toggle::after {
  vertical-align: 0; }

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem; }

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropleft .dropdown-toggle::after {
  display: none; }

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto; }

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #16181b;
    text-decoration: none;
    background-color: #e9ecef; }
  .dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #ff7442; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #adb5bd;
    pointer-events: none;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529; }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: -webkit-inline-flex;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .btn,
  .btn-group-vertical > .btn {
    position: relative;
    -webkit-flex: 1 1 auto;
            flex: 1 1 auto; }
    .btn-group > .btn:hover,
    .btn-group-vertical > .btn:hover {
      z-index: 1; }
    .btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
    .btn-group-vertical > .btn:focus,
    .btn-group-vertical > .btn:active,
    .btn-group-vertical > .btn.active {
      z-index: 1; }

.btn-toolbar {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-justify-content: flex-start;
          justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px; }

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem; }
  .dropdown-toggle-split::after,
  .dropup .dropdown-toggle-split::after,
  .dropright .dropdown-toggle-split::after {
    margin-left: 0; }
  .dropleft .dropdown-toggle-split::before {
    margin-right: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }

.btn-group-vertical {
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  -webkit-justify-content: center;
          justify-content: center; }
  .btn-group-vertical > .btn,
  .btn-group-vertical > .btn-group {
    width: 100%; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) {
    margin-top: -1px; }
  .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group-vertical > .btn-group:not(:last-child) > .btn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0; }
  .btn-group-toggle > .btn input[type="radio"],
  .btn-group-toggle > .btn input[type="checkbox"],
  .btn-group-toggle > .btn-group > .btn input[type="radio"],
  .btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none; }

.input-group {
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-align-items: stretch;
          align-items: stretch;
  width: 100%; }
  .input-group > .form-control,
  .input-group > .form-control-plaintext,
  .input-group > .custom-select,
  .input-group > .custom-file {
    position: relative;
    -webkit-flex: 1 1 auto;
            flex: 1 1 auto;
    width: 1%;
    min-width: 0;
    margin-bottom: 0; }
    .input-group > .form-control + .form-control,
    .input-group > .form-control + .custom-select,
    .input-group > .form-control + .custom-file,
    .input-group > .form-control-plaintext + .form-control,
    .input-group > .form-control-plaintext + .custom-select,
    .input-group > .form-control-plaintext + .custom-file,
    .input-group > .custom-select + .form-control,
    .input-group > .custom-select + .custom-select,
    .input-group > .custom-select + .custom-file,
    .input-group > .custom-file + .form-control,
    .input-group > .custom-file + .custom-select,
    .input-group > .custom-file + .custom-file {
      margin-left: -1px; }
  .input-group > .form-control:focus,
  .input-group > .custom-select:focus,
  .input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
    z-index: 3; }
  .input-group > .custom-file .custom-file-input:focus {
    z-index: 4; }
  .input-group > .form-control:not(:first-child),
  .input-group > .custom-select:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .input-group > .custom-file {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center; }
    .input-group > .custom-file:not(:last-child) .custom-file-label,
    .input-group > .custom-file:not(:first-child) .custom-file-label {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }
  .input-group:not(.has-validation) > .form-control:not(:last-child),
  .input-group:not(.has-validation) > .custom-select:not(:last-child),
  .input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label::after {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .input-group.has-validation > .form-control:nth-last-child(n + 3),
  .input-group.has-validation > .custom-select:nth-last-child(n + 3),
  .input-group.has-validation > .custom-file:nth-last-child(n + 3) .custom-file-label::after {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }

.input-group-prepend,
.input-group-append {
  display: -webkit-flex;
  display: flex; }
  .input-group-prepend .btn,
  .input-group-append .btn {
    position: relative;
    z-index: 2; }
    .input-group-prepend .btn:focus,
    .input-group-append .btn:focus {
      z-index: 3; }
  .input-group-prepend .btn + .btn,
  .input-group-prepend .btn + .input-group-text,
  .input-group-prepend .input-group-text + .input-group-text,
  .input-group-prepend .input-group-text + .btn,
  .input-group-append .btn + .btn,
  .input-group-append .btn + .input-group-text,
  .input-group-append .input-group-text + .input-group-text,
  .input-group-append .input-group-text + .btn {
    margin-left: -1px; }

.input-group-prepend {
  margin-right: -1px; }

.input-group-append {
  margin-left: -1px; }

.input-group-text {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.625rem; }
  .input-group-text input[type="radio"],
  .input-group-text input[type="checkbox"] {
    margin-top: 0; }

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1em + 4rem + 2px); }

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 2rem 1rem;
  font-size: 1.25rem;
  line-height: 1;
  border-radius: 0.25rem; }

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 2px); }

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem; }

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .btn,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .input-group-text,
.input-group.has-validation > .input-group-append:nth-last-child(n + 3) > .btn,
.input-group.has-validation > .input-group-append:nth-last-child(n + 3) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.custom-control {
  position: relative;
  z-index: 1;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
  -webkit-print-color-adjust: exact;
          color-adjust: exact; }

.custom-control-inline {
  display: -webkit-inline-flex;
  display: inline-flex;
  margin-right: 1rem; }

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.25rem;
  opacity: 0; }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #ff7442;
    background-color: #ff7442; }
  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 0.2rem rgba(255, 116, 66, 0.25); }
  .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border-color: #ffd2c2; }
  .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
    color: #fff;
    background-color: #fff7f5;
    border-color: #fff7f5; }
  .custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
    color: #6c757d; }
    .custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
      background-color: #e9ecef; }

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top; }
  .custom-control-label::before {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    background-color: #fff;
    border: #adb5bd solid 1px; }
  .custom-control-label::after {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background: 50% / 50% 50% no-repeat; }

.custom-checkbox .custom-control-label::before {
  border-radius: 3px; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #ff7442;
  background-color: #ff7442; }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(255, 116, 66, 0.5); }

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(255, 116, 66, 0.5); }

.custom-radio .custom-control-label::before {
  border-radius: 50%; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e"); }

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(255, 116, 66, 0.5); }

.custom-switch {
  padding-left: 2.25rem; }
  .custom-switch .custom-control-label::before {
    left: -2.25rem;
    width: 1.75rem;
    pointer-events: all;
    border-radius: 0.5rem; }
  .custom-switch .custom-control-label::after {
    top: calc(0.25rem + 2px);
    left: calc(-2.25rem + 2px);
    width: calc(1rem - 4px);
    height: calc(1rem - 4px);
    background-color: #adb5bd;
    border-radius: 0.5rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .custom-switch .custom-control-label::after {
        transition: none; } }
  .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    background-color: #fff;
    -webkit-transform: translateX(0.75rem);
            transform: translateX(0.75rem); }
  .custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(255, 116, 66, 0.5); }

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-family: "acumin-pro-semi-condensed", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat;
  border: 1px solid #ced4da;
  border-radius: 0.625rem;
  -webkit-appearance: none;
          appearance: none; }
  .custom-select:focus {
    border-color: #ffd2c2;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(255, 116, 66, 0.25); }
    .custom-select:focus::-ms-value {
      color: #495057;
      background-color: #fff; }
  .custom-select[multiple], .custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 0.75rem;
    background-image: none; }
  .custom-select:disabled {
    color: #6c757d;
    background-color: #e9ecef; }
  .custom-select::-ms-expand {
    display: none; }
  .custom-select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #495057; }

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem; }

.custom-select-lg {
  height: calc(1em + 4rem + 2px);
  padding-top: 2rem;
  padding-bottom: 2rem;
  padding-left: 1rem;
  font-size: 1.25rem; }

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin-bottom: 0; }

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin: 0;
  overflow: hidden;
  opacity: 0; }
  .custom-file-input:focus ~ .custom-file-label {
    border-color: #ffd2c2;
    box-shadow: 0 0 0 0.2rem rgba(255, 116, 66, 0.25); }
  .custom-file-input[disabled] ~ .custom-file-label,
  .custom-file-input:disabled ~ .custom-file-label {
    background-color: #e9ecef; }
  .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Browse"; }
  .custom-file-input ~ .custom-file-label[data-browse]::after {
    content: attr(data-browse); }

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  overflow: hidden;
  font-family: "acumin-pro-semi-condensed", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 500;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.625rem; }
  .custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(1.5em + 0.75rem);
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    color: #495057;
    content: "Browse";
    background-color: #e9ecef;
    border-left: inherit;
    border-radius: 0 0.625rem 0.625rem 0; }

.custom-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  -webkit-appearance: none;
          appearance: none; }
  .custom-range:focus {
    outline: 0; }
    .custom-range:focus::-webkit-slider-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(255, 116, 66, 0.25); }
    .custom-range:focus::-moz-range-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(255, 116, 66, 0.25); }
    .custom-range:focus::-ms-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(255, 116, 66, 0.25); }
  .custom-range::-moz-focus-outer {
    border: 0; }
  .custom-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #ff7442;
    border: 0;
    border-radius: 1rem;
    -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    -webkit-appearance: none;
            appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-webkit-slider-thumb {
        -webkit-transition: none;
        transition: none; } }
    .custom-range::-webkit-slider-thumb:active {
      background-color: #fff7f5; }
  .custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #ff7442;
    border: 0;
    border-radius: 1rem;
    -moz-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-moz-range-thumb {
        -moz-transition: none;
        transition: none; } }
    .custom-range::-moz-range-thumb:active {
      background-color: #fff7f5; }
  .custom-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: 0;
    margin-right: 0.2rem;
    margin-left: 0.2rem;
    background-color: #ff7442;
    border: 0;
    border-radius: 1rem;
    -ms-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-ms-thumb {
        -ms-transition: none;
        transition: none; } }
    .custom-range::-ms-thumb:active {
      background-color: #fff7f5; }
  .custom-range::-ms-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: 0.5rem; }
  .custom-range::-ms-fill-lower {
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range::-ms-fill-upper {
    margin-right: 15px;
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range:disabled::-webkit-slider-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-webkit-slider-runnable-track {
    cursor: default; }
  .custom-range:disabled::-moz-range-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-moz-range-track {
    cursor: default; }
  .custom-range:disabled::-ms-thumb {
    background-color: #adb5bd; }

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .custom-control-label::before,
    .custom-file-label,
    .custom-select {
      transition: none; } }

.nav {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 1.5rem 2rem; }
  .nav-link:hover, .nav-link:focus {
    text-decoration: none; }
  .nav-link.disabled {
    color: #6c757d;
    pointer-events: none;
    cursor: default; }

.nav-tabs {
  border-bottom: 0 solid #dee2e6; }
  .nav-tabs .nav-link {
    margin-bottom: 0;
    border: 0 solid transparent;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      border-color: #e9ecef #e9ecef #dee2e6; }
    .nav-tabs .nav-link.disabled {
      color: #6c757d;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #161a2f;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff; }
  .nav-tabs .dropdown-menu {
    margin-top: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  border-radius: 0.625rem; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #ff7442; }

.nav-fill > .nav-link,
.nav-fill .nav-item {
  -webkit-flex: 1 1 auto;
          flex: 1 1 auto;
  text-align: center; }

.nav-justified > .nav-link,
.nav-justified .nav-item {
  -webkit-flex-basis: 0;
          flex-basis: 0;
  -webkit-flex-grow: 1;
          flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  padding: 0.5rem 1rem; }
  .navbar .container,
  .navbar .container-fluid, .navbar .container-sm, .navbar .container-md, .navbar .container-lg, .navbar .container-xl {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-between;
            justify-content: space-between; }

.navbar-brand {
  display: inline-block;
  padding-top: 1.3125rem;
  padding-bottom: 1.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap; }
  .navbar-brand:hover, .navbar-brand:focus {
    text-decoration: none; }

.navbar-nav {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu {
    position: static;
    float: none; }

.navbar-text {
  display: inline-block;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem; }

.navbar-collapse {
  -webkit-flex-basis: 100%;
          flex-basis: 100%;
  -webkit-flex-grow: 1;
          flex-grow: 1;
  -webkit-align-items: center;
          align-items: center; }

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem; }
  .navbar-toggler:hover, .navbar-toggler:focus {
    text-decoration: none; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: 50% / 100% 100% no-repeat; }

.navbar-nav-scroll {
  max-height: 75vh;
  overflow-y: auto; }

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 576px) {
  .navbar-expand-sm {
    -webkit-flex-flow: row nowrap;
            flex-flow: row nowrap;
    -webkit-justify-content: flex-start;
            justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      -webkit-flex-direction: row;
              flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
      -webkit-flex-wrap: nowrap;
              flex-wrap: nowrap; }
    .navbar-expand-sm .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-sm .navbar-collapse {
      display: -webkit-flex !important;
      display: flex !important;
      -webkit-flex-basis: auto;
              flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; } }

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    -webkit-flex-flow: row nowrap;
            flex-flow: row nowrap;
    -webkit-justify-content: flex-start;
            justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      -webkit-flex-direction: row;
              flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
      -webkit-flex-wrap: nowrap;
              flex-wrap: nowrap; }
    .navbar-expand-md .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-md .navbar-collapse {
      display: -webkit-flex !important;
      display: flex !important;
      -webkit-flex-basis: auto;
              flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; } }

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 992px) {
  .navbar-expand-lg {
    -webkit-flex-flow: row nowrap;
            flex-flow: row nowrap;
    -webkit-justify-content: flex-start;
            justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      -webkit-flex-direction: row;
              flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
      -webkit-flex-wrap: nowrap;
              flex-wrap: nowrap; }
    .navbar-expand-lg .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-lg .navbar-collapse {
      display: -webkit-flex !important;
      display: flex !important;
      -webkit-flex-basis: auto;
              flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; } }

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1200px) {
  .navbar-expand-xl {
    -webkit-flex-flow: row nowrap;
            flex-flow: row nowrap;
    -webkit-justify-content: flex-start;
            justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      -webkit-flex-direction: row;
              flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
      -webkit-flex-wrap: nowrap;
              flex-wrap: nowrap; }
    .navbar-expand-xl .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-xl .navbar-collapse {
      display: -webkit-flex !important;
      display: flex !important;
      -webkit-flex-basis: auto;
              flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; } }

.navbar-expand {
  -webkit-flex-flow: row nowrap;
          flex-flow: row nowrap;
  -webkit-justify-content: flex-start;
          justify-content: flex-start; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
    padding-right: 0;
    padding-left: 0; }
  .navbar-expand .navbar-nav {
    -webkit-flex-direction: row;
            flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
    -webkit-flex-wrap: nowrap;
            flex-wrap: nowrap; }
  .navbar-expand .navbar-nav-scroll {
    overflow: visible; }
  .navbar-expand .navbar-collapse {
    display: -webkit-flex !important;
    display: flex !important;
    -webkit-flex-basis: auto;
            flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9); }
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: rgba(0, 0, 0, 0.7); }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-text a {
    color: rgba(0, 0, 0, 0.9); }
    .navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
      color: rgba(0, 0, 0, 0.9); }

.navbar-dark .navbar-brand {
  color: #fff; }
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #fff; }

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75); }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff; }

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-text a {
    color: #fff; }
    .navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
      color: #fff; }

.card {
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.625rem; }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group {
    border-top: inherit;
    border-bottom: inherit; }
    .card > .list-group:first-child {
      border-top-width: 0;
      border-top-left-radius: calc(0.625rem - 1px);
      border-top-right-radius: calc(0.625rem - 1px); }
    .card > .list-group:last-child {
      border-bottom-width: 0;
      border-bottom-right-radius: calc(0.625rem - 1px);
      border-bottom-left-radius: calc(0.625rem - 1px); }
  .card > .card-header + .list-group,
  .card > .list-group + .card-footer {
    border-top: 0; }

.card-body {
  -webkit-flex: 1 1 auto;
          flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem; }

.card-title {
  margin-bottom: 0.75rem; }

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }

.card-link + .card-link {
  margin-left: 1.25rem; }

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125); }
  .card-header:first-child {
    border-radius: calc(0.625rem - 1px) calc(0.625rem - 1px) 0 0; }

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125); }
  .card-footer:last-child {
    border-radius: 0 0 calc(0.625rem - 1px) calc(0.625rem - 1px); }

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
  border-radius: calc(0.625rem - 1px); }

.card-img,
.card-img-top,
.card-img-bottom {
  -webkit-flex-shrink: 0;
          flex-shrink: 0;
  width: 100%; }

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.625rem - 1px);
  border-top-right-radius: calc(0.625rem - 1px); }

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.625rem - 1px);
  border-bottom-left-radius: calc(0.625rem - 1px); }

.card-deck .card {
  margin-bottom: 15px; }

@media (min-width: 576px) {
  .card-deck {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row wrap;
            flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px; }
    .card-deck .card {
      -webkit-flex: 1 0;
              flex: 1 0;
      margin-right: 15px;
      margin-bottom: 0;
      margin-left: 15px; } }

.card-group > .card {
  margin-bottom: 15px; }

@media (min-width: 576px) {
  .card-group {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row wrap;
            flex-flow: row wrap; }
    .card-group > .card {
      -webkit-flex: 1 0;
              flex: 1 0;
      margin-bottom: 0; }
      .card-group > .card + .card {
        margin-left: 0;
        border-left: 0; }
      .card-group > .card:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-top,
        .card-group > .card:not(:last-child) .card-header {
          border-top-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-bottom,
        .card-group > .card:not(:last-child) .card-footer {
          border-bottom-right-radius: 0; }
      .card-group > .card:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-top,
        .card-group > .card:not(:first-child) .card-header {
          border-top-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-bottom,
        .card-group > .card:not(:first-child) .card-footer {
          border-bottom-left-radius: 0; } }

.card-columns .card {
  margin-bottom: 0.75rem; }

@media (min-width: 576px) {
  .card-columns {
    -webkit-column-count: 3;
            column-count: 3;
    grid-column-gap: 1.25rem;
    -webkit-column-gap: 1.25rem;
            column-gap: 1.25rem;
    orphans: 1;
    widows: 1; }
    .card-columns .card {
      display: inline-block;
      width: 100%; } }

.accordion {
  overflow-anchor: none; }
  .accordion > .card {
    overflow: hidden; }
    .accordion > .card:not(:last-of-type) {
      border-bottom: 0;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0; }
    .accordion > .card:not(:first-of-type) {
      border-top-left-radius: 0;
      border-top-right-radius: 0; }
    .accordion > .card > .card-header {
      border-radius: 0;
      margin-bottom: -1px; }

.breadcrumb {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.625rem; }

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem; }
  .breadcrumb-item + .breadcrumb-item::before {
    float: left;
    padding-right: 0.5rem;
    color: #6c757d;
    content: "/"; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none; }

.breadcrumb-item.active {
  color: #6c757d; }

.pagination {
  display: -webkit-flex;
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.625rem; }

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #ff7442;
  background-color: #fff;
  border: 1px solid #dee2e6; }
  .page-link:hover {
    z-index: 2;
    color: #f54100;
    text-decoration: none;
    background-color: #e9ecef;
    border-color: #dee2e6; }
  .page-link:focus {
    z-index: 3;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(255, 116, 66, 0.25); }

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.625rem;
  border-bottom-left-radius: 0.625rem; }

.page-item:last-child .page-link {
  border-top-right-radius: 0.625rem;
  border-bottom-right-radius: 0.625rem; }

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #ff7442;
  border-color: #ff7442; }

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6; }

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5; }

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem; }

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem; }

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5; }

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem; }

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem; }

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.625rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .badge {
      transition: none; } }
  a.badge:hover, a.badge:focus {
    text-decoration: none; }
  .badge:empty {
    display: none; }

.btn .badge {
  position: relative;
  top: -1px; }

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem; }

.badge-primary {
  color: #212529;
  background-color: #ff7442; }
  a.badge-primary:hover, a.badge-primary:focus {
    color: #212529;
    background-color: #ff4e0f; }
  a.badge-primary:focus, a.badge-primary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(255, 116, 66, 0.5); }

.badge-secondary {
  color: #212529;
  background-color: #dfe1e0; }
  a.badge-secondary:hover, a.badge-secondary:focus {
    color: #212529;
    background-color: #c5c8c7; }
  a.badge-secondary:focus, a.badge-secondary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(223, 225, 224, 0.5); }

.badge-success {
  color: #fff;
  background-color: #00cf69; }
  a.badge-success:hover, a.badge-success:focus {
    color: #fff;
    background-color: #009c4f; }
  a.badge-success:focus, a.badge-success.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 207, 105, 0.5); }

.badge-info {
  color: #fff;
  background-color: #17a2b8; }
  a.badge-info:hover, a.badge-info:focus {
    color: #fff;
    background-color: #117a8b; }
  a.badge-info:focus, a.badge-info.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.badge-warning {
  color: #212529;
  background-color: #ffc107; }
  a.badge-warning:hover, a.badge-warning:focus {
    color: #212529;
    background-color: #d39e00; }
  a.badge-warning:focus, a.badge-warning.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.badge-danger {
  color: #fff;
  background-color: #dc3545; }
  a.badge-danger:hover, a.badge-danger:focus {
    color: #fff;
    background-color: #bd2130; }
  a.badge-danger:focus, a.badge-danger.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

.badge-light {
  color: #212529;
  background-color: #dfe1e0; }
  a.badge-light:hover, a.badge-light:focus {
    color: #212529;
    background-color: #c5c8c7; }
  a.badge-light:focus, a.badge-light.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(223, 225, 224, 0.5); }

.badge-dark {
  color: #fff;
  background-color: #303449; }
  a.badge-dark:hover, a.badge-dark:focus {
    color: #fff;
    background-color: #1c1e2a; }
  a.badge-dark:focus, a.badge-dark.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(48, 52, 73, 0.5); }

.badge-ternary {
  color: #fff;
  background-color: #f86029; }
  a.badge-ternary:hover, a.badge-ternary:focus {
    color: #fff;
    background-color: #e64308; }
  a.badge-ternary:focus, a.badge-ternary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(248, 96, 41, 0.5); }

.badge-darker {
  color: #fff;
  background-color: #161a2f; }
  a.badge-darker:hover, a.badge-darker:focus {
    color: #fff;
    background-color: #06070c; }
  a.badge-darker:focus, a.badge-darker.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(22, 26, 47, 0.5); }

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem; }
  @media (min-width: 576px) {
    .jumbotron {
      padding: 4rem 2rem; } }

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0; }

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.625rem; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 700; }

.alert-dismissible {
  padding-right: 4rem; }
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    padding: 0.75rem 1.25rem;
    color: inherit; }

.alert-primary {
  color: #853c22;
  background-color: #ffe3d9;
  border-color: #ffd8ca; }
  .alert-primary hr {
    border-top-color: #ffc5b1; }
  .alert-primary .alert-link {
    color: #5c2a18; }

.alert-secondary {
  color: #747574;
  background-color: #f9f9f9;
  border-color: #f6f7f6; }
  .alert-secondary hr {
    border-top-color: #e9ebe9; }
  .alert-secondary .alert-link {
    color: #5b5b5b; }

.alert-success {
  color: #006c37;
  background-color: #ccf5e1;
  border-color: #b8f2d5; }
  .alert-success hr {
    border-top-color: #a2eec8; }
  .alert-success .alert-link {
    color: #00391d; }

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb; }
  .alert-info hr {
    border-top-color: #abdde5; }
  .alert-info .alert-link {
    color: #062c33; }

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba; }
  .alert-warning hr {
    border-top-color: #ffe8a1; }
  .alert-warning .alert-link {
    color: #533f03; }

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb; }
  .alert-danger hr {
    border-top-color: #f1b0b7; }
  .alert-danger .alert-link {
    color: #491217; }

.alert-light {
  color: #747574;
  background-color: #f9f9f9;
  border-color: #f6f7f6; }
  .alert-light hr {
    border-top-color: #e9ebe9; }
  .alert-light .alert-link {
    color: #5b5b5b; }

.alert-dark {
  color: #191b26;
  background-color: #d6d6db;
  border-color: #c5c6cc; }
  .alert-dark hr {
    border-top-color: #b7b9c0; }
  .alert-dark .alert-link {
    color: #050507; }

.alert-ternary {
  color: #813215;
  background-color: #fedfd4;
  border-color: #fdd2c3; }
  .alert-ternary hr {
    border-top-color: #fcbfaa; }
  .alert-ternary .alert-link {
    color: #55210e; }

.alert-darker {
  color: #0b0e18;
  background-color: #d0d1d5;
  border-color: #bebfc5; }
  .alert-darker hr {
    border-top-color: #b1b2b9; }
  .alert-darker .alert-link {
    color: black; }

@-webkit-keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

.progress {
  display: -webkit-flex;
  display: flex;
  height: 1rem;
  overflow: hidden;
  line-height: 0;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.625rem; }

.progress-bar {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #ff7442;
  transition: width 0.6s ease; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar {
      transition: none; } }

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem; }

.progress-bar-animated {
  -webkit-animation: 1s linear infinite progress-bar-stripes;
          animation: 1s linear infinite progress-bar-stripes; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar-animated {
      -webkit-animation: none;
              animation: none; } }

.media {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: flex-start;
          align-items: flex-start; }

.media-body {
  -webkit-flex: 1 1;
          flex: 1 1; }

.list-group {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.625rem; }

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit; }
  .list-group-item-action:hover, .list-group-item-action:focus {
    z-index: 1;
    color: #495057;
    text-decoration: none;
    background-color: #f8f9fa; }
  .list-group-item-action:active {
    color: #161a2f;
    background-color: #e9ecef; }

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125); }
  .list-group-item:first-child {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit; }
  .list-group-item:last-child {
    border-bottom-right-radius: inherit;
    border-bottom-left-radius: inherit; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: #fff; }
  .list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #ff7442;
    border-color: #ff7442; }
  .list-group-item + .list-group-item {
    border-top-width: 0; }
    .list-group-item + .list-group-item.active {
      margin-top: -1px;
      border-top-width: 1px; }

.list-group-horizontal {
  -webkit-flex-direction: row;
          flex-direction: row; }
  .list-group-horizontal > .list-group-item:first-child {
    border-bottom-left-radius: 0.625rem;
    border-top-right-radius: 0; }
  .list-group-horizontal > .list-group-item:last-child {
    border-top-right-radius: 0.625rem;
    border-bottom-left-radius: 0; }
  .list-group-horizontal > .list-group-item.active {
    margin-top: 0; }
  .list-group-horizontal > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0; }
    .list-group-horizontal > .list-group-item + .list-group-item.active {
      margin-left: -1px;
      border-left-width: 1px; }

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    -webkit-flex-direction: row;
            flex-direction: row; }
    .list-group-horizontal-sm > .list-group-item:first-child {
      border-bottom-left-radius: 0.625rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-sm > .list-group-item:last-child {
      border-top-right-radius: 0.625rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-sm > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-sm > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 768px) {
  .list-group-horizontal-md {
    -webkit-flex-direction: row;
            flex-direction: row; }
    .list-group-horizontal-md > .list-group-item:first-child {
      border-bottom-left-radius: 0.625rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-md > .list-group-item:last-child {
      border-top-right-radius: 0.625rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-md > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-md > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-md > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    -webkit-flex-direction: row;
            flex-direction: row; }
    .list-group-horizontal-lg > .list-group-item:first-child {
      border-bottom-left-radius: 0.625rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-lg > .list-group-item:last-child {
      border-top-right-radius: 0.625rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-lg > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-lg > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    -webkit-flex-direction: row;
            flex-direction: row; }
    .list-group-horizontal-xl > .list-group-item:first-child {
      border-bottom-left-radius: 0.625rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-xl > .list-group-item:last-child {
      border-top-right-radius: 0.625rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-xl > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-xl > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

.list-group-flush {
  border-radius: 0; }
  .list-group-flush > .list-group-item {
    border-width: 0 0 1px; }
    .list-group-flush > .list-group-item:last-child {
      border-bottom-width: 0; }

.list-group-item-primary {
  color: #853c22;
  background-color: #ffd8ca; }
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #853c22;
    background-color: #ffc5b1; }
  .list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #853c22;
    border-color: #853c22; }

.list-group-item-secondary {
  color: #747574;
  background-color: #f6f7f6; }
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #747574;
    background-color: #e9ebe9; }
  .list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #747574;
    border-color: #747574; }

.list-group-item-success {
  color: #006c37;
  background-color: #b8f2d5; }
  .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #006c37;
    background-color: #a2eec8; }
  .list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #006c37;
    border-color: #006c37; }

.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb; }
  .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #0c5460;
    background-color: #abdde5; }
  .list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #0c5460;
    border-color: #0c5460; }

.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba; }
  .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #856404;
    background-color: #ffe8a1; }
  .list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #856404;
    border-color: #856404; }

.list-group-item-danger {
  color: #721c24;
  background-color: #f5c6cb; }
  .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #721c24;
    background-color: #f1b0b7; }
  .list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #721c24;
    border-color: #721c24; }

.list-group-item-light {
  color: #747574;
  background-color: #f6f7f6; }
  .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #747574;
    background-color: #e9ebe9; }
  .list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #747574;
    border-color: #747574; }

.list-group-item-dark {
  color: #191b26;
  background-color: #c5c6cc; }
  .list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: #191b26;
    background-color: #b7b9c0; }
  .list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #191b26;
    border-color: #191b26; }

.list-group-item-ternary {
  color: #813215;
  background-color: #fdd2c3; }
  .list-group-item-ternary.list-group-item-action:hover, .list-group-item-ternary.list-group-item-action:focus {
    color: #813215;
    background-color: #fcbfaa; }
  .list-group-item-ternary.list-group-item-action.active {
    color: #fff;
    background-color: #813215;
    border-color: #813215; }

.list-group-item-darker {
  color: #0b0e18;
  background-color: #bebfc5; }
  .list-group-item-darker.list-group-item-action:hover, .list-group-item-darker.list-group-item-action:focus {
    color: #0b0e18;
    background-color: #b1b2b9; }
  .list-group-item-darker.list-group-item-action.active {
    color: #fff;
    background-color: #0b0e18;
    border-color: #0b0e18; }

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5; }
  @media (max-width: 1200px) {
    .close {
      font-size: calc(1.275rem + 0.3vw) ; } }
  .close:hover {
    color: #000;
    text-decoration: none; }
  .close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
    opacity: .75; }

button.close {
  padding: 0;
  background-color: transparent;
  border: 0; }

a.close.disabled {
  pointer-events: none; }

.toast {
  -webkit-flex-basis: 350px;
          flex-basis: 350px;
  max-width: 350px;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  opacity: 0;
  border-radius: 0.25rem; }
  .toast:not(:last-child) {
    margin-bottom: 0.75rem; }
  .toast.showing {
    opacity: 1; }
  .toast.show {
    display: block;
    opacity: 1; }
  .toast.hide {
    display: none; }

.toast-header {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px); }

.toast-body {
  padding: 0.75rem; }

.modal-open {
  overflow: hidden; }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    transition: -webkit-transform 0.3s ease-out;
    transition: transform 0.3s ease-out;
    transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
    -webkit-transform: translate(0, -50px);
            transform: translate(0, -50px); }
    @media (prefers-reduced-motion: reduce) {
      .modal.fade .modal-dialog {
        transition: none; } }
  .modal.show .modal-dialog {
    -webkit-transform: none;
            transform: none; }
  .modal.modal-static .modal-dialog {
    -webkit-transform: scale(1.02);
            transform: scale(1.02); }

.modal-dialog-scrollable {
  display: -webkit-flex;
  display: flex;
  max-height: calc(100% - 1rem); }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 1rem);
    overflow: hidden; }
  .modal-dialog-scrollable .modal-header,
  .modal-dialog-scrollable .modal-footer {
    -webkit-flex-shrink: 0;
            flex-shrink: 0; }
  .modal-dialog-scrollable .modal-body {
    overflow-y: auto; }

.modal-dialog-centered {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  min-height: calc(100% - 1rem); }
  .modal-dialog-centered::before {
    display: block;
    height: calc(100vh - 1rem);
    height: -webkit-min-content;
    height: min-content;
    content: ""; }
  .modal-dialog-centered.modal-dialog-scrollable {
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    height: 100%; }
    .modal-dialog-centered.modal-dialog-scrollable .modal-content {
      max-height: none; }
    .modal-dialog-centered.modal-dialog-scrollable::before {
      content: none; }

.modal-content {
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  width: 100%;
  color: #161a2f;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }

.modal-header {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .modal-header .close {
    padding: 1rem 1rem;
    margin: -1rem -1rem -1rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.5; }

.modal-body {
  position: relative;
  -webkit-flex: 1 1 auto;
          flex: 1 1 auto;
  padding: 1rem; }

.modal-footer {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px); }
  .modal-footer > * {
    margin: 0.25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto; }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem); }
    .modal-dialog-scrollable .modal-content {
      max-height: calc(100vh - 3.5rem); }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem); }
    .modal-dialog-centered::before {
      height: calc(100vh - 3.5rem);
      height: -webkit-min-content;
      height: min-content; }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px; } }

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px; } }

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: 0.9; }
  .tooltip .arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem; }
    .tooltip .arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0; }
  .bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
    bottom: 0; }
    .bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
      top: 0;
      border-width: 0.4rem 0.4rem 0;
      border-top-color: #000; }

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem; }
  .bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
      right: 0;
      border-width: 0.4rem 0.4rem 0.4rem 0;
      border-right-color: #000; }

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0; }
  .bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
    top: 0; }
    .bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
      bottom: 0;
      border-width: 0 0.4rem 0.4rem;
      border-bottom-color: #000; }

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem; }
  .bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
      left: 0;
      border-width: 0.4rem 0 0.4rem 0.4rem;
      border-left-color: #000; }

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.625rem; }

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem; }
  .popover .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 0.3rem; }
    .popover .arrow::before, .popover .arrow::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem; }
  .bs-popover-top > .arrow, .bs-popover-auto[x-placement^="top"] > .arrow {
    bottom: calc(-0.5rem - 1px); }
    .bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^="top"] > .arrow::before {
      bottom: 0;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^="top"] > .arrow::after {
      bottom: 1px;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: #fff; }

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem; }
  .bs-popover-right > .arrow, .bs-popover-auto[x-placement^="right"] > .arrow {
    left: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^="right"] > .arrow::before {
      left: 0;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^="right"] > .arrow::after {
      left: 1px;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: #fff; }

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem; }
  .bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^="bottom"] > .arrow {
    top: calc(-0.5rem - 1px); }
    .bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^="bottom"] > .arrow::before {
      top: 0;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^="bottom"] > .arrow::after {
      top: 1px;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: #fff; }
  .bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: "";
    border-bottom: 1px solid #f7f7f7; }

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem; }
  .bs-popover-left > .arrow, .bs-popover-auto[x-placement^="left"] > .arrow {
    right: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^="left"] > .arrow::before {
      right: 0;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^="left"] > .arrow::after {
      right: 1px;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: #fff; }

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .popover-header:empty {
    display: none; }

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #161a2f; }

.carousel {
  position: relative; }

.carousel.pointer-event {
  touch-action: pan-y; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }
  .carousel-inner::after {
    display: block;
    clear: both;
    content: ""; }

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  transition: -webkit-transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out, -webkit-transform 0.6s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-item {
      transition: none; } }

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block; }

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  -webkit-transform: translateX(100%);
          transform: translateX(100%); }

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%); }

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  -webkit-transform: none;
          transform: none; }

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1; }

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-fade .active.carousel-item-left,
    .carousel-fade .active.carousel-item-right {
      transition: none; } }

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-control-prev,
    .carousel-control-next {
      transition: none; } }
  .carousel-control-prev:hover, .carousel-control-prev:focus,
  .carousel-control-next:hover,
  .carousel-control-next:focus {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: 0.9; }

.carousel-control-prev {
  left: 0; }

.carousel-control-next {
  right: 0; }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: 50% / 100% 100% no-repeat; }

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e"); }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e"); }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none; }
  .carousel-indicators li {
    box-sizing: content-box;
    -webkit-flex: 0 1 auto;
            flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: .5;
    transition: opacity 0.6s ease; }
    @media (prefers-reduced-motion: reduce) {
      .carousel-indicators li {
        transition: none; } }
  .carousel-indicators .active {
    opacity: 1; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center; }

@-webkit-keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: .75s linear infinite spinner-border;
          animation: .75s linear infinite spinner-border; }

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em; }

@-webkit-keyframes spinner-grow {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0); }
  50% {
    opacity: 1;
    -webkit-transform: none;
            transform: none; } }

@keyframes spinner-grow {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0); }
  50% {
    opacity: 1;
    -webkit-transform: none;
            transform: none; } }

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  -webkit-animation: .75s linear infinite spinner-grow;
          animation: .75s linear infinite spinner-grow; }

.spinner-grow-sm {
  width: 1rem;
  height: 1rem; }

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
  .spinner-grow {
    -webkit-animation-duration: 1.5s;
            animation-duration: 1.5s; } }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #ff7442 !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #ff4e0f !important; }

.bg-secondary {
  background-color: #dfe1e0 !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #c5c8c7 !important; }

.bg-success {
  background-color: #00cf69 !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #009c4f !important; }

.bg-info {
  background-color: #17a2b8 !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important; }

.bg-warning {
  background-color: #ffc107 !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important; }

.bg-danger {
  background-color: #dc3545 !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important; }

.bg-light {
  background-color: #dfe1e0 !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #c5c8c7 !important; }

.bg-dark {
  background-color: #303449 !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1c1e2a !important; }

.bg-ternary {
  background-color: #f86029 !important; }

a.bg-ternary:hover, a.bg-ternary:focus,
button.bg-ternary:hover,
button.bg-ternary:focus {
  background-color: #e64308 !important; }

.bg-darker {
  background-color: #161a2f !important; }

a.bg-darker:hover, a.bg-darker:focus,
button.bg-darker:hover,
button.bg-darker:focus {
  background-color: #06070c !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #dee2e6 !important; }

.border-top {
  border-top: 1px solid #dee2e6 !important; }

.border-right {
  border-right: 1px solid #dee2e6 !important; }

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important; }

.border-left {
  border-left: 1px solid #dee2e6 !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #ff7442 !important; }

.border-secondary {
  border-color: #dfe1e0 !important; }

.border-success {
  border-color: #00cf69 !important; }

.border-info {
  border-color: #17a2b8 !important; }

.border-warning {
  border-color: #ffc107 !important; }

.border-danger {
  border-color: #dc3545 !important; }

.border-light {
  border-color: #dfe1e0 !important; }

.border-dark {
  border-color: #303449 !important; }

.border-ternary {
  border-color: #f86029 !important; }

.border-darker {
  border-color: #161a2f !important; }

.border-white {
  border-color: #fff !important; }

.rounded-sm {
  border-radius: 0.2rem !important; }

.rounded {
  border-radius: 0.625rem !important; }

.rounded-top {
  border-top-left-radius: 0.625rem !important;
  border-top-right-radius: 0.625rem !important; }

.rounded-right {
  border-top-right-radius: 0.625rem !important;
  border-bottom-right-radius: 0.625rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.625rem !important;
  border-bottom-left-radius: 0.625rem !important; }

.rounded-left {
  border-top-left-radius: 0.625rem !important;
  border-bottom-left-radius: 0.625rem !important; }

.rounded-lg {
  border-radius: 0.3rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 50rem !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: -webkit-flex !important;
  display: flex !important; }

.d-inline-flex {
  display: -webkit-inline-flex !important;
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: -webkit-flex !important;
    display: flex !important; }
  .d-sm-inline-flex {
    display: -webkit-inline-flex !important;
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: -webkit-flex !important;
    display: flex !important; }
  .d-md-inline-flex {
    display: -webkit-inline-flex !important;
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: -webkit-flex !important;
    display: flex !important; }
  .d-lg-inline-flex {
    display: -webkit-inline-flex !important;
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: -webkit-flex !important;
    display: flex !important; }
  .d-xl-inline-flex {
    display: -webkit-inline-flex !important;
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: -webkit-flex !important;
    display: flex !important; }
  .d-print-inline-flex {
    display: -webkit-inline-flex !important;
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  -webkit-flex-direction: row !important;
          flex-direction: row !important; }

.flex-column {
  -webkit-flex-direction: column !important;
          flex-direction: column !important; }

.flex-row-reverse {
  -webkit-flex-direction: row-reverse !important;
          flex-direction: row-reverse !important; }

.flex-column-reverse {
  -webkit-flex-direction: column-reverse !important;
          flex-direction: column-reverse !important; }

.flex-wrap {
  -webkit-flex-wrap: wrap !important;
          flex-wrap: wrap !important; }

.flex-nowrap {
  -webkit-flex-wrap: nowrap !important;
          flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  -webkit-flex-wrap: wrap-reverse !important;
          flex-wrap: wrap-reverse !important; }

.flex-fill {
  -webkit-flex: 1 1 auto !important;
          flex: 1 1 auto !important; }

.flex-grow-0 {
  -webkit-flex-grow: 0 !important;
          flex-grow: 0 !important; }

.flex-grow-1 {
  -webkit-flex-grow: 1 !important;
          flex-grow: 1 !important; }

.flex-shrink-0 {
  -webkit-flex-shrink: 0 !important;
          flex-shrink: 0 !important; }

.flex-shrink-1 {
  -webkit-flex-shrink: 1 !important;
          flex-shrink: 1 !important; }

.justify-content-start {
  -webkit-justify-content: flex-start !important;
          justify-content: flex-start !important; }

.justify-content-end {
  -webkit-justify-content: flex-end !important;
          justify-content: flex-end !important; }

.justify-content-center {
  -webkit-justify-content: center !important;
          justify-content: center !important; }

.justify-content-between {
  -webkit-justify-content: space-between !important;
          justify-content: space-between !important; }

.justify-content-around {
  -webkit-justify-content: space-around !important;
          justify-content: space-around !important; }

.align-items-start {
  -webkit-align-items: flex-start !important;
          align-items: flex-start !important; }

.align-items-end {
  -webkit-align-items: flex-end !important;
          align-items: flex-end !important; }

.align-items-center {
  -webkit-align-items: center !important;
          align-items: center !important; }

.align-items-baseline {
  -webkit-align-items: baseline !important;
          align-items: baseline !important; }

.align-items-stretch {
  -webkit-align-items: stretch !important;
          align-items: stretch !important; }

.align-content-start {
  -webkit-align-content: flex-start !important;
          align-content: flex-start !important; }

.align-content-end {
  -webkit-align-content: flex-end !important;
          align-content: flex-end !important; }

.align-content-center {
  -webkit-align-content: center !important;
          align-content: center !important; }

.align-content-between {
  -webkit-align-content: space-between !important;
          align-content: space-between !important; }

.align-content-around {
  -webkit-align-content: space-around !important;
          align-content: space-around !important; }

.align-content-stretch {
  -webkit-align-content: stretch !important;
          align-content: stretch !important; }

.align-self-auto {
  -webkit-align-self: auto !important;
          align-self: auto !important; }

.align-self-start {
  -webkit-align-self: flex-start !important;
          align-self: flex-start !important; }

.align-self-end {
  -webkit-align-self: flex-end !important;
          align-self: flex-end !important; }

.align-self-center {
  -webkit-align-self: center !important;
          align-self: center !important; }

.align-self-baseline {
  -webkit-align-self: baseline !important;
          align-self: baseline !important; }

.align-self-stretch {
  -webkit-align-self: stretch !important;
          align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    -webkit-flex-direction: row !important;
            flex-direction: row !important; }
  .flex-sm-column {
    -webkit-flex-direction: column !important;
            flex-direction: column !important; }
  .flex-sm-row-reverse {
    -webkit-flex-direction: row-reverse !important;
            flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    -webkit-flex-direction: column-reverse !important;
            flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    -webkit-flex-wrap: wrap !important;
            flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    -webkit-flex-wrap: nowrap !important;
            flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    -webkit-flex-wrap: wrap-reverse !important;
            flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    -webkit-flex: 1 1 auto !important;
            flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    -webkit-flex-grow: 0 !important;
            flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    -webkit-flex-grow: 1 !important;
            flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    -webkit-flex-shrink: 0 !important;
            flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    -webkit-flex-shrink: 1 !important;
            flex-shrink: 1 !important; }
  .justify-content-sm-start {
    -webkit-justify-content: flex-start !important;
            justify-content: flex-start !important; }
  .justify-content-sm-end {
    -webkit-justify-content: flex-end !important;
            justify-content: flex-end !important; }
  .justify-content-sm-center {
    -webkit-justify-content: center !important;
            justify-content: center !important; }
  .justify-content-sm-between {
    -webkit-justify-content: space-between !important;
            justify-content: space-between !important; }
  .justify-content-sm-around {
    -webkit-justify-content: space-around !important;
            justify-content: space-around !important; }
  .align-items-sm-start {
    -webkit-align-items: flex-start !important;
            align-items: flex-start !important; }
  .align-items-sm-end {
    -webkit-align-items: flex-end !important;
            align-items: flex-end !important; }
  .align-items-sm-center {
    -webkit-align-items: center !important;
            align-items: center !important; }
  .align-items-sm-baseline {
    -webkit-align-items: baseline !important;
            align-items: baseline !important; }
  .align-items-sm-stretch {
    -webkit-align-items: stretch !important;
            align-items: stretch !important; }
  .align-content-sm-start {
    -webkit-align-content: flex-start !important;
            align-content: flex-start !important; }
  .align-content-sm-end {
    -webkit-align-content: flex-end !important;
            align-content: flex-end !important; }
  .align-content-sm-center {
    -webkit-align-content: center !important;
            align-content: center !important; }
  .align-content-sm-between {
    -webkit-align-content: space-between !important;
            align-content: space-between !important; }
  .align-content-sm-around {
    -webkit-align-content: space-around !important;
            align-content: space-around !important; }
  .align-content-sm-stretch {
    -webkit-align-content: stretch !important;
            align-content: stretch !important; }
  .align-self-sm-auto {
    -webkit-align-self: auto !important;
            align-self: auto !important; }
  .align-self-sm-start {
    -webkit-align-self: flex-start !important;
            align-self: flex-start !important; }
  .align-self-sm-end {
    -webkit-align-self: flex-end !important;
            align-self: flex-end !important; }
  .align-self-sm-center {
    -webkit-align-self: center !important;
            align-self: center !important; }
  .align-self-sm-baseline {
    -webkit-align-self: baseline !important;
            align-self: baseline !important; }
  .align-self-sm-stretch {
    -webkit-align-self: stretch !important;
            align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    -webkit-flex-direction: row !important;
            flex-direction: row !important; }
  .flex-md-column {
    -webkit-flex-direction: column !important;
            flex-direction: column !important; }
  .flex-md-row-reverse {
    -webkit-flex-direction: row-reverse !important;
            flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    -webkit-flex-direction: column-reverse !important;
            flex-direction: column-reverse !important; }
  .flex-md-wrap {
    -webkit-flex-wrap: wrap !important;
            flex-wrap: wrap !important; }
  .flex-md-nowrap {
    -webkit-flex-wrap: nowrap !important;
            flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    -webkit-flex-wrap: wrap-reverse !important;
            flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    -webkit-flex: 1 1 auto !important;
            flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    -webkit-flex-grow: 0 !important;
            flex-grow: 0 !important; }
  .flex-md-grow-1 {
    -webkit-flex-grow: 1 !important;
            flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    -webkit-flex-shrink: 0 !important;
            flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    -webkit-flex-shrink: 1 !important;
            flex-shrink: 1 !important; }
  .justify-content-md-start {
    -webkit-justify-content: flex-start !important;
            justify-content: flex-start !important; }
  .justify-content-md-end {
    -webkit-justify-content: flex-end !important;
            justify-content: flex-end !important; }
  .justify-content-md-center {
    -webkit-justify-content: center !important;
            justify-content: center !important; }
  .justify-content-md-between {
    -webkit-justify-content: space-between !important;
            justify-content: space-between !important; }
  .justify-content-md-around {
    -webkit-justify-content: space-around !important;
            justify-content: space-around !important; }
  .align-items-md-start {
    -webkit-align-items: flex-start !important;
            align-items: flex-start !important; }
  .align-items-md-end {
    -webkit-align-items: flex-end !important;
            align-items: flex-end !important; }
  .align-items-md-center {
    -webkit-align-items: center !important;
            align-items: center !important; }
  .align-items-md-baseline {
    -webkit-align-items: baseline !important;
            align-items: baseline !important; }
  .align-items-md-stretch {
    -webkit-align-items: stretch !important;
            align-items: stretch !important; }
  .align-content-md-start {
    -webkit-align-content: flex-start !important;
            align-content: flex-start !important; }
  .align-content-md-end {
    -webkit-align-content: flex-end !important;
            align-content: flex-end !important; }
  .align-content-md-center {
    -webkit-align-content: center !important;
            align-content: center !important; }
  .align-content-md-between {
    -webkit-align-content: space-between !important;
            align-content: space-between !important; }
  .align-content-md-around {
    -webkit-align-content: space-around !important;
            align-content: space-around !important; }
  .align-content-md-stretch {
    -webkit-align-content: stretch !important;
            align-content: stretch !important; }
  .align-self-md-auto {
    -webkit-align-self: auto !important;
            align-self: auto !important; }
  .align-self-md-start {
    -webkit-align-self: flex-start !important;
            align-self: flex-start !important; }
  .align-self-md-end {
    -webkit-align-self: flex-end !important;
            align-self: flex-end !important; }
  .align-self-md-center {
    -webkit-align-self: center !important;
            align-self: center !important; }
  .align-self-md-baseline {
    -webkit-align-self: baseline !important;
            align-self: baseline !important; }
  .align-self-md-stretch {
    -webkit-align-self: stretch !important;
            align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    -webkit-flex-direction: row !important;
            flex-direction: row !important; }
  .flex-lg-column {
    -webkit-flex-direction: column !important;
            flex-direction: column !important; }
  .flex-lg-row-reverse {
    -webkit-flex-direction: row-reverse !important;
            flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    -webkit-flex-direction: column-reverse !important;
            flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    -webkit-flex-wrap: wrap !important;
            flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    -webkit-flex-wrap: nowrap !important;
            flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    -webkit-flex-wrap: wrap-reverse !important;
            flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    -webkit-flex: 1 1 auto !important;
            flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    -webkit-flex-grow: 0 !important;
            flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    -webkit-flex-grow: 1 !important;
            flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    -webkit-flex-shrink: 0 !important;
            flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    -webkit-flex-shrink: 1 !important;
            flex-shrink: 1 !important; }
  .justify-content-lg-start {
    -webkit-justify-content: flex-start !important;
            justify-content: flex-start !important; }
  .justify-content-lg-end {
    -webkit-justify-content: flex-end !important;
            justify-content: flex-end !important; }
  .justify-content-lg-center {
    -webkit-justify-content: center !important;
            justify-content: center !important; }
  .justify-content-lg-between {
    -webkit-justify-content: space-between !important;
            justify-content: space-between !important; }
  .justify-content-lg-around {
    -webkit-justify-content: space-around !important;
            justify-content: space-around !important; }
  .align-items-lg-start {
    -webkit-align-items: flex-start !important;
            align-items: flex-start !important; }
  .align-items-lg-end {
    -webkit-align-items: flex-end !important;
            align-items: flex-end !important; }
  .align-items-lg-center {
    -webkit-align-items: center !important;
            align-items: center !important; }
  .align-items-lg-baseline {
    -webkit-align-items: baseline !important;
            align-items: baseline !important; }
  .align-items-lg-stretch {
    -webkit-align-items: stretch !important;
            align-items: stretch !important; }
  .align-content-lg-start {
    -webkit-align-content: flex-start !important;
            align-content: flex-start !important; }
  .align-content-lg-end {
    -webkit-align-content: flex-end !important;
            align-content: flex-end !important; }
  .align-content-lg-center {
    -webkit-align-content: center !important;
            align-content: center !important; }
  .align-content-lg-between {
    -webkit-align-content: space-between !important;
            align-content: space-between !important; }
  .align-content-lg-around {
    -webkit-align-content: space-around !important;
            align-content: space-around !important; }
  .align-content-lg-stretch {
    -webkit-align-content: stretch !important;
            align-content: stretch !important; }
  .align-self-lg-auto {
    -webkit-align-self: auto !important;
            align-self: auto !important; }
  .align-self-lg-start {
    -webkit-align-self: flex-start !important;
            align-self: flex-start !important; }
  .align-self-lg-end {
    -webkit-align-self: flex-end !important;
            align-self: flex-end !important; }
  .align-self-lg-center {
    -webkit-align-self: center !important;
            align-self: center !important; }
  .align-self-lg-baseline {
    -webkit-align-self: baseline !important;
            align-self: baseline !important; }
  .align-self-lg-stretch {
    -webkit-align-self: stretch !important;
            align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    -webkit-flex-direction: row !important;
            flex-direction: row !important; }
  .flex-xl-column {
    -webkit-flex-direction: column !important;
            flex-direction: column !important; }
  .flex-xl-row-reverse {
    -webkit-flex-direction: row-reverse !important;
            flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    -webkit-flex-direction: column-reverse !important;
            flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    -webkit-flex-wrap: wrap !important;
            flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    -webkit-flex-wrap: nowrap !important;
            flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    -webkit-flex-wrap: wrap-reverse !important;
            flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    -webkit-flex: 1 1 auto !important;
            flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    -webkit-flex-grow: 0 !important;
            flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    -webkit-flex-grow: 1 !important;
            flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    -webkit-flex-shrink: 0 !important;
            flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    -webkit-flex-shrink: 1 !important;
            flex-shrink: 1 !important; }
  .justify-content-xl-start {
    -webkit-justify-content: flex-start !important;
            justify-content: flex-start !important; }
  .justify-content-xl-end {
    -webkit-justify-content: flex-end !important;
            justify-content: flex-end !important; }
  .justify-content-xl-center {
    -webkit-justify-content: center !important;
            justify-content: center !important; }
  .justify-content-xl-between {
    -webkit-justify-content: space-between !important;
            justify-content: space-between !important; }
  .justify-content-xl-around {
    -webkit-justify-content: space-around !important;
            justify-content: space-around !important; }
  .align-items-xl-start {
    -webkit-align-items: flex-start !important;
            align-items: flex-start !important; }
  .align-items-xl-end {
    -webkit-align-items: flex-end !important;
            align-items: flex-end !important; }
  .align-items-xl-center {
    -webkit-align-items: center !important;
            align-items: center !important; }
  .align-items-xl-baseline {
    -webkit-align-items: baseline !important;
            align-items: baseline !important; }
  .align-items-xl-stretch {
    -webkit-align-items: stretch !important;
            align-items: stretch !important; }
  .align-content-xl-start {
    -webkit-align-content: flex-start !important;
            align-content: flex-start !important; }
  .align-content-xl-end {
    -webkit-align-content: flex-end !important;
            align-content: flex-end !important; }
  .align-content-xl-center {
    -webkit-align-content: center !important;
            align-content: center !important; }
  .align-content-xl-between {
    -webkit-align-content: space-between !important;
            align-content: space-between !important; }
  .align-content-xl-around {
    -webkit-align-content: space-around !important;
            align-content: space-around !important; }
  .align-content-xl-stretch {
    -webkit-align-content: stretch !important;
            align-content: stretch !important; }
  .align-self-xl-auto {
    -webkit-align-self: auto !important;
            align-self: auto !important; }
  .align-self-xl-start {
    -webkit-align-self: flex-start !important;
            align-self: flex-start !important; }
  .align-self-xl-end {
    -webkit-align-self: flex-end !important;
            align-self: flex-end !important; }
  .align-self-xl-center {
    -webkit-align-self: center !important;
            align-self: center !important; }
  .align-self-xl-baseline {
    -webkit-align-self: baseline !important;
            align-self: baseline !important; }
  .align-self-xl-stretch {
    -webkit-align-self: stretch !important;
            align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.user-select-all {
  -webkit-user-select: all !important;
      -ms-user-select: all !important;
          user-select: all !important; }

.user-select-auto {
  -webkit-user-select: auto !important;
      -ms-user-select: auto !important;
          user-select: auto !important; }

.user-select-none {
  -webkit-user-select: none !important;
      -ms-user-select: none !important;
          user-select: none !important; }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: -webkit-sticky !important;
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sticky-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.vw-100 {
  width: 100vw !important; }

.vh-100 {
  height: 100vh !important; }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.m-n1 {
  margin: -0.25rem !important; }

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important; }

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important; }

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important; }

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important; }

.m-n2 {
  margin: -0.5rem !important; }

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important; }

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important; }

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important; }

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important; }

.m-n3 {
  margin: -1rem !important; }

.mt-n3,
.my-n3 {
  margin-top: -1rem !important; }

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important; }

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important; }

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important; }

.m-n4 {
  margin: -1.5rem !important; }

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important; }

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important; }

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important; }

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important; }

.m-n5 {
  margin: -3rem !important; }

.mt-n5,
.my-n5 {
  margin-top: -3rem !important; }

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important; }

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important; }

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .m-sm-n1 {
    margin: -0.25rem !important; }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important; }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important; }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important; }
  .m-sm-n2 {
    margin: -0.5rem !important; }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important; }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important; }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important; }
  .m-sm-n3 {
    margin: -1rem !important; }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important; }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important; }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important; }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important; }
  .m-sm-n4 {
    margin: -1.5rem !important; }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important; }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important; }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important; }
  .m-sm-n5 {
    margin: -3rem !important; }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important; }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important; }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important; }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .m-md-n1 {
    margin: -0.25rem !important; }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important; }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important; }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important; }
  .m-md-n2 {
    margin: -0.5rem !important; }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important; }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important; }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important; }
  .m-md-n3 {
    margin: -1rem !important; }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important; }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important; }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important; }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important; }
  .m-md-n4 {
    margin: -1.5rem !important; }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important; }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important; }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important; }
  .m-md-n5 {
    margin: -3rem !important; }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important; }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important; }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important; }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .m-lg-n1 {
    margin: -0.25rem !important; }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important; }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important; }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important; }
  .m-lg-n2 {
    margin: -0.5rem !important; }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important; }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important; }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important; }
  .m-lg-n3 {
    margin: -1rem !important; }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important; }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important; }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important; }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important; }
  .m-lg-n4 {
    margin: -1.5rem !important; }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important; }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important; }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important; }
  .m-lg-n5 {
    margin: -3rem !important; }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important; }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important; }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important; }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .m-xl-n1 {
    margin: -0.25rem !important; }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important; }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important; }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important; }
  .m-xl-n2 {
    margin: -0.5rem !important; }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important; }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important; }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important; }
  .m-xl-n3 {
    margin: -1rem !important; }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important; }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important; }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important; }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important; }
  .m-xl-n4 {
    margin: -1.5rem !important; }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important; }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important; }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important; }
  .m-xl-n5 {
    margin: -3rem !important; }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important; }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important; }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important; }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0); }

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important; }

.text-justify {
  text-align: justify !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-lighter {
  font-weight: lighter !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-weight-bolder {
  font-weight: bolder !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #fff !important; }

.text-primary {
  color: #ff7442 !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #f54100 !important; }

.text-secondary {
  color: #dfe1e0 !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #b8bcba !important; }

.text-success {
  color: #00cf69 !important; }

a.text-success:hover, a.text-success:focus {
  color: #008342 !important; }

.text-info {
  color: #17a2b8 !important; }

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important; }

.text-warning {
  color: #ffc107 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important; }

.text-danger {
  color: #dc3545 !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #a71d2a !important; }

.text-light {
  color: #dfe1e0 !important; }

a.text-light:hover, a.text-light:focus {
  color: #b8bcba !important; }

.text-dark {
  color: #303449 !important; }

a.text-dark:hover, a.text-dark:focus {
  color: #12131b !important; }

.text-ternary {
  color: #f86029 !important; }

a.text-ternary:hover, a.text-ternary:focus {
  color: #ce3c07 !important; }

.text-darker {
  color: #161a2f !important; }

a.text-darker:hover, a.text-darker:focus {
  color: black !important; }

.text-body {
  color: #161a2f !important; }

.text-muted {
  color: #6c757d !important; }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.text-decoration-none {
  text-decoration: none !important; }

.text-break {
  word-break: break-word !important;
  word-wrap: break-word !important; }

.text-reset {
  color: inherit !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important; }
  a:not(.btn) {
    text-decoration: underline; }
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  pre {
    white-space: pre-wrap !important; }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  @page {
    size: a3; }
  body {
    min-width: 992px !important; }
  .container {
    min-width: 992px !important; }
  .navbar {
    display: none; }
  .badge {
    border: 1px solid #000; }
  .table {
    border-collapse: collapse !important; }
    .table td,
    .table th {
      background-color: #fff !important; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important; }
  .table-dark {
    color: inherit; }
    .table-dark th,
    .table-dark td,
    .table-dark thead th,
    .table-dark tbody + tbody {
      border-color: #dee2e6; }
  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6; } }

body {
  font-family: acumin-pro-condensed, sans-serif;
  font-weight: 400;
  font-style: normal;
  background-color: #161a2f;
  background-attachment: fixed;
  overflow: hidden;
  top: 0 !important; }

html, body {
  margin: 0;
  background: #161a2f;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

html, body {
  color: #dfe1e0; }

.lead {
  font-size: 2rem; }

h1 {
  font-size: 6rem;
  color: #ff7442; }

h4 {
  letter-spacing: 0.02em; }

.fill {
  width: 100%;
  height: 100%;
  margin: 0; }

.no-select {
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none; }

.cursor-grab {
  cursor: -webkit-grab;
  cursor: grab; }

.cursor-grabbing {
  cursor: -webkit-grabbing;
  cursor: grabbing; }

.cursor-pointer {
  cursor: pointer; }

.orange {
  color: #ff7442; }

.blue {
  color: #00b0ff; }

.green {
  color: #00cf69; }

.font-smooth {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.alert {
  font-family: "acumin-pro-semi-condensed", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"; }

.globe-wrapper {
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  transition: left 1s, right 1s; }

.globe-progress-wrapper {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  position: fixed;
  top: 0;
  bottom: 0; }

.App {
  height: 100vh;
  overflow: hidden; }
  .App .background-canvas {
    background: black;
    width: 100%;
    height: 100%;
    padding: 0;
    display: block;
    top: 0; }
  .App .progress .progress-bar {
    transition: none !important; }
  .App .main-content {
    height: 100vh;
    overflow: hidden;
    white-space: nowrap; }
  .App .main-column {
    overflow: hidden;
    display: block;
    transition: left 1s, right 1s;
    overflow: hidden;
    margin: 0;
    position: fixed;
    top: 0;
    bottom: 0;
    z-index: 2; }
    .App .main-column.title-page-column {
      z-index: 1; }
    @media (max-width: 768px) {
      .App .main-column:not(.title-page-column) {
        z-index: 2000; } }
  .App .main-column .main-column-content {
    white-space: normal;
    height: 100vh;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    overflow: hidden; }
  .App .main-column-content-inner {
    background: #fff;
    border-top-left-radius: 0.625rem;
    -webkit-flex-grow: 1;
            flex-grow: 1;
    -webkit-flex-shrink: 0;
            flex-shrink: 0;
    height: 100vh;
    overflow-y: auto;
    width: 100%; }
    @media (max-width: 576px) {
      .App .main-column-content-inner {
        padding-bottom: 85px !important; } }
    @media (max-width: 576px) {
      .App .main-column-content-inner.sidemenu {
        padding-bottom: 0px !important; } }
    .App .main-column-content-inner.sidemenu, .App .main-column-content-inner.sidebar-left {
      border-top-left-radius: 0;
      border-bottom-right-radius: 0.625rem;
      border-top-right-radius: 0.625rem; }
    .App .main-column-content-inner.sidebar-right {
      border-bottom-left-radius: 0.625rem;
      border-top-left-radius: 0.625rem; }
  .App .top-menu {
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 20; }
  .App .app-buttons {
    position: fixed;
    -webkit-flex-grow: 0;
            flex-grow: 0;
    -webkit-flex-shrink: 0;
            flex-shrink: 0;
    left: 25px;
    top: 20px;
    z-index: 2; }
    @media (max-width: 768px) {
      .App .app-buttons {
        top: 25px;
        left: 25px; } }
  .App.menu-open .app-buttons, .App.data-full-width .app-buttons, .App.data-index-full-width .app-buttons, .App.resource-tree-full-width .app-buttons {
    z-index: 0; }
  .App .menu-button {
    border: none;
    border-radius: 0;
    cursor: pointer;
    display: inline-block;
    height: 14px;
    width: 35px; }
    .App .menu-button span {
      display: block;
      border-top: 2px solid #fff;
      margin-bottom: 5px;
      width: 100%; }
  .App .lang-button {
    color: #fff;
    cursor: pointer;
    font-size: 16px;
    max-width: 70px; }
    .App .lang-button button {
      max-width: 70px; }
    .App .lang-button span {
      font-weight: 700;
      letter-spacing: 0.08em; }
  .App .user-button {
    color: #fff;
    font-size: 26px;
    letter-spacing: 0.02em;
    line-height: 1;
    position: relative; }
    .App .user-button.has-notifications::after {
      content: "";
      border-radius: 50%;
      width: 8px;
      height: 8px;
      background: #bf2323;
      display: block;
      position: absolute;
      right: -3px;
      bottom: -2px; }
  .App #app-search-button {
    top: 20px; }
    @media (max-width: 575px) {
      .App #app-search-button {
        z-index: 2; } }
  .App .search-button,
  .App .close-button {
    background: #ededee;
    border: 0;
    font-weight: 300;
    height: 60px;
    line-height: 60px;
    padding: 0;
    position: absolute;
    top: 40px;
    width: 60px; }
    .App .search-button.left,
    .App .close-button.left {
      left: 40px; }
    .App .search-button.right,
    .App .close-button.right {
      right: 40px; }
    .App .search-button:hover,
    .App .close-button:hover {
      background: #fff; }
    @media (max-width: 575px) {
      .App .search-button,
      .App .close-button {
        font-size: 15px !important;
        width: 40px;
        height: 40px;
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: center;
                align-items: center;
        -webkit-justify-content: center;
                justify-content: center; }
        .App .search-button.right,
        .App .close-button.right {
          right: 20px !important; } }
  .App .search-button {
    font-size: 22px; }
  .App .close-button {
    font-size: 26px; }
  .App .sidebar-left .close-button {
    left: 40px; }
  .App .sidebar-right .close-button {
    right: 40px; }
  .App .page-col {
    height: 100vh;
    display: inline-block; }
  .App .globe-tooltip {
    position: fixed;
    border-radius: 0.625rem;
    background: #ff7442; }
  .App .globe-tooltip .triangle {
    position: absolute;
    width: 0;
    height: 0;
    left: 50%;
    bottom: -10px;
    margin-left: -15px;
    border-style: solid;
    border-width: 10px 15px 0 15px;
    border-color: #ff7442 transparent transparent transparent; }

.splash {
  background: #161a2f;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1050;
  transition: opacity 1s ease; }
  .splash.has-message {
    position: static;
    position: initial;
    margin-top: 250px; }
    @media (max-width: 500px) {
      .splash.has-message {
        margin-top: 150px; } }
  .splash .splash-message {
    width: 500px;
    margin-top: 30px;
    padding: 10px; }
  .splash .main-title {
    font-size: 6.2vw;
    letter-spacing: -0.32px;
    background: linear-gradient(to right, #f74e50, #ff7442);
    display: inline;
    -webkit-background-clip: text;
            background-clip: text;
    text-fill-color: transparent;
    -webkit-text-fill-color: transparent;
    font-weight: 700;
    padding-left: 3rem; }
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      .splash .main-title {
        background: none;
        color: #ff7442; } }
    @media (max-width: 1380px) {
      .splash .main-title {
        padding-left: 0px; } }
    @media (max-width: 1200px) {
      .splash .main-title {
        font-size: 5vw; } }
    @media (max-width: 768px) {
      .splash .main-title {
        font-size: 60px; } }
    @media (max-width: 576px) {
      .splash .main-title {
        font-size: 45px; } }

.pill-list .list-inline-item {
  border: 1px solid #ebebeb;
  border-radius: 19px;
  color: #383838;
  font-weight: 500;
  letter-spacing: 0.01em;
  padding: 0.5rem 1rem;
  cursor: pointer;
  transition: all 200ms ease-in; }
  .pill-list .list-inline-item:hover {
    background: #ff7442;
    color: white; }

.news-tag {
  z-index: 1; }
  .news-tag:hover {
    z-index: 5; }

.pulse {
  z-index: 1; }
  .pulse:hover + .news-tag {
    visibility: visible;
    z-index: 1; }
  .pulse a {
    box-shadow: 0 0 0 0 #ff7442;
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-animation: pulse 2s infinite;
            animation: pulse 2s infinite; }

@-webkit-keyframes pulse {
  0% {
    -webkit-transform: scale(0.95);
            transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 116, 66, 0.7); }
  70% {
    -webkit-transform: scale(1);
            transform: scale(1);
    box-shadow: 0 0 0 10px rgba(255, 116, 66, 0); }
  100% {
    -webkit-transform: scale(0.95);
            transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 116, 66, 0); } }

@keyframes pulse {
  0% {
    -webkit-transform: scale(0.95);
            transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 116, 66, 0.7); }
  70% {
    -webkit-transform: scale(1);
            transform: scale(1);
    box-shadow: 0 0 0 10px rgba(255, 116, 66, 0); }
  100% {
    -webkit-transform: scale(0.95);
            transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 116, 66, 0); } }

.modal {
  z-index: 3000; }

@media (max-width: 575px) {
  .api-information .main-column-content-inner {
    padding-left: 20px !important;
    padding-right: 20px !important; } }

@media (max-width: 500px) {
  .api-information .border.p-3.mb-2 {
    border: none !important;
    padding: 0px !important; } }

.country-picker .main-column-content {
  background: #161a2f; }
  .country-picker .main-column-content .country-picker-wrap {
    margin-top: 10px;
    width: 75%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column; }

.page-content-wrap.has-logo.width-75 {
  margin-top: 60px; }

.page-back svg {
  font-size: 12px; }

.page-logo {
  width: 140px;
  position: absolute;
  top: 35px; }
  .page-logo img {
    max-width: 100%; }

.page-banner-image {
  position: relative; }
  .page-banner-image img {
    max-width: 100%; }
  .page-banner-image .page-banner-caption {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 10px 15px;
    margin: 20px;
    min-width: 350px;
    max-width: 350px; }
    .page-banner-image .page-banner-caption h4 {
      margin-bottom: 0px; }

.page-children .row {
  -webkit-justify-content: center;
          justify-content: center; }

.page-children .page-child-section {
  margin-bottom: 15px; }
  .page-children .page-child-section img {
    object-fit: cover;
    width: 100%;
    height: 150px;
    margin-bottom: 10px; }
  .page-children .page-child-section a {
    color: black; }
    .page-children .page-child-section a h3 {
      margin-bottom: 5px; }
    .page-children .page-child-section a:hover {
      color: #ff7442;
      text-decoration: none; }

.page-accordion {
  padding: 20px 0px; }
  .page-accordion .card-header p {
    margin-bottom: 0px; }

.test123 {
  position: absolute;
  right: 100;
  top: 0; }

.resource-item__overlay {
  position: absolute;
  top: 40%;
  right: 10%;
  -webkit-transform: translate(0%, -60%);
          transform: translate(0%, -60%);
  pointer-events: none;
  /* Prevents interaction */ }

.overlay-lock-icon {
  color: gray;
  font-size: 8rem;
  /* Adjust size as needed */
  opacity: 0.3;
  /* Slight transparency for subtle effect */ }

.resource-item__locked {
  width: 100%;
  position: absolute;
  left: 10px;
  bottom: 5px;
  z-index: 99; }
  .resource-item__locked .resource-item__locked-inner {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center; }
    .resource-item__locked .resource-item__locked-inner .resource-item__locked-icon {
      background: #ff7442;
      border-radius: 50%;
      width: 30px;
      height: 30px;
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center;
      -webkit-justify-content: center;
              justify-content: center;
      margin-right: 10px; }
  .resource-item__locked .resource-item__locked-text {
    color: white;
    font-weight: bold; }

.fav-resource-item__locked {
  width: 100%; }
  .fav-resource-item__locked .resource-item__locked-inner {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center; }
    .fav-resource-item__locked .resource-item__locked-inner .resource-item__locked-icon {
      background: #ff7442;
      border-radius: 50%;
      width: 30px;
      height: 30px;
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center;
      -webkit-justify-content: center;
              justify-content: center;
      margin-right: 10px; }
  .fav-resource-item__locked .resource-item__locked-text {
    color: black;
    font-weight: bold; }

.resource-fav-container {
  margin-bottom: 10px; }

.title-page {
  position: relative;
  width: 100%; }
  .courses-title-page .title-page {
    padding-left: 1.5rem !important;
    margin-top: 0px;
    padding-top: 140px !important; }
    @media (max-width: 1380px) {
      .courses-title-page .title-page {
        padding-top: 250px !important; } }
    @media (max-width: 1200px) {
      .courses-title-page .title-page {
        padding-top: 390px !important; }
        .courses-title-page .title-page .title-page-logo {
          display: none; } }
  @media (max-height: 450px) {
    .title-page {
      margin-top: 60px; } }
  @media (max-width: 768px) {
    .title-page {
      padding-left: 1.5rem !important;
      margin-top: 160px; } }
  @media (max-width: 576px) {
    .title-page {
      margin-top: 0px; } }
  .title-page .main-title {
    font-size: 6vw;
    letter-spacing: -0.32px;
    background: linear-gradient(to right, #f74e50, #ff7442);
    display: inline;
    -webkit-background-clip: text;
            background-clip: text;
    text-fill-color: transparent;
    -webkit-text-fill-color: transparent;
    font-weight: 700;
    padding-left: 3rem; }
    .courses-title-page .title-page .main-title {
      font-size: 45px; }
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      .title-page .main-title {
        background: none;
        color: #ff7442; } }
    @media (max-width: 1380px) {
      .title-page .main-title {
        padding-left: 0px; } }
    @media (max-width: 1200px) {
      .title-page .main-title {
        font-size: 5vw; } }
    @media (max-width: 768px) {
      .title-page .main-title {
        font-size: 60px; } }
    @media (max-width: 576px) {
      .title-page .main-title {
        font-size: 45px; } }
  .title-page .title-page-logo {
    height: 74px;
    width: auto;
    opacity: 1;
    max-width: 100%; }
    .courses-title-page .title-page .title-page-logo {
      height: 74px; }
    @media (max-width: 1200px) {
      .title-page .title-page-logo {
        height: 55px; } }
    @media (max-width: 768px) {
      .title-page .title-page-logo {
        height: 74px; } }
  .title-page .title-content {
    padding-left: 3rem; }
    .courses-title-page .title-page .title-content {
      padding-left: 0px !important;
      padding-top: 20px; }
    @media (max-width: 1380px) {
      .title-page .title-content {
        padding-left: 0px; } }
    @media (max-width: 1200px) {
      .title-page .title-content {
        padding-top: 20px; } }
    @media (max-width: 768px) {
      .title-page .title-content {
        padding-left: 0px !important; } }
  .title-page .lead {
    font-size: 1.5vw;
    font-family: acumin-pro-semi-condensed, sans-serif;
    font-weight: 400;
    font-style: normal;
    padding-right: 7rem; }
    .courses-title-page .title-page .lead {
      font-size: 17px;
      padding-right: 0px; }
    @media (max-width: 1380px) {
      .title-page .lead {
        font-size: 17px;
        padding-right: 0px; } }
  .title-page .button-wrap {
    padding-left: 3rem; }
    .courses-title-page .title-page .button-wrap {
      padding-left: 0px !important; }
    @media (max-width: 1380px) {
      .title-page .button-wrap {
        padding-left: 0px; } }
    @media (max-width: 768px) {
      .title-page .button-wrap {
        padding-left: 0px !important; } }
  .title-page .blue-frontier {
    margin-top: 10px;
    padding-left: 3rem; }
    .courses-title-page .title-page .blue-frontier {
      padding-left: 0px !important; }
    @media (max-width: 1380px) {
      .title-page .blue-frontier {
        padding-left: 0px; } }
    @media (max-width: 768px) {
      .title-page .blue-frontier {
        padding-left: 0px !important; } }
  .title-page .btn {
    font-size: 24px;
    letter-spacing: 0,32px;
    font-family: acumin-pro-semi-condensed, sans-serif;
    font-weight: 400;
    font-style: normal;
    padding: 24px; }
    .courses-title-page .title-page .btn {
      font-size: 16px;
      padding: 12px; }
      .courses-title-page .title-page .btn svg {
        width: 8px; }
    @media (max-width: 1380px) {
      .title-page .btn {
        font-size: 18px; } }
    @media (max-width: 1200px) {
      .title-page .btn {
        padding: 12px; } }
    @media (max-width: 992px) {
      .title-page .btn {
        font-size: 16px; }
        .title-page .btn svg {
          display: none; } }
    @media (max-width: 768px) {
      .title-page .btn svg {
        width: 8px;
        display: block; } }
  .title-page .home-banner-wrap {
    min-height: 150px; }
    .courses-title-page .title-page .home-banner-wrap {
      min-height: 75px; }
    @media (max-width: 1380px) {
      .title-page .home-banner-wrap {
        min-height: 120px; } }
    @media (max-width: 1200px) {
      .title-page .home-banner-wrap {
        min-height: 100px; } }
    @media (max-width: 992px) {
      .title-page .home-banner-wrap {
        min-height: 75px; } }
  .title-page .home-banner {
    padding-left: 3rem;
    margin-top: 20px; }
    .courses-title-page .title-page .home-banner {
      padding-left: 0px; }
    @media (max-width: 1380px) {
      .title-page .home-banner {
        padding-left: 0px; } }
    @media (max-width: 768px) {
      .title-page .home-banner {
        padding-left: 0px; } }
    .title-page .home-banner a {
      display: block; }
    .title-page .home-banner img {
      width: 100%; }
  .title-page .continent-select .dropdown-menu.show {
    width: 100%; }

.title-page-column.courses-title-page .main-column-content {
  overflow-y: auto !important;
  margin-top: 65px;
  padding-bottom: 65px; }

@media (max-width: 768px) {
  .title-page-column .main-column-content {
    overflow-y: auto !important;
    margin-top: 65px;
    padding-bottom: 65px; } }

.inner-title-page {
  background: #161a2f; }

.profile-header {
  border-top-left-radius: 0.625rem;
  position: relative;
  width: 100%;
  z-index: 1030;
  padding: 1.5rem; }
  @media (max-width: 575px) {
    .profile-header {
      padding: 0.5rem; } }

.profile-title {
  color: #fff;
  font-weight: 700;
  text-indent: 1rem;
  text-transform: uppercase;
  letter-spacing: 0.02em;
  margin-bottom: 0; }
  @media (max-width: 768px) {
    .profile-title {
      margin-left: 90px;
      margin-bottom: 3px; } }

.close-link {
  background: none;
  border: 0;
  color: #fff;
  font-size: .875rem;
  font-weight: 700;
  letter-spacing: 0.08em;
  text-transform: uppercase; }
  .close-link:hover {
    background: none;
    border: 0;
    color: #fff;
    text-decoration: none; }

.profile-tabs {
  position: relative;
  width: 100%;
  z-index: 1030;
  background-color: #fff;
  border-bottom: 1px solid #ebebeb; }
  .profile-tabs .nav-link {
    color: #000;
    font-size: 0.875rem;
    font-weight: 600;
    letter-spacing: 0.08em;
    text-align: center;
    text-transform: uppercase; }
    @media (max-width: 575px) {
      .profile-tabs .nav-link {
        padding: 10px; } }
    .profile-tabs .nav-link.active {
      border-bottom: 2px solid #ff7442; }
  .profile-tabs .search-input-save {
    bottom: -3rem; }

@media (max-width: 575px) {
  #profile-tabs-tabpane-resources .search-input,
  #profile-tabs-tabpane-data .search-input {
    padding: 2px 0 4px 3rem;
    height: auto;
    height: initial; }
  #profile-tabs-tabpane-resources .search-input-wrapper .search-icon,
  #profile-tabs-tabpane-data .search-input-wrapper .search-icon {
    top: 9px;
    font-size: 1rem; } }

.tab-content {
  color: #161a2f;
  margin: 0;
  padding: 0; }
  .tab-content #profile-tabs-tabpane-profile .col {
    max-width: calc(100% - 100px); }

.tab-pane {
  font-family: "acumin-pro-semi-condensed", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  height: calc(100vh - 153px);
  overflow-y: scroll;
  margin: 0; }
  @media (max-width: 767px) {
    .tab-pane {
      height: auto;
      height: initial;
      overflow-y: auto; } }

.profile-sidebar {
  border-bottom: 1px solid #ebebeb;
  border-right: 1px solid #ebebeb;
  display: block;
  position: relative;
  width: 100px; }
  @media (max-width: 575px) {
    .profile-sidebar {
      width: 60px; } }

.sideways-text {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  color: #ff7442;
  display: block;
  font-family: "acumin-pro-condensed", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: .875rem;
  font-weight: 700;
  letter-spacing: 0.06em;
  text-align: center;
  text-transform: uppercase;
  -webkit-transform: rotate(-90deg) translate(-50%, -50%) translate3d(0, 0, 0) perspective(1px) scale(1.1);
          transform: rotate(-90deg) translate(-50%, -50%) translate3d(0, 0, 0) perspective(1px) scale(1.1);
  -webkit-transform-origin: 0 0;
          transform-origin: 0 0; }

.profile-inner {
  border-bottom: 1px solid #ebebeb;
  padding: 3rem; }
  .profile-inner .profile-content-wrap {
    height: 150px;
    overflow: hidden;
    margin-bottom: 20px; }
    .profile-inner .profile-content-wrap.read-more {
      height: 100%; }
  .profile-inner .profile-read-more {
    cursor: pointer;
    color: #ff7442; }

.info-list .list-inline-item {
  background-color: transparent;
  border: 1px solid #ebebeb;
  border-radius: 2px;
  font-family: "acumin-pro-condensed", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: .9375rem;
  font-weight: 500;
  letter-spacing: 0;
  margin-bottom: 0.5rem;
  transition: background-color 100ms cubic-bezier(0, 0, 0.2, 1); }

.orange-item {
  cursor: pointer; }
  .orange-item a {
    color: #161a2f;
    display: block;
    padding-bottom: 0.5rem;
    padding-left: 0.75rem;
    padding-right: 1rem;
    padding-top: 0.5rem; }
  .orange-item svg {
    color: #ff7442; }
  .orange-item:hover {
    background-color: #ff7442;
    color: #fff; }
    .orange-item:hover a {
      color: #fff;
      text-decoration: none; }
    .orange-item:hover svg {
      color: #fff; }

.blue-item {
  cursor: pointer; }
  .blue-item svg {
    color: #00b0ff; }
  .blue-item:hover {
    background-color: #00b0ff;
    color: #fff; }
    .blue-item:hover svg {
      color: #fff; }

.green-item {
  cursor: pointer; }
  .green-item svg {
    color: #00cf69; }
  .green-item:hover {
    background-color: #00cf69;
    color: #fff; }
    .green-item:hover svg {
      color: #fff; }

.resource {
  font-size: 1.125rem;
  border: 1px solid #b1b9cb;
  border-radius: .25rem;
  font-family: "acumin-pro-condensed", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 500;
  letter-spacing: 0.02em; }

.profile-page-wrap .loading-loader {
  display: none; }

.profile-page-wrap.loading {
  position: relative; }
  .profile-page-wrap.loading .loading-loader {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    background: white;
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 1; }

.profile-user-content {
  display: -webkit-flex;
  display: flex; }
  @media (max-width: 575px) {
    .profile-user-content {
      display: block; } }
  .profile-user-content .profile-user-content__profile-picture {
    margin-right: 2rem; }
    .profile-user-content .profile-user-content__profile-picture .profile-user-content__image {
      border: 1px solid #ff7442;
      border-radius: 5px;
      width: 120px;
      height: 120px;
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center;
      -webkit-justify-content: center;
              justify-content: center; }
      .profile-user-content .profile-user-content__profile-picture .profile-user-content__image .profile-picture {
        background-size: cover;
        width: 100%;
        height: 100%; }
      .profile-user-content .profile-user-content__profile-picture .profile-user-content__image svg {
        font-size: 80px; }
    .profile-user-content .profile-user-content__profile-picture .profile-user-content__social a {
      margin-left: 4px;
      color: #387998;
      text-decoration: underline; }
      .profile-user-content .profile-user-content__profile-picture .profile-user-content__social a:hover {
        color: #ff7442; }
    .profile-user-content .profile-user-content__profile-picture .profile-user-content__social .user-social__researchgate svg {
      color: #40ba9b; }
    .profile-user-content .profile-user-content__profile-picture .profile-user-content__social .user-social__twitter svg {
      color: #1da1f2; }
    .profile-user-content .profile-user-content__profile-picture .profile-user-content__social .user-social__linkedin svg {
      color: #2977c9; }
    .profile-user-content .profile-user-content__profile-picture .profile-user-content__social .user-social__orcid svg {
      color: #a6ce39; }
    .profile-user-content .profile-user-content__profile-picture .profile-user-content__message {
      margin-top: -30px; }

.column-profile #profile-tabs-tabpane-resources .search-input-save {
  bottom: -2.75rem; }

.column-profile #profile-tabs-tabpane-resources .search-input-reset {
  bottom: -4rem; }

.column-profile .profile-title {
  margin-left: 0px; }

@media (max-width: 500px) {
  .column-profile .category-criteria .resource-category-selector .dropdown-menu.show a {
    white-space: normal;
    white-space: initial; } }

@media (max-width: 400px) {
  .column-profile .category-criteria {
    -webkit-flex-direction: column;
            flex-direction: column; }
    .column-profile .category-criteria .resource-category-selector {
      -webkit-justify-content: initial;
              justify-content: initial; }
      .column-profile .category-criteria .resource-category-selector .dropdown-menu.show a {
        white-space: normal;
        white-space: initial; } }

.message-modal h3 {
  color: #ff7442; }

.data-wrap .search-input-wrapper {
  margin-bottom: 50px; }
  .data-wrap .search-input-wrapper .search-input-save {
    bottom: -2.75rem; }
  .data-wrap .search-input-wrapper .search-input-reset {
    bottom: -4rem; }

.heatmap-btn {
  width: 150px; }

.data-results {
  display: -webkit-flex;
  display: flex; }
  .data-results .data-functions-wrap {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column; }
  .data-results .map-wrap {
    display: -webkit-flex;
    display: flex;
    margin-top: 20px; }
  @media (max-width: 1380px) {
    .data-results {
      -webkit-flex-direction: column;
              flex-direction: column; }
      .data-results h4 {
        margin-bottom: 30px; } }

.data-common-searches {
  margin-top: 80px; }

.data-index-view {
  display: none;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  background: white;
  padding: 80px; }
  .data-index-view h3 {
    color: #ff7442; }
  @media (max-width: 991px) {
    .data-index-view {
      padding: 20px;
      margin-top: 150px; } }
  @media (max-width: 575px) {
    .data-index-view {
      margin-top: 90px; } }

.mobile-filter-close {
  color: #ff7442;
  cursor: pointer;
  display: none; }
  @media (max-width: 991px) {
    .mobile-filter-close {
      display: block; } }
  .mobile-filter-close:hover {
    color: black; }

.mobile-map-close {
  color: #ff7442;
  cursor: pointer;
  display: none;
  position: absolute;
  top: 160px;
  left: 10px;
  z-index: 20000;
  background: white;
  padding: 5px 10px;
  border-radius: 5px; }
  @media (max-width: 991px) {
    .mobile-map-close {
      display: block; } }
  @media (max-width: 767px) {
    .mobile-map-close {
      top: 90px; } }
  .mobile-map-close:hover {
    color: black; }

.data-index-full-width .column-profile .data-index-view {
  width: 50%; }
  @media (max-width: 991px) {
    .data-index-full-width .column-profile .data-index-view {
      width: 100%;
      z-index: 1; } }

.data-index-full-width .data-index-view {
  width: 67%;
  display: block;
  overflow-y: auto; }
  .data-index-full-width .data-index-view::after {
    content: '';
    width: 1px;
    height: 80%;
    background: #ebebeb;
    display: block;
    position: absolute;
    right: 0;
    top: 10%; }
  .data-index-full-width .data-index-view .data-index-profile-type.data-index-open .data-index-profile-wrap {
    display: block !important; }
  .data-index-full-width .data-index-view .data-index-profile-type h4 {
    background: #ebebeb;
    padding: 5px 10px;
    cursor: pointer;
    transition: all .3s; }
    .data-index-full-width .data-index-view .data-index-profile-type h4:hover {
      opacity: 0.8; }
  .data-index-full-width .data-index-view .data-index-profile-type .data-index-profile-wrap {
    margin-bottom: 20px;
    display: none; }
  .data-index-full-width .data-index-view .data-index-profile-type .data-index-profile {
    margin-left: 20px;
    font-size: 18px;
    cursor: pointer;
    color: #ff7442;
    transition: all .3s; }
    .data-index-full-width .data-index-view .data-index-profile-type .data-index-profile.is-criteria {
      cursor: auto;
      cursor: initial;
      color: #ebebeb; }
    .data-index-full-width .data-index-view .data-index-profile-type .data-index-profile:hover {
      opacity: 0.8; }

.data-full-width .data-heatmap {
  width: 67%;
  height: 100%;
  display: block; }
  @media (max-width: 991px) {
    .data-full-width .data-heatmap {
      width: 50%; } }
  @media (max-width: 767px) {
    .data-full-width .data-heatmap {
      width: 100%; } }
  .data-full-width .data-heatmap::after {
    content: "";
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display: block;
    background: white;
    z-index: 1000;
    opacity: 0;
    transition: opacity 100ms ease-in; }

.data-full-width .data-search {
  padding-top: 54px;
  width: 33%;
  padding-left: 58px; }
  @media (max-width: 991px) {
    .data-full-width .data-search {
      width: 50%;
      padding-left: 30px; } }
  @media (max-width: 767px) {
    .data-full-width .data-search {
      width: 100%;
      z-index: 1;
      background: white;
      padding: 10px;
      position: fixed;
      width: 100%;
      top: 0;
      left: 0; } }

@media (max-width: 767px) {
  .data-full-width .data-results {
    display: none; }
    .data-full-width .data-results h4 {
      margin: 0px !important; } }

.data-full-width .column-profile .data-heatmap {
  width: 50%;
  padding-right: 0px; }
  @media (max-width: 990px) {
    .data-full-width .column-profile .data-heatmap {
      z-index: 1;
      width: 100%; } }

.data-full-width .column-profile .data-search {
  width: 100%;
  padding-left: 0px;
  padding-top: 0px; }

.data-full-width .column-profile .main-column-content-inner {
  padding: 0px; }

.data-full-width .column-profile .data-content-wrap {
  display: -webkit-flex;
  display: flex; }

.data-full-width .column-data {
  width: 100% !important; }
  .data-full-width .column-data .data-content-wrap {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-end;
            justify-content: flex-end; }
  .data-full-width .column-data .main-column-content {
    width: 100% !important; }
  .data-full-width .column-data .main-column-content-inner {
    padding: 0px; }
  .data-full-width .column-data .sidebar-page-title {
    display: none; }
    .data-full-width .column-data .sidebar-page-title.show-heatmap {
      display: block;
      margin-bottom: 35px;
      position: relative;
      left: -8px;
      top: 1px; }

.data-heatmap {
  display: none;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0; }
  .data-heatmap #map {
    width: 100%;
    height: 100%; }
  .data-heatmap.map-loading::after {
    opacity: 0.5;
    position: absolute; }
  .data-heatmap .heatmap-loader {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center; }
    .data-heatmap .heatmap-loader svg {
      z-index: 1001; }

.column-profile .data-search h2.text-center {
  display: none !important; }

.column-profile .data-search h3.show-heatmap {
  display: none !important; }

.data-map-key {
  position: absolute;
  z-index: 1000;
  right: 35px;
  background: white;
  padding: 10px;
  border-radius: 4px;
  width: 200px;
  box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.25);
  bottom: 15px; }
  .data-map-key .marker-key-icon-wrap {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    margin-bottom: 5px;
    cursor: pointer; }
    .data-map-key .marker-key-icon-wrap.disabled {
      color: gray; }
      .data-map-key .marker-key-icon-wrap.disabled .marker-key-icon {
        background: #eeeaea !important;
        border: 2px solid #cac5c5 !important; }
    .data-map-key .marker-key-icon-wrap.no-filter {
      cursor: auto;
      cursor: initial; }
    .data-map-key .marker-key-icon-wrap .marker-key-icon {
      width: 15px;
      height: 15px;
      display: inline-block;
      border-radius: 50%;
      border: 2px solid;
      margin-right: 10px; }

.leaflet-marker-icon.map-marker {
  background: white; }
  .leaflet-marker-icon.map-marker .icon {
    color: #ff7442;
    font-weight: bold;
    padding: 0px 5px;
    border-radius: 5px;
    border: 1px solid #ff7442; }

@media (max-width: 767px) {
  .data-full-width .column-data .sidebar-page-title,
  .data-full-width .column-data h2,
  .data-full-width .column-data .search-input-wrapper,
  .data-full-width .column-data .search-criteria,
  .data-full-width .column-data h4:not(.datasets-link),
  .data-full-width .column-data .common-search-list {
    display: none !important; }
  .data-full-width .column-profile .data-heatmap {
    width: 100%; }
  .data-full-width .column-profile .data-search {
    padding-bottom: 0px; }
  .data-full-width .column-profile .data-results {
    padding: 10px; }
  .data-full-width .column-profile .search-input-wrapper,
  .data-full-width .column-profile .search-criteria,
  .data-full-width .column-profile .profile-header,
  .data-full-width .column-profile h4:not(.datasets-link),
  .data-full-width .column-profile .profile-tabs {
    display: none !important; } }

.sidebar .network-create-account-form {
  margin-left: 3rem;
  margin-right: 3rem; }

.user-profiles .user-profiles-wrap {
  margin-bottom: 20px; }

.user-profiles .user-profile__chip {
  display: -webkit-inline-flex;
  display: inline-flex;
  background: #e0e0e0;
  border-radius: 16px;
  padding: 5px 12px;
  cursor: pointer;
  margin-right: 5px; }
  .user-profiles .user-profile__chip svg {
    width: 10px;
    margin-left: 10px;
    position: relative;
    top: 5px; }

.user-profiles .loading {
  margin-left: 10px; }

.user-profiles .user-profiles__search {
  margin-bottom: 20px; }

.user-profiles__button-wrap button {
  color: white;
  margin-bottom: 20px; }

.user-profiles__button-wrap .delete-btn {
  background: #c50000;
  margin-left: 10px; }

.account-home .account-home__profile {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center; }
  .account-home .account-home__profile .account-home__profile__icon {
    display: -webkit-flex;
    display: flex;
    font-size: 100px;
    margin-right: 20px; }
    .account-home .account-home__profile .account-home__profile__icon .profile-picture {
      background-size: cover;
      width: 150px;
      height: 150px; }
  .account-home .account-home__profile .account-home__profile__info {
    font-weight: 600;
    font-size: 14px; }
    .account-home .account-home__profile .account-home__profile__info div {
      margin-bottom: 10px; }
    .account-home .account-home__profile .account-home__profile__info p {
      margin-bottom: -5px; }
    .account-home .account-home__profile .account-home__profile__info span {
      color: #ff7442;
      font-size: 20px; }

.account-home .account-home__menu {
  margin-top: 40px; }
  .account-home .account-home__menu a, .account-home .account-home__menu .link {
    cursor: pointer;
    font-size: 20px;
    background: #ff7442;
    color: #fff;
    display: block;
    padding: 10px;
    margin-bottom: 20px;
    border-radius: 5px; }
    .account-home .account-home__menu a svg, .account-home .account-home__menu .link svg {
      margin-right: 10px; }
    .account-home .account-home__menu a .notifications-count, .account-home .account-home__menu .link .notifications-count {
      background: white;
      border-radius: 50%;
      color: #ff7442;
      width: 25px;
      height: 25px;
      font-weight: 600;
      margin-left: 10px;
      display: -webkit-inline-flex;
      display: inline-flex;
      -webkit-align-items: center;
              align-items: center;
      -webkit-justify-content: center;
              justify-content: center;
      font-size: 16px; }

.user-profile-form button {
  width: 140px;
  height: 40px; }
  .user-profile-form button svg {
    color: white; }

.data-import-btn {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center; }
  .data-import-btn svg {
    margin-left: 15px; }

.data-upload-form {
  font-size: 16px;
  font-family: "acumin-pro-semi-condensed", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"; }
  .data-upload-form select {
    font-size: 16px; }

.data-upload-form__errors {
  height: 150px;
  overflow-y: scroll; }

.notifications .notifications__header {
  margin-bottom: 15px; }
  .notifications .notifications__header span {
    color: #ff7442;
    font-weight: 600; }

.notifications .notifications__list {
  margin-bottom: 15px; }

.notifications .notification {
  border: 1px solid #b1b9cb;
  border-radius: .25rem;
  padding: 10px;
  padding-bottom: 0px;
  margin-bottom: 14px; }
  .notifications .notification.notification--unread {
    border: 2px solid #ff7442; }
    .notifications .notification.notification--unread .notification__date {
      color: #ff7442; }

.notifications .notification__top {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  padding-bottom: 5px;
  border-bottom: 1px solid #b1b9cb;
  margin-bottom: 15px;
  padding-right: 5px; }

.notifications .notification__actions {
  display: -webkit-flex;
  display: flex; }

.notifications .notification__date {
  font-weight: 600; }

.notifications .notification__read {
  color: #ff7442;
  cursor: pointer;
  margin-right: 10px; }
  .notifications .notification__read svg {
    width: 16px; }

.notifications .notification__message {
  margin-bottom: 10px; }

.notifications .notification__delete {
  color: #bf2323;
  font-size: 20px;
  position: relative;
  top: -4px;
  cursor: pointer; }

.data-upload-form .data-upload-form__instructions {
  margin-bottom: 40px; }
  .data-upload-form .data-upload-form__instructions .btn {
    color: white; }
  .data-upload-form .data-upload-form__instructions div {
    margin-bottom: 40px; }

.data-upload-form #data-description {
  margin-bottom: 40px; }

.code {
  background: lightgray;
  font-family: monospace;
  font-size: 12px;
  word-wrap: break-word;
  padding: 10px; }

.news-item-link,
.news-item-link:hover {
  text-decoration: none; }

.news-page-heading {
  color: #ff7442;
  font-weight: 600; }

.news-page-region {
  font-weight: 500; }

.news-page-text {
  color: rgba(22, 26, 47, 0.85);
  font-family: "acumin-pro-semi-condensed", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  opacity: .85; }

.news-page-link {
  font-family: "acumin-pro-semi-condensed", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"; }

.related-article {
  font-size: 1.125rem;
  border: 1px solid rgba(177, 185, 203, 0.6);
  border-radius: 4px;
  padding: 1rem;
  position: relative; }
  .related-article a:hover {
    text-decoration: none !important; }
    .related-article a:hover h3 {
      text-decoration: underline; }
  .related-article h5 {
    font-size: 1.125rem;
    color: #ff7442;
    font-weight: 600;
    margin-bottom: .25rem; }
  .related-article h6 {
    font-size: 1.125rem;
    color: #161a2f;
    font-weight: 500;
    opacity: .85;
    margin-bottom: 1rem; }
  .related-article a {
    color: #161a2f; }

.news-archive .news-archive__item {
  border: 1px solid rgba(177, 185, 203, 0.6);
  border-radius: 4px;
  padding: 1rem;
  margin-bottom: 1rem; }
  .news-archive .news-archive__item .date {
    text-align: right;
    margin-bottom: 0px; }

.column-resources .main-column-content-inner {
  padding-top: 130px; }

.column-resources .data-index-view {
  width: 50%; }

.column-resources .search-input-save {
  bottom: -4rem; }

.column-resources .search-input-reset {
  bottom: -5.25rem; }

.column-resources .show-resource-tree-btn {
  bottom: -2.75rem; }

.resource-tree-view {
  display: none;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  background: white;
  padding: 80px; }
  .resource-tree-view h3 {
    color: #ff7442; }
  .resource-tree-full-width .resource-tree-view {
    display: block;
    overflow-y: auto;
    width: 50%; }
  .resource-tree-view::after {
    content: '';
    width: 1px;
    height: 80%;
    background: #ebebeb;
    display: block;
    position: absolute;
    right: 0;
    top: 10%; }
  .resource-tree-view .resource-tree-node {
    background: #ebebeb;
    margin-bottom: 10px;
    padding: 5px 10px; }
    .resource-tree-view .resource-tree-node .resource-tree-node-title {
      font-weight: 600;
      font-size: 16px;
      display: inline-block;
      cursor: pointer;
      transition: color .3s; }
      .resource-tree-view .resource-tree-node .resource-tree-node-title:hover {
        color: #ff7442; }
    .resource-tree-view .resource-tree-node .resource-tree-node-icon {
      padding-right: 10px;
      cursor: pointer; }
    .resource-tree-view .resource-tree-node .resource-tree-node-desc {
      color: #ff7442; }
    .resource-tree-view .resource-tree-node .loading-node {
      position: absolute;
      margin-top: 4px;
      margin-left: 10px; }
    .resource-tree-view .resource-tree-node.node-has-children .resource-tree-node-desc {
      padding-left: 22px; }

.resource {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center; }
  .resource a {
    color: inherit;
    text-decoration: none; }
    .resource a:hover {
      color: #ff7442; }
  .resource .resource__user-favourite {
    font-size: 30px;
    position: absolute;
    bottom: 6px;
    right: 6px;
    color: #ededee;
    z-index: 1;
    cursor: pointer;
    padding-left: 10px; }
    .resource .resource__user-favourite.fav {
      color: #ff7442; }
  .resource .resource-category {
    color: #ff7442;
    font-size: 16px;
    font-weight: 600;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    align-items: center;
    -webkit-justify-content: space-between;
            justify-content: space-between; }
  .resource.resource--search {
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: flex-start;
            align-items: flex-start; }
    .column-saved-searches .resource.resource--search span {
      display: block; }
    .column-saved-searches .resource.resource--search a {
      display: -webkit-flex;
      display: flex;
      -webkit-flex-wrap: wrap;
              flex-wrap: wrap; }
    .resource.resource--search a:hover span {
      color: #ff7442; }
    .resource.resource--search span {
      margin-right: 5px;
      background: rgba(177, 185, 203, 0.3);
      color: #240708;
      border-radius: 4px;
      font-family: "acumin-pro-semi-condensed", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
      font-weight: 500;
      letter-spacing: 0.01em;
      font-size: 14px; }
    .resource.resource--search .resource__user-favourite:hover {
      color: #ff7442; }
    .resource.resource--search .resource-search-wrap {
      display: -webkit-flex;
      display: flex;
      width: 100%;
      -webkit-justify-content: space-between;
              justify-content: space-between; }
  .resource .resource-search__timestamp {
    font-size: 14px; }

.resources-search-result {
  margin-top: 20px; }
  .resources-search-result p {
    font-weight: 600;
    font-size: 24px; }

.resource-matrix {
  color: #ff7442;
  cursor: pointer; }

.category-criteria {
  display: -webkit-flex;
  display: flex;
  margin-top: 30px;
  margin-bottom: 15px; }
  @media (max-width: 575px) {
    .category-criteria {
      margin-top: 80px; } }
  .category-criteria h4 {
    margin-right: 5px;
    margin-bottom: 0px; }
  .category-criteria .resource-category-selector {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-end;
            justify-content: flex-end; }
    .category-criteria .resource-category-selector button {
      background: none !important;
      color: #ff7442 !important;
      border: 0px;
      padding: 0px;
      font-size: 16px; }
    .category-criteria .resource-category-selector .dropdown-menu {
      box-shadow: 0px 10px 22px 0px rgba(0, 0, 0, 0.75); }

.sidebar-left,
.sidebar-right {
  color: #161a2f;
  padding-top: 120px;
  z-index: 1030; }

.sidebar-page-title {
  left: 40px;
  position: absolute;
  top: 55px; }

.sidebar-left .sidebar-page-title {
  left: 120px; }

.btn-layered {
  background: none;
  border: 0;
  margin: 0;
  padding: 0; }

.sidemenu {
  color: #161a2f;
  display: -webkit-flex;
  display: flex;
  z-index: 1030; }
  .sidemenu .side-menu-content .btn {
    min-width: 110px; }
  .sidemenu .btn {
    color: white; }

.menu-list {
  counter-reset: a;
  list-style-type: decimal;
  margin-bottom: 6rem;
  margin-left: 40px;
  margin-top: 40px;
  padding: 0; }
  @media (max-width: 768px) and (min-width: 576px) {
    .menu-list {
      margin-top: 60px; } }
  .menu-list li {
    font-size: 2rem;
    display: block;
    float: none;
    letter-spacing: 0.01em;
    list-style: none;
    margin-bottom: 0.5rem;
    position: relative; }
    @media (max-width: 1200px) {
      .menu-list li {
        font-size: calc(1.325rem + 0.9vw) ; } }
    .menu-list li a {
      color: #383838;
      font-weight: 600; }
      .menu-list li a:hover {
        color: #f86029;
        text-decoration: none; }
    .menu-list li:before {
      font-size: 0.875rem;
      box-sizing: border-box;
      content: counter(a, decimal-leading-zero);
      counter-increment: a;
      color: #f86029;
      font-weight: 600;
      letter-spacing: 0.02em;
      margin-right: 8px;
      padding: 4px;
      text-align: center;
      position: absolute;
      left: -2.5rem;
      top: .5rem;
      width: 2rem; }

.social-links-list {
  margin-left: 4.25rem;
  width: 250px; }
  .social-links-list li a {
    font-size: 0.875rem;
    color: #90939a;
    font-weight: 700;
    letter-spacing: 0.08em;
    text-transform: uppercase; }

.form-control::-webkit-input-placeholder {
  font-size: 1.125rem;
  color: #90939a;
  font-family: "acumin-pro-semi-condensed", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 500;
  letter-spacing: 0; }

.form-control:-ms-input-placeholder {
  font-size: 1.125rem;
  color: #90939a;
  font-family: "acumin-pro-semi-condensed", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 500;
  letter-spacing: 0; }

.form-control::placeholder {
  font-size: 1.125rem;
  color: #90939a;
  font-family: "acumin-pro-semi-condensed", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 500;
  letter-spacing: 0; }

.form-control:focus {
  border-color: transparent !important; }

.custom-control-label {
  color: #240708;
  font-family: "acumin-pro-semi-condensed", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  opacity: .85; }

.valid-feedback,
.invalid-feedback {
  font-size: 0.875rem;
  font-family: "acumin-pro-semi-condensed", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: italic; }

label .btn-link {
  border-radius: 0;
  display: inline;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  margin: 0;
  padding: 0;
  text-align: left;
  text-decoration: underline;
  vertical-align: baseline; }
  label .btn-link:hover {
    text-decoration: none; }

.modal-50w {
  width: 50%;
  max-width: none !important; }

.contact-form .form-control,
.network-create-account-form .form-control {
  border-radius: 0;
  border-left: 0;
  border-right: 0;
  border-top: 0; }

.contact-form .input-with-icon,
.network-create-account-form .input-with-icon {
  padding-right: 2rem; }

.contact-form .svg-inline--fa,
.network-create-account-form .svg-inline--fa {
  font-size: 1.375rem;
  color: white; }
  @media (max-width: 1200px) {
    .contact-form .svg-inline--fa,
    .network-create-account-form .svg-inline--fa {
      font-size: calc(1.2625rem + 0.15vw) ; } }

.contact-form label:not(.custom-control-label),
.network-create-account-form label:not(.custom-control-label) {
  font-size: 1rem;
  color: #b1b9cb;
  font-family: "acumin-pro-semi-condensed", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 500;
  letter-spacing: 0.02em; }

.contact-form .custom-control-label,
.network-create-account-form .custom-control-label {
  font-weight: 500;
  letter-spacing: 0; }
  .contact-form .custom-control-label a,
  .network-create-account-form .custom-control-label a {
    color: #ff7442;
    text-decoration: underline; }
    .contact-form .custom-control-label a:hover,
    .network-create-account-form .custom-control-label a:hover {
      text-decoration: none; }

.alert.alert-success a {
  text-decoration: underline;
  color: #006c37; }

#create-account-field-research-units label,
#update-profile-field-research-units label {
  width: 100%; }

.search-input-wrapper {
  position: relative; }
  .column-search .search-input-wrapper {
    margin-bottom: 30px; }
  .search-input-wrapper .search-icon {
    font-size: 1.25rem;
    color: #240708;
    display: inline-block;
    position: absolute;
    left: 1rem;
    top: 35px; }
  .search-input-wrapper .search-input-reset, .search-input-wrapper .search-input-save, .search-input-wrapper .show-data-index-btn, .search-input-wrapper .show-resource-tree-btn {
    cursor: pointer;
    color: #ff7442;
    position: absolute;
    right: 0;
    font-weight: 600; }
    .search-input-wrapper .search-input-reset svg, .search-input-wrapper .search-input-save svg, .search-input-wrapper .show-data-index-btn svg, .search-input-wrapper .show-resource-tree-btn svg {
      width: 12px;
      margin-left: 5px; }

.search-input {
  padding-left: 3rem; }

.search-criteria-list li {
  background: rgba(177, 185, 203, 0.3);
  color: #240708;
  border-radius: 4px;
  font-family: "acumin-pro-semi-condensed", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 500;
  letter-spacing: 0.01em;
  font-size: 14px; }
  .search-criteria-list li .close-icon {
    font-size: 0.875rem;
    color: #808e92;
    cursor: pointer;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none; }
  .search-criteria-list li.vector > span,
  .search-criteria-list li.species > span {
    font-style: italic; }

.common-search-list li {
  font-family: "acumin-pro-semi-condensed", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 500; }
  .common-search-list li em {
    cursor: pointer;
    transition: color 200ms ease-in; }
  .common-search-list li:hover em {
    color: #ff7442; }

.search-autocomplete {
  background: #fff;
  border: 1px solid #b1b9cb;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  padding: 0;
  position: relative;
  top: -3px; }
  .search-autocomplete svg {
    padding: 10px 0px; }

.search-autocomplete-list {
  list-style: none;
  margin: 0;
  padding: 0; }
  .search-autocomplete-list li a,
  .search-autocomplete-list li button {
    font-size: 1.125rem;
    background: #fff;
    border: 0;
    border-radius: 0;
    color: #a9adb2;
    display: block;
    font-family: "acumin-pro-semi-condensed", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-weight: 500;
    margin: 0;
    padding: 1rem;
    text-align: left;
    text-decoration: none; }
    .search-autocomplete-list li a .data-name,
    .search-autocomplete-list li button .data-name {
      display: inline-block;
      margin-bottom: 0;
      margin-left: .5rem;
      margin-top: 0;
      padding: 0; }
      .search-autocomplete-list li a .data-name.vector, .search-autocomplete-list li a .data-name.vectorspecies,
      .search-autocomplete-list li button .data-name.vector,
      .search-autocomplete-list li button .data-name.vectorspecies {
        font-style: italic; }
    .search-autocomplete-list li a:hover,
    .search-autocomplete-list li button:hover {
      background: rgba(188, 190, 194, 0.12);
      color: #a9adb2; }
    .search-autocomplete-list li a span.highlight,
    .search-autocomplete-list li button span.highlight {
      color: #4F3738; }

.search-back-to-top {
  background: #ff7442;
  color: white;
  width: 25px;
  height: 25px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  font-size: 15px;
  border-radius: 5px;
  position: fixed;
  bottom: 10px;
  right: 25px;
  cursor: pointer; }

.datasets-link {
  font-size: 1.25rem;
  font-weight: 600;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  text-decoration: underline; }

.column-search .main-column-content-inner {
  padding: 0px !important; }
  .column-search .main-column-content-inner .global-search-wrapper {
    border-top: 1px solid #ebebeb; }
  .column-search .main-column-content-inner .search-input-wrapper {
    margin-top: 120px;
    padding: 0px 3rem; }
    .column-search .main-column-content-inner .search-input-wrapper .search-icon {
      left: 4rem; }
  .column-search .main-column-content-inner .search-popular, .column-search .main-column-content-inner .search-criteria {
    padding: 0px 3rem; }
  .column-search .main-column-content-inner .search-criteria {
    margin-top: 3rem; }
  .column-search .main-column-content-inner .search-input-reset {
    right: 3rem; }
  .column-search .main-column-content-inner .search-input-save {
    right: 3rem;
    bottom: -3rem; }
  .column-search .main-column-content-inner .info-list .list-inline-item.resource-search-item a {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center; }
    .column-search .main-column-content-inner .info-list .list-inline-item.resource-search-item a svg {
      font-size: 20px;
      color: black; }
  .column-search .main-column-content-inner .info-list .list-inline-item.resource-search-item:hover a svg {
    color: white; }

.four-oh-four {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0; }
  .four-oh-four .main-title {
    font-size: 6.2vw;
    letter-spacing: -0.32px;
    background: linear-gradient(to right, #f74e50, #ff7442);
    display: inline;
    -webkit-background-clip: text;
            background-clip: text;
    text-fill-color: transparent;
    -webkit-text-fill-color: transparent;
    font-weight: 700;
    padding-left: 3rem; }

.resource-matrix-table h3 {
  color: #ff7442;
  margin-bottom: 20px; }

.resource-matrix-table .resource-matrix-table__header {
  font-weight: 600;
  margin-bottom: 0px; }

.resource-matrix-table ul {
  list-style: none;
  padding-left: 15px; }

.resource-matrix-table li {
  color: #ff7442;
  cursor: pointer; }
  .resource-matrix-table li::before {
    content: ' - ';
    position: relative;
    left: -10px; }

.resource-matrix-table .resource-matrix-table__selected {
  color: #ff7442; }
  .resource-matrix-table .resource-matrix-table__selected span {
    color: #90939a;
    cursor: pointer;
    font-size: 0.75em;
    margin-left: 5px; }

.institutions .institutions__list, .institutions .collaborators__list, .collaborators .institutions__list, .collaborators .collaborators__list {
  padding-bottom: 30px; }

.institutions .institutions__delete-btn, .institutions .collaborators__delete-btn, .collaborators .institutions__delete-btn, .collaborators .collaborators__delete-btn {
  margin: 10px 0px;
  font-size: 14px; }

.institutions .institutions__list-item, .institutions .collaborators__list-item, .collaborators .institutions__list-item, .collaborators .collaborators__list-item {
  background-color: #e0e0e0;
  display: -webkit-inline-flex;
  display: inline-flex;
  transition: background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  -webkit-align-items: center;
          align-items: center;
  white-space: nowrap;
  border-radius: 16px;
  -webkit-justify-content: center;
          justify-content: center;
  margin-right: 5px;
  margin-bottom: 5px;
  height: 32px;
  padding: 0px 15px; }
  .institutions .institutions__list-item.to-delete, .institutions .collaborators__list-item.to-delete, .collaborators .institutions__list-item.to-delete, .collaborators .collaborators__list-item.to-delete {
    background: #dc3545;
    color: white; }
    .institutions .institutions__list-item.to-delete svg, .institutions .collaborators__list-item.to-delete svg, .collaborators .institutions__list-item.to-delete svg, .collaborators .collaborators__list-item.to-delete svg {
      color: white; }
  .institutions .institutions__list-item .institutions__list-remove, .institutions .institutions__list-item .collaborators__list-remove, .institutions .collaborators__list-item .institutions__list-remove, .institutions .collaborators__list-item .collaborators__list-remove, .collaborators .institutions__list-item .institutions__list-remove, .collaborators .institutions__list-item .collaborators__list-remove, .collaborators .collaborators__list-item .institutions__list-remove, .collaborators .collaborators__list-item .collaborators__list-remove {
    color: #a6a6a6;
    padding-left: 10px;
    cursor: pointer; }

.institutions .institutions__add, .institutions .collaborators__add, .collaborators .institutions__add, .collaborators .collaborators__add {
  padding-bottom: 40px;
  border-bottom: 1px solid #ced4da;
  margin-bottom: 40px; }
  .institutions .institutions__add .institutions__add-btn, .institutions .institutions__add .collaborators__add-btn, .institutions .collaborators__add .institutions__add-btn, .institutions .collaborators__add .collaborators__add-btn, .collaborators .institutions__add .institutions__add-btn, .collaborators .institutions__add .collaborators__add-btn, .collaborators .collaborators__add .institutions__add-btn, .collaborators .collaborators__add .collaborators__add-btn {
    margin-top: 20px;
    font-size: 14px;
    color: white;
    min-width: 120px;
    min-height: 35px; }
    .institutions .institutions__add .institutions__add-btn svg, .institutions .institutions__add .collaborators__add-btn svg, .institutions .collaborators__add .institutions__add-btn svg, .institutions .collaborators__add .collaborators__add-btn svg, .collaborators .institutions__add .institutions__add-btn svg, .collaborators .institutions__add .collaborators__add-btn svg, .collaborators .collaborators__add .institutions__add-btn svg, .collaborators .collaborators__add .collaborators__add-btn svg {
      color: white; }
  .institutions .institutions__add .select-search .select-search__value input, .institutions .collaborators__add .select-search .select-search__value input, .collaborators .institutions__add .select-search .select-search__value input, .collaborators .collaborators__add .select-search .select-search__value input {
    display: block;
    width: 100%;
    font-size: 1.125rem;
    font-weight: 500;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    padding: 5px; }
  .institutions .institutions__add .select-search .select-search__select, .institutions .collaborators__add .select-search .select-search__select, .collaborators .institutions__add .select-search .select-search__select, .collaborators .collaborators__add .select-search .select-search__select {
    border: 1px solid #ced4da;
    position: relative;
    top: -1px;
    padding: 10px 10px; }
  .institutions .institutions__add .select-search .select-search__options, .institutions .collaborators__add .select-search .select-search__options, .collaborators .institutions__add .select-search .select-search__options, .collaborators .collaborators__add .select-search .select-search__options {
    margin-bottom: 0px;
    list-style: none;
    padding-left: 0px; }
    .institutions .institutions__add .select-search .select-search__options button, .institutions .collaborators__add .select-search .select-search__options button, .collaborators .institutions__add .select-search .select-search__options button, .collaborators .collaborators__add .select-search .select-search__options button {
      background: none;
      border: 0px;
      padding: 5px 0px;
      transition: 150ms ease-in-out; }
      .institutions .institutions__add .select-search .select-search__options button:hover, .institutions .collaborators__add .select-search .select-search__options button:hover, .collaborators .institutions__add .select-search .select-search__options button:hover, .collaborators .collaborators__add .select-search .select-search__options button:hover {
        color: #a6b1bb; }
      .institutions .institutions__add .select-search .select-search__options button.is-selected, .institutions .collaborators__add .select-search .select-search__options button.is-selected, .collaborators .institutions__add .select-search .select-search__options button.is-selected, .collaborators .collaborators__add .select-search .select-search__options button.is-selected {
        color: #ff7442; }

.institutions .institutions__create a, .institutions .collaborators__create a, .collaborators .institutions__create a, .collaborators .collaborators__create a {
  color: white;
  margin-bottom: 40px;
  font-size: 14px; }

.select-search .select-search__value input {
  display: block;
  width: 100%;
  font-size: 1.125rem;
  font-weight: 500;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  padding: 5px; }

.select-search .select-search__select {
  border: 1px solid #ced4da;
  position: relative;
  top: -1px;
  padding: 10px 10px; }

.select-search .select-search__options {
  margin-bottom: 0px;
  list-style: none;
  padding-left: 0px; }
  .select-search .select-search__options button {
    background: none;
    border: 0px;
    padding: 5px 0px;
    transition: 150ms ease-in-out; }
    .select-search .select-search__options button:hover {
      color: #a6b1bb; }
    .select-search .select-search__options button.is-selected {
      color: #ff7442; }

.institutions__create-form .submit-btn, .user-profile-form .submit-btn, .collaborators__create-form .submit-btn {
  color: white;
  min-width: 205px;
  min-height: 41px; }
  .institutions__create-form .submit-btn svg, .user-profile-form .submit-btn svg, .collaborators__create-form .submit-btn svg {
    color: white; }

.institutions__create-form .relationships__header, .user-profile-form .relationships__header, .collaborators__create-form .relationships__header {
  display: -webkit-flex;
  display: flex;
  margin-bottom: 15px; }
  .institutions__create-form .relationships__header .relationships-add, .user-profile-form .relationships__header .relationships-add, .collaborators__create-form .relationships__header .relationships-add {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    position: relative;
    color: white;
    font-size: 12px;
    width: 20px;
    height: 26px;
    border-radius: 50%;
    top: 5px;
    margin-left: 10px; }

.institutions__create-form .relationships__type, .user-profile-form .relationships__type, .collaborators__create-form .relationships__type {
  margin-bottom: 10px; }
  .institutions__create-form .relationships__type .relationships__type-header, .user-profile-form .relationships__type .relationships__type-header, .collaborators__create-form .relationships__type .relationships__type-header {
    font-weight: 600;
    margin-bottom: 5px; }
  .institutions__create-form .relationships__type .relationships__profile, .user-profile-form .relationships__type .relationships__profile, .collaborators__create-form .relationships__type .relationships__profile {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    background: #e0e0e0;
    border-radius: 15px;
    padding: 0px 15px;
    margin-right: 5px;
    margin-bottom: 5px; }
    .institutions__create-form .relationships__type .relationships__profile.to-delete, .user-profile-form .relationships__type .relationships__profile.to-delete, .collaborators__create-form .relationships__type .relationships__profile.to-delete {
      color: #721c24;
      background-color: #f8d7da;
      border-color: #f5c6cb; }
      .institutions__create-form .relationships__type .relationships__profile.to-delete svg, .user-profile-form .relationships__type .relationships__profile.to-delete svg, .collaborators__create-form .relationships__type .relationships__profile.to-delete svg {
        font-size: 13px; }
    .institutions__create-form .relationships__type .relationships__profile .relationshios__profile-delete, .user-profile-form .relationships__type .relationships__profile .relationshios__profile-delete, .collaborators__create-form .relationships__type .relationships__profile .relationshios__profile-delete {
      margin-left: 10px;
      cursor: pointer;
      font-size: 12px; }

.institutions__create-form .relationships__current-profiles, .user-profile-form .relationships__current-profiles, .collaborators__create-form .relationships__current-profiles {
  display: -webkit-flex;
  display: flex;
  margin-bottom: 15px;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap; }
  .institutions__create-form .relationships__current-profiles .relationships__profile, .user-profile-form .relationships__current-profiles .relationships__profile, .collaborators__create-form .relationships__current-profiles .relationships__profile {
    background: #e0e0e0;
    border-radius: 15px;
    padding: 0px 15px;
    margin-right: 5px;
    margin-bottom: 5px; }

.tox-notifications-container {
  display: none; }

.institutions-modal input:focus {
  border: 0px; }

.institutions-modal .select-search__select {
  height: 150px;
  overflow-y: scroll; }

.institutions-modal .select-search__row button {
  text-align: left; }

.institutions-modal .select-search.is-disabled .select-search__value input {
  background: #e0e0e0; }

.institutions-modal .institutions-modal__type .select-search {
  margin-bottom: 25px; }

.institutions-modal .institutions-modal__profiles p {
  margin-bottom: 5px; }

.institutions-modal .institutions-modal__profiles .select-search {
  margin-bottom: 25px; }

.institutions-modal .btn {
  color: white;
  min-height: 41px; }
  .institutions-modal .btn svg {
    color: white; }

@media (max-width: 767px) {
  .courses-page .main-column-content-inner {
    padding-left: 20px !important;
    padding-right: 20px !important; } }

.courses-page .sidebar-right {
  padding-top: 50px; }

.courses-page h2 {
  text-align: left !important; }

.courses-page table.dataTable {
  table-layout: fixed; }
  .courses-page table.dataTable thead {
    background: #ff7442;
    color: white; }
    .courses-page table.dataTable thead th {
      border-bottom: 0px;
      font-size: 18px;
      padding: 10px; }
      .courses-page table.dataTable thead th select {
        max-width: 120px; }
  @media (max-width: 991px) {
    .courses-page table.dataTable th::before {
      content: '';
      display: block;
      width: 150px; }
    .courses-page table.dataTable th:last-of-type::before {
      width: 300px; } }

.courses-page .top #DataTables_Table_0_filter {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  padding-top: 30px;
  z-index: 1000;
  position: relative; }
  .courses-page .top #DataTables_Table_0_filter label {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center; }

.courses-page .bottom {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center; }
  .courses-page .bottom .paging {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column; }
  .courses-page .bottom #DataTables_Table_0_length {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center; }
    .courses-page .bottom #DataTables_Table_0_length label {
      width: 150px;
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center;
      margin-top: 10px; }
      .courses-page .bottom #DataTables_Table_0_length label select {
        margin: 0px 10px; }

.courses-page .dataTables_scrollBody::-webkit-scrollbar {
  display: none; }

.courses-page .dataTables_wrapper .dataTables_paginate .paginate_button {
  padding: 0px; }
  .courses-page .dataTables_wrapper .dataTables_paginate .paginate_button:hover {
    opacity: 0.5;
    background: none;
    border: 1px solid white; }

.courses-page .courses-display-as {
  position: relative;
  top: 55px;
  z-index: 1; }
  @media (max-width: 640px) {
    .courses-page .courses-display-as {
      top: 25px; } }
  .courses-page .courses-display-as .filter-page-header-btn {
    margin-bottom: 0px; }
    .courses-page .courses-display-as .filter-page-header-btn.grey {
      background: silver; }
      .courses-page .courses-display-as .filter-page-header-btn.grey::before {
        background: grey; }

.courses-page .courses-map {
  position: relative;
  z-index: 1;
  height: 500px;
  width: 100%;
  top: 75px; }
  .courses-page .courses-map #map {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%; }

.courses-page .courses-orange-box {
  color: white;
  background: #ff7442;
  font-weight: 100;
  padding: 15px;
  margin-top: 60px; }
  .courses-page .courses-orange-box p {
    margin-bottom: 0px; }

.courses-title-page .button-wrap {
  -webkit-flex-direction: column;
          flex-direction: column; }
  .courses-title-page .button-wrap div {
    width: 100% !important;
    max-width: 100%;
    padding: 5px 15px !important; }

.courses-title-page .continent-select {
  display: block !important; }

.course-modal .modal-header {
  -webkit-justify-content: space-between;
          justify-content: space-between;
  border-bottom: none;
  padding-bottom: 0; }

.course-modal .modal-body {
  max-height: 600px;
  overflow-y: auto; }
  .course-modal .modal-body h4 {
    color: #ff7442; }

.course-modal .courses-data div {
  margin-bottom: 15px; }

.filter-page-menu {
  padding: 30px 10px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  height: 100%;
  width: 85px;
  background: #161a2f; }
  @media (max-width: 575px) {
    .filter-page-menu {
      padding: 30px 5px;
      width: 50px; }
      .filter-page-menu .lang-button button {
        font-size: 13px;
        padding: 5px; } }
  .filter-page-menu .filter-page-menu-top {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column; }
    .filter-page-menu .filter-page-menu-top .user-button-wrap {
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: center;
              justify-content: center; }
    .filter-page-menu .filter-page-menu-top .menu-button-wrap {
      margin-bottom: 30px;
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: center;
              justify-content: center; }
      .filter-page-menu .filter-page-menu-top .menu-button-wrap .menu-button {
        margin-right: 0px !important; }
    .filter-page-menu .filter-page-menu-top .filter-button-wrap {
      display: none; }
      @media (max-width: 1440px) {
        .filter-page-menu .filter-page-menu-top .filter-button-wrap {
          display: -webkit-flex;
          display: flex;
          -webkit-justify-content: center;
                  justify-content: center;
          font-size: 26px;
          margin-top: 30px;
          cursor: pointer; } }

.filter-page-full-width .app-buttons, .filter-page-full-width #app-search-button {
  display: none !important; }

.filter-page-full-width .resources-filter-page, .filter-page-full-width .data-filter-page, .filter-page-full-width .network-filter-page {
  width: 100%;
  height: 100%; }
  .filter-page-full-width .resources-filter-page.is-loading .filter-page-content__content, .filter-page-full-width .data-filter-page.is-loading .filter-page-content__content, .filter-page-full-width .network-filter-page.is-loading .filter-page-content__content {
    opacity: 0.5; }

.filter-page-full-width .filter-page {
  width: 100%;
  height: 100%;
  display: -webkit-flex;
  display: flex; }
  @media (max-width: 1440px) {
    .filter-page-full-width .filter-page.show-filters .filter-page-filters {
      display: block; } }
  .filter-page-full-width .filter-page .react-datepicker__current-month {
    display: none; }
  .filter-page-full-width .filter-page .search-input-save {
    position: static;
    position: initial; }
  .filter-page-full-width .filter-page .filter-page-filters {
    padding: 18px 25px;
    background: #e2e2e2;
    min-width: 500px;
    overflow: auto; }
    @media (max-width: 1440px) {
      .filter-page-full-width .filter-page .filter-page-filters {
        display: none;
        position: absolute;
        z-index: 10010;
        left: 85px;
        height: 100%; } }
    @media (max-width: 575px) {
      .filter-page-full-width .filter-page .filter-page-filters {
        left: 50px;
        min-width: 0;
        width: calc(100% - 50px); } }
    .filter-page-full-width .filter-page .filter-page-filters .search-input-reset {
      display: none; }
    .filter-page-full-width .filter-page .filter-page-filters .search-input-wrapper {
      border-bottom: 1px solid #c5c5c5;
      padding-bottom: 15px;
      margin-bottom: 15px; }
      .filter-page-full-width .filter-page .filter-page-filters .search-input-wrapper .search-icon {
        top: 12px; }
    .filter-page-full-width .filter-page .filter-page-filters .filter-page-date-filters {
      border-bottom: 1px solid #c5c5c5;
      padding-bottom: 15px;
      margin-bottom: 15px; }
      .filter-page-full-width .filter-page .filter-page-filters .filter-page-date-filters .react-datepicker-wrapper {
        display: block; }
      .filter-page-full-width .filter-page .filter-page-filters .filter-page-date-filters .date-filter-submit {
        color: white; }
        .filter-page-full-width .filter-page .filter-page-filters .filter-page-date-filters .date-filter-submit:hover {
          color: black; }
    .filter-page-full-width .filter-page .filter-page-filters .filter-page-profile-types-wrap .filter-page-profile-type {
      background: white;
      padding: 10px;
      border-radius: 5px;
      margin-bottom: 15px; }
      .filter-page-full-width .filter-page .filter-page-filters .filter-page-profile-types-wrap .filter-page-profile-type .filter-page-profile-wrap {
        display: none; }
      .filter-page-full-width .filter-page .filter-page-filters .filter-page-profile-types-wrap .filter-page-profile-type h4 {
        color: #161a2f;
        margin-bottom: 0px;
        cursor: pointer;
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: center;
                align-items: center;
        -webkit-justify-content: space-between;
                justify-content: space-between; }
        .filter-page-full-width .filter-page .filter-page-filters .filter-page-profile-types-wrap .filter-page-profile-type h4 .profile-filter-arrow {
          color: white;
          background: #ff7442;
          padding: 2px 10px;
          border-radius: 5px; }
          .filter-page-full-width .filter-page .filter-page-filters .filter-page-profile-types-wrap .filter-page-profile-type h4 .profile-filter-arrow svg {
            position: relative;
            top: -1px;
            -webkit-transform: rotate(180deg);
                    transform: rotate(180deg); }
        .filter-page-full-width .filter-page .filter-page-filters .filter-page-profile-types-wrap .filter-page-profile-type h4 .selected-count {
          font-weight: 100;
          color: #ff7442;
          margin-right: 10px; }
      .filter-page-full-width .filter-page .filter-page-filters .filter-page-profile-types-wrap .filter-page-profile-type .filter-page-profile {
        color: #161a2f;
        margin-bottom: 5px;
        cursor: pointer;
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: center;
                align-items: center; }
        .filter-page-full-width .filter-page .filter-page-filters .filter-page-profile-types-wrap .filter-page-profile-type .filter-page-profile.is-criteria .filter-page-profile__checkbox {
          background: #ff7442; }
        .filter-page-full-width .filter-page .filter-page-filters .filter-page-profile-types-wrap .filter-page-profile-type .filter-page-profile.is-disabled {
          color: #bdbaba;
          cursor: auto;
          cursor: initial; }
          .filter-page-full-width .filter-page .filter-page-filters .filter-page-profile-types-wrap .filter-page-profile-type .filter-page-profile.is-disabled .filter-page-profile__checkbox {
            border: 1px solid #bdbaba; }
        .filter-page-full-width .filter-page .filter-page-filters .filter-page-profile-types-wrap .filter-page-profile-type .filter-page-profile .filter-page-profile__checkbox {
          width: 15px;
          height: 15px;
          min-width: 15px;
          border: 1px solid black;
          margin-right: 10px; }
      .filter-page-full-width .filter-page .filter-page-filters .filter-page-profile-types-wrap .filter-page-profile-type.filter-page-open h4 {
        padding-bottom: 15px;
        margin-bottom: 15px;
        border-bottom: 1px solid #e2e2e2; }
      .filter-page-full-width .filter-page .filter-page-filters .filter-page-profile-types-wrap .filter-page-profile-type.filter-page-open .profile-filter-arrow {
        background: #bdbaba; }
        .filter-page-full-width .filter-page .filter-page-filters .filter-page-profile-types-wrap .filter-page-profile-type.filter-page-open .profile-filter-arrow svg {
          -webkit-transform: rotate(0deg);
                  transform: rotate(0deg);
          top: -2px; }
      .filter-page-full-width .filter-page .filter-page-filters .filter-page-profile-types-wrap .filter-page-profile-type.filter-page-open .filter-page-profile-wrap {
        display: block;
        max-height: 250px;
        overflow: auto; }
  .filter-page-full-width .filter-page .filter-page-content {
    background: white;
    width: 100%;
    padding: 15px 0px;
    overflow: auto; }
    .filter-page-full-width .filter-page .filter-page-content .filter-page-content__header {
      padding: 0px 25px;
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center;
      -webkit-justify-content: space-between;
              justify-content: space-between;
      -webkit-flex-wrap: wrap;
              flex-wrap: wrap; }
      @media (max-width: 767px) {
        .filter-page-full-width .filter-page .filter-page-content .filter-page-content__header {
          display: block; } }
      .filter-page-full-width .filter-page .filter-page-content .filter-page-content__header .header-btns-wrap {
        display: -webkit-flex;
        display: flex; }
        @media (max-width: 500px) {
          .filter-page-full-width .filter-page .filter-page-content .filter-page-content__header .header-btns-wrap {
            -webkit-flex-direction: column;
                    flex-direction: column;
            margin-bottom: 10px; } }
      @media (max-width: 767px) {
        .filter-page-full-width .filter-page .filter-page-content .filter-page-content__header {
          -webkit-flex-wrap: wrap;
                  flex-wrap: wrap; } }
      .filter-page-full-width .filter-page .filter-page-content .filter-page-content__header .filter-page-content__header-title {
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: center;
                align-items: center;
        margin-bottom: 20px; }
        @media (max-width: 500px) {
          .filter-page-full-width .filter-page .filter-page-content .filter-page-content__header .filter-page-content__header-title {
            margin-bottom: 0px; } }
        .filter-page-full-width .filter-page .filter-page-content .filter-page-content__header .filter-page-content__header-title h1 {
          font-size: 2rem;
          margin-right: 10px; }
        .filter-page-full-width .filter-page .filter-page-content .filter-page-content__header .filter-page-content__header-title svg {
          width: 20px; }
    .filter-page-full-width .filter-page .filter-page-content .filter-page-criteria {
      padding: 25px 15px;
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center;
      border-bottom: 1px solid #e2e2e2; }
      .filter-page-full-width .filter-page .filter-page-content .filter-page-criteria ul {
        margin-bottom: 0px;
        margin-left: 20px; }
      .filter-page-full-width .filter-page .filter-page-content .filter-page-criteria .label {
        font-weight: 600;
        color: #161a2f;
        font-size: 1.2rem;
        min-width: 115px; }
      .filter-page-full-width .filter-page .filter-page-content .filter-page-criteria .clear-search {
        color: #ff7442;
        cursor: pointer;
        font-size: 18px;
        font-weight: bold; }
        .filter-page-full-width .filter-page .filter-page-content .filter-page-criteria .clear-search svg {
          margin-right: 5px; }
        .filter-page-full-width .filter-page .filter-page-content .filter-page-criteria .clear-search:hover {
          color: #161a2f; }
    .filter-page-full-width .filter-page .filter-page-content .filter-page-content__categories {
      background: #161a2f;
      padding-right: 10px; }
      @media (max-width: 767px) {
        .filter-page-full-width .filter-page .filter-page-content .filter-page-content__categories {
          background: none;
          padding: 0px 25px; } }
      .filter-page-full-width .filter-page .filter-page-content .filter-page-content__categories ul {
        display: -webkit-flex;
        display: flex;
        list-style: none;
        padding-left: 0px;
        margin-bottom: 0px; }
        @media (max-width: 767px) {
          .filter-page-full-width .filter-page .filter-page-content .filter-page-content__categories ul {
            -webkit-flex-wrap: wrap;
                    flex-wrap: wrap; } }
      .filter-page-full-width .filter-page .filter-page-content .filter-page-content__categories li {
        text-align: center;
        padding: 10px 15px;
        font-weight: 700;
        color: white;
        cursor: pointer;
        border-right: 1px solid #e2e2e2;
        transition: all .3s; }
        @media (max-width: 767px) {
          .filter-page-full-width .filter-page .filter-page-content .filter-page-content__categories li {
            width: 47%;
            background: #161a2f;
            margin: 5px;
            display: -webkit-flex;
            display: flex;
            -webkit-align-items: center;
                    align-items: center;
            -webkit-justify-content: center;
                    justify-content: center; } }
        @media (max-width: 575px) {
          .filter-page-full-width .filter-page .filter-page-content .filter-page-content__categories li {
            width: 100%; } }
        .filter-page-full-width .filter-page .filter-page-content .filter-page-content__categories li:hover {
          color: #ff7442; }
        .filter-page-full-width .filter-page .filter-page-content .filter-page-content__categories li.active {
          background: #ff7442;
          color: white; }
    .filter-page-full-width .filter-page .filter-page-content .filter-page-content__content {
      padding: 15px 25px;
      color: black;
      height: 100%; }
      .filter-page-full-width .filter-page .filter-page-content .filter-page-content__content h3 {
        font-size: 1.5rem;
        margin-bottom: 30px; }
      .filter-page-full-width .filter-page .filter-page-content .filter-page-content__content .filter-page-resources__overview {
        margin-bottom: 60px; }
        .filter-page-full-width .filter-page .filter-page-content .filter-page-content__content .filter-page-resources__overview .filter-page-resources__overview-list .filter-page-resource-item {
          min-height: 200px; }
        @media (max-width: 1200px) {
          .filter-page-full-width .filter-page .filter-page-content .filter-page-content__content .filter-page-resources__overview .filter-page-resources__overview-list .col-12 {
            margin-bottom: 30px; }
            .filter-page-full-width .filter-page .filter-page-content .filter-page-content__content .filter-page-resources__overview .filter-page-resources__overview-list .col-12:last-of-type {
              display: none; } }
      .filter-page-full-width .filter-page .filter-page-content .filter-page-content__content .resource-filter-page-results.grid-view .filter-page-resource-item {
        min-height: 200px; }
      .filter-page-full-width .filter-page .filter-page-content .filter-page-content__content .resource-filter-page-results.list-view .col-12 {
        width: 100% !important;
        -webkit-flex: 0 0 100%;
                flex: 0 0 100%;
        max-width: 100%;
        margin-bottom: 15px; }
      .filter-page-full-width .filter-page .filter-page-content .filter-page-content__content .resource-filter-page-results.list-view .filter-page-resource-item {
        padding: 5px;
        box-shadow: none;
        border: 1px solid #e2e2e2; }
        .filter-page-full-width .filter-page .filter-page-content .filter-page-content__content .resource-filter-page-results.list-view .filter-page-resource-item::after {
          content: none; }
        .filter-page-full-width .filter-page .filter-page-content .filter-page-content__content .resource-filter-page-results.list-view .filter-page-resource-item .resource-item__category,
        .filter-page-full-width .filter-page .filter-page-content .filter-page-content__content .resource-filter-page-results.list-view .filter-page-resource-item .resource-item__image {
          display: none; }
        .filter-page-full-width .filter-page .filter-page-content .filter-page-content__content .resource-filter-page-results.list-view .filter-page-resource-item .resource-item__details {
          -webkit-flex-direction: initial;
                  flex-direction: initial;
          -webkit-align-items: center;
                  align-items: center; }
        .filter-page-full-width .filter-page .filter-page-content .filter-page-content__content .resource-filter-page-results.list-view .filter-page-resource-item .resource-item__title {
          color: #161a2f;
          font-weight: 100; }
        .filter-page-full-width .filter-page .filter-page-content .filter-page-content__content .resource-filter-page-results.list-view .filter-page-resource-item .resource-item__buttons {
          margin-top: 0px; }
        .filter-page-full-width .filter-page .filter-page-content .filter-page-content__content .resource-filter-page-results.list-view .filter-page-resource-item .resource-item__list-icon {
          display: block;
          margin-right: 15px; }
        .filter-page-full-width .filter-page .filter-page-content .filter-page-content__content .resource-filter-page-results.list-view .filter-page-resource-item .resource-item__text {
          display: -webkit-flex;
          display: flex; }
      .filter-page-full-width .filter-page .filter-page-content .filter-page-content__content .resource-filter-page-results .col-12 {
        margin-bottom: 30px; }
      .filter-page-full-width .filter-page .filter-page-content .filter-page-content__content .filter-page-resource-item {
        padding: 15px;
        box-shadow: 2px 2px 10px 2px rgba(0, 0, 0, 0.4);
        border-radius: 5px;
        display: -webkit-flex;
        display: flex;
        height: 100%;
        position: relative;
        overflow: hidden; }
        .filter-page-full-width .filter-page .filter-page-content .filter-page-content__content .filter-page-resource-item::after {
          content: '';
          background: #161a2f;
          position: absolute;
          bottom: 0px;
          width: 100%;
          left: 0;
          height: 40px;
          z-index: 1; }
        .filter-page-full-width .filter-page .filter-page-content .filter-page-content__content .filter-page-resource-item .resource-item__title {
          color: #ff7442;
          font-weight: 600; }
        .filter-page-full-width .filter-page .filter-page-content .filter-page-content__content .filter-page-resource-item .resource-item__list-icon {
          display: none; }
        .filter-page-full-width .filter-page .filter-page-content .filter-page-content__content .filter-page-resource-item .resource-item__details {
          margin-left: 15px;
          display: -webkit-flex;
          display: flex;
          -webkit-flex-direction: column;
                  flex-direction: column;
          -webkit-justify-content: space-between;
                  justify-content: space-between;
          width: 100%; }
        .filter-page-full-width .filter-page .filter-page-content .filter-page-content__content .filter-page-resource-item .resource-item__category {
          color: #000;
          font-weight: 600; }
        .filter-page-full-width .filter-page .filter-page-content .filter-page-content__content .filter-page-resource-item .resource-item__image {
          max-height: 120px;
          max-width: 90px; }
          .filter-page-full-width .filter-page .filter-page-content .filter-page-content__content .filter-page-resource-item .resource-item__image.has-image {
            overflow: hidden;
            border: 1px solid #e2e2e2;
            box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.4);
            width: 125px; }
          .filter-page-full-width .filter-page .filter-page-content .filter-page-content__content .filter-page-resource-item .resource-item__image img {
            height: 120px; }
        .filter-page-full-width .filter-page .filter-page-content .filter-page-content__content .filter-page-resource-item .resource-item__no-image {
          height: 100%;
          min-height: 120px;
          display: -webkit-flex;
          display: flex;
          -webkit-align-items: center;
                  align-items: center;
          -webkit-justify-content: center;
                  justify-content: center;
          width: 90px;
          background: #ff7442;
          color: white;
          border: 1px solid #e2e2e2;
          box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.4);
          font-size: 44px;
          position: relative;
          z-index: 2; }
        .filter-page-full-width .filter-page .filter-page-content .filter-page-content__content .filter-page-resource-item .resource-item__buttons {
          display: -webkit-flex;
          display: flex;
          -webkit-justify-content: flex-end;
                  justify-content: flex-end;
          margin-top: 10px;
          position: relative;
          z-index: 2; }
          .filter-page-full-width .filter-page .filter-page-content .filter-page-content__content .filter-page-resource-item .resource-item__buttons .resource-item__button {
            background: #ff7442;
            color: white;
            width: 40px;
            height: 40px;
            border-radius: 5px;
            border: 1px solid white;
            display: -webkit-flex;
            display: flex;
            -webkit-align-items: center;
                    align-items: center;
            -webkit-justify-content: center;
                    justify-content: center;
            font-size: 20px;
            margin-left: 5px;
            transition: color .3s;
            cursor: pointer; }
            .filter-page-full-width .filter-page .filter-page-content .filter-page-content__content .filter-page-resource-item .resource-item__buttons .resource-item__button a {
              transition: color .3s;
              color: white; }
            .filter-page-full-width .filter-page .filter-page-content .filter-page-content__content .filter-page-resource-item .resource-item__buttons .resource-item__button:hover, .filter-page-full-width .filter-page .filter-page-content .filter-page-content__content .filter-page-resource-item .resource-item__buttons .resource-item__button:hover a {
              color: #161a2f; }
            .filter-page-full-width .filter-page .filter-page-content .filter-page-content__content .filter-page-resource-item .resource-item__buttons .resource-item__button.button-fav {
              background: #bdbdbd;
              cursor: pointer; }
              .filter-page-full-width .filter-page .filter-page-content .filter-page-content__content .filter-page-resource-item .resource-item__buttons .resource-item__button.button-fav.is-fav {
                background: #ff7442; }
  .filter-page-full-width .filter-page .resource-filter-page-list-header {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 20px; }
    @media (max-width: 991px) {
      .filter-page-full-width .filter-page .resource-filter-page-list-header {
        -webkit-flex-direction: column;
                flex-direction: column;
        -webkit-align-items: initial;
                align-items: initial; } }
    .filter-page-full-width .filter-page .resource-filter-page-list-header .total-count {
      color: #ff7442;
      font-size: 1.2rem; }
    .filter-page-full-width .filter-page .resource-filter-page-list-header .filter-page-header__results {
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: column;
              flex-direction: column; }
      @media (max-width: 991px) {
        .filter-page-full-width .filter-page .resource-filter-page-list-header .filter-page-header__results {
          -webkit-flex-direction: initial;
                  flex-direction: initial;
          -webkit-align-items: center;
                  align-items: center;
          margin-bottom: 10px; }
          .filter-page-full-width .filter-page .resource-filter-page-list-header .filter-page-header__results span {
            margin-left: 10px;
            margin-top: 2px; } }
    .filter-page-full-width .filter-page .resource-filter-page-list-header .filter-page-header__controls {
      display: -webkit-flex;
      display: flex; }
      @media (max-width: 991px) {
        .filter-page-full-width .filter-page .resource-filter-page-list-header .filter-page-header__controls {
          -webkit-flex-wrap: wrap;
                  flex-wrap: wrap; } }
    .filter-page-full-width .filter-page .resource-filter-page-list-header .filter-page-header__view {
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center;
      font-size: 18px;
      font-weight: 100; }
      @media (max-width: 991px) {
        .filter-page-full-width .filter-page .resource-filter-page-list-header .filter-page-header__view {
          min-width: 100%;
          margin-top: 15px; } }
      .filter-page-full-width .filter-page .resource-filter-page-list-header .filter-page-header__view .pipe {
        border-left: 1px solid #e2e2e2;
        height: 28px; }
    .filter-page-full-width .filter-page .resource-filter-page-list-header .filter-page-header__view-btn {
      margin-left: 10px;
      margin-right: 10px;
      color: #e2e2e2;
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center;
      cursor: pointer; }
      .filter-page-full-width .filter-page .resource-filter-page-list-header .filter-page-header__view-btn.active {
        color: #ff7442; }
      .filter-page-full-width .filter-page .resource-filter-page-list-header .filter-page-header__view-btn svg {
        font-size: 28px;
        margin-right: 5px; }
    .filter-page-full-width .filter-page .resource-filter-page-list-header .filter-page-header__sort,
    .filter-page-full-width .filter-page .resource-filter-page-list-header .filter-page-header__sort-dir {
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center;
      font-size: 18px;
      font-weight: 100;
      margin-right: 50px; }
      @media (max-width: 991px) {
        .filter-page-full-width .filter-page .resource-filter-page-list-header .filter-page-header__sort,
        .filter-page-full-width .filter-page .resource-filter-page-list-header .filter-page-header__sort-dir {
          -webkit-flex-direction: column;
                  flex-direction: column;
          -webkit-align-items: initial;
                  align-items: initial; } }
      @media (max-width: 575px) {
        .filter-page-full-width .filter-page .resource-filter-page-list-header .filter-page-header__sort,
        .filter-page-full-width .filter-page .resource-filter-page-list-header .filter-page-header__sort-dir {
          margin-right: 20px;
          margin-bottom: 10px; } }
      .filter-page-full-width .filter-page .resource-filter-page-list-header .filter-page-header__sort span,
      .filter-page-full-width .filter-page .resource-filter-page-list-header .filter-page-header__sort-dir span {
        margin-right: 10px; }
      .filter-page-full-width .filter-page .resource-filter-page-list-header .filter-page-header__sort .dropdown button,
      .filter-page-full-width .filter-page .resource-filter-page-list-header .filter-page-header__sort-dir .dropdown button {
        border: 1px solid #e2e2e2;
        background: 0px;
        font-size: 18px;
        font-weight: 100;
        font-family: acumin-pro-condensed, sans-serif;
        width: 150px;
        text-align: left;
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: center;
                align-items: center;
        -webkit-justify-content: space-between;
                justify-content: space-between; }
        .filter-page-full-width .filter-page .resource-filter-page-list-header .filter-page-header__sort .dropdown button::after,
        .filter-page-full-width .filter-page .resource-filter-page-list-header .filter-page-header__sort-dir .dropdown button::after {
          border: 0px;
          content: none; }
        .filter-page-full-width .filter-page .resource-filter-page-list-header .filter-page-header__sort .dropdown button:hover, .filter-page-full-width .filter-page .resource-filter-page-list-header .filter-page-header__sort .dropdown button:focus,
        .filter-page-full-width .filter-page .resource-filter-page-list-header .filter-page-header__sort-dir .dropdown button:hover,
        .filter-page-full-width .filter-page .resource-filter-page-list-header .filter-page-header__sort-dir .dropdown button:focus {
          color: #161a2f; }
        .filter-page-full-width .filter-page .resource-filter-page-list-header .filter-page-header__sort .dropdown button svg,
        .filter-page-full-width .filter-page .resource-filter-page-list-header .filter-page-header__sort-dir .dropdown button svg {
          border-left: 1px solid #e2e2e2;
          padding-left: 10px;
          height: 25px;
          width: 25px; }
    .filter-page-full-width .filter-page .resource-filter-page-list-header .filter-page-header__sort-dir button {
      width: 100px; }

.filter-page-full-width .resource-tree-index-list {
  margin-bottom: 100px; }
  @media (max-width: 1200px) {
    .filter-page-full-width .resource-tree-index-list .col-12 {
      margin-bottom: 10px; } }
  .filter-page-full-width .resource-tree-index-list .resource-tree-node {
    box-shadow: 2px 2px 10px 2px rgba(0, 0, 0, 0.4);
    border-left: 1px solid #e2e2e2;
    border-radius: 5px;
    overflow: hidden;
    height: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: space-between;
            justify-content: space-between; }
    .filter-page-full-width .resource-tree-index-list .resource-tree-node .resource-tree-node__title {
      background: #ff7442;
      color: white;
      font-weight: 600;
      padding: 5px 10px; }
    .filter-page-full-width .resource-tree-index-list .resource-tree-node .resource-tree-node__desc {
      padding: 5px 10px; }
    .filter-page-full-width .resource-tree-index-list .resource-tree-node .resource-tree-node__link-wrap {
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: flex-end;
              justify-content: flex-end;
      padding: 10px; }
    .filter-page-full-width .resource-tree-index-list .resource-tree-node .resource-tree-node__link {
      background: #ff7442;
      color: white;
      font-weight: 600;
      width: 70px;
      text-align: center;
      border-radius: 5px;
      padding: 3px 5px;
      cursor: pointer;
      transition: all .3s; }
      .filter-page-full-width .resource-tree-index-list .resource-tree-node .resource-tree-node__link:hover {
        color: black; }

.filter-page-full-width .filter-page-content__pre-filters .resource-index-intro {
  color: black;
  padding: 15px;
  border-bottom: 1px solid #e2e2e2; }
  .filter-page-full-width .filter-page-content__pre-filters .resource-index-intro .resource-tree-index-list {
    margin-bottom: 30px; }
    @media (max-width: 1200px) {
      .filter-page-full-width .filter-page-content__pre-filters .resource-index-intro .resource-tree-index-list .col-12 {
        margin-bottom: 30px; } }
  .filter-page-full-width .filter-page-content__pre-filters .resource-index-intro .resource-index-sub-list {
    margin-top: 30px; }

.filter-page-full-width .resources-filter-page .filter-page-content {
  padding-top: 0px; }

.filter-page-full-width .resources-filter-page .filter-page-content__header {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 10000;
  background: white;
  padding-top: 15px !important; }
  @media (max-width: 767px) {
    .filter-page-full-width .resources-filter-page .filter-page-content__header {
      position: static;
      position: initial; } }

.filter-page-full-width .data-filter-page .filter-page-content {
  padding-top: 0px; }

.filter-page-full-width .data-filter-page .filter-page-content__header {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 10000;
  background: white;
  padding-top: 15px !important; }
  @media (max-width: 990px) {
    .filter-page-full-width .data-filter-page .filter-page-content__header {
      position: static;
      position: initial; } }

@media (max-width: 767px) {
  .filter-page-full-width .data-filter-page .filter-page-content .filter-page-content__header {
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: initial;
            align-items: initial; } }

.filter-page-full-width .data-filter-page .filter-page-criteria {
  border-top: 1px solid #e2e2e2; }

.filter-page-full-width .data-filter-page .result-count {
  font-weight: 600;
  text-decoration: underline;
  font-size: 1.5rem; }

.filter-page-full-width .data-filter-page .map-container {
  position: relative; }
  .filter-page-full-width .data-filter-page .map-container.is-locked::after {
    position: absolute;
    z-index: 2;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: white;
    opacity: 0.5;
    content: "";
    display: block; }

.filter-page-full-width .data-filter-page #map {
  width: 100%;
  height: 700px;
  position: relative;
  z-index: 1; }

.filter-page-full-width .data-filter-page .data-header-btns {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap; }
  @media (max-width: 500px) {
    .filter-page-full-width .data-filter-page .data-header-btns {
      display: block; } }
  .filter-page-full-width .data-filter-page .data-header-btns .filter-page-header-btn {
    margin-left: 10px;
    min-width: 115px; }
    @media (max-width: 767px) {
      .filter-page-full-width .data-filter-page .data-header-btns .filter-page-header-btn {
        margin-left: 0px;
        margin-right: 10px;
        margin-bottom: 10px; } }
    @media (max-width: 500px) {
      .filter-page-full-width .data-filter-page .data-header-btns .filter-page-header-btn {
        display: block;
        margin-right: 0px; } }
  .filter-page-full-width .data-filter-page .data-header-btns select {
    height: 38px;
    padding: 0px 5px; }
    @media (max-width: 767px) {
      .filter-page-full-width .data-filter-page .data-header-btns select {
        margin-right: 10px; } }
    @media (max-width: 575px) {
      .filter-page-full-width .data-filter-page .data-header-btns select {
        margin-bottom: 5px; } }
    @media (max-width: 500px) {
      .filter-page-full-width .data-filter-page .data-header-btns select {
        width: 100%; } }

.filter-page-full-width .data-filter-page .data-upload-cta {
  border: 1px solid #161a2f;
  border-radius: 5px;
  max-width: 500px; }
  @media (max-width: 767px) {
    .filter-page-full-width .data-filter-page .data-upload-cta {
      max-width: 100%; } }
  .filter-page-full-width .data-filter-page .data-upload-cta.no-form .data-upload-cta__content {
    padding: 20px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center; }
  .filter-page-full-width .data-filter-page .data-upload-cta .data-upload-cta__header {
    background: #161a2f;
    color: white;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    padding: 8px;
    font-size: 20px;
    font-weight: 600; }
  .filter-page-full-width .data-filter-page .data-upload-cta .data-upload-cta__content {
    padding: 8px; }
    .filter-page-full-width .data-filter-page .data-upload-cta .data-upload-cta__content label {
      color: black;
      font-weight: 600; }
    .filter-page-full-width .data-filter-page .data-upload-cta .data-upload-cta__content select {
      border: 1px solid #ced4da; }
    .filter-page-full-width .data-filter-page .data-upload-cta .data-upload-cta__content .show-form-btn {
      background: #ff7442;
      color: white;
      border-radius: 5px;
      cursor: pointer;
      padding: 5px;
      width: 200px;
      text-align: center; }
      .filter-page-full-width .data-filter-page .data-upload-cta .data-upload-cta__content .show-form-btn:hover {
        color: black; }
    .filter-page-full-width .data-filter-page .data-upload-cta .data-upload-cta__content button[type=submit] {
      font-weight: 100;
      font-size: 16px;
      border-color: white; }
      @media (max-width: 575px) {
        .filter-page-full-width .data-filter-page .data-upload-cta .data-upload-cta__content button[type=submit] {
          font-size: 12px; } }
      .filter-page-full-width .data-filter-page .data-upload-cta .data-upload-cta__content button[type=submit] svg {
        font-size: 16px; }

.filter-page-full-width .network-filter-page .filter-page-filters {
  height: 100%; }
  @media (max-width: 575px) {
    .filter-page-full-width .network-filter-page .filter-page-filters {
      width: 100%; } }

.filter-page-full-width .network-filter-page .filter-page-content {
  padding-top: 0px; }

.filter-page-full-width .network-filter-page .filter-page-content__header {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 10000;
  background: white;
  padding-top: 15px !important; }
  @media (max-width: 990px) {
    .filter-page-full-width .network-filter-page .filter-page-content__header {
      position: static;
      position: initial; } }

.filter-page-full-width .network-filter-page .filter-page-content__header-title {
  margin-bottom: 0px !important; }

.filter-page-full-width .network-filter-page .network-letter-list {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  border-top: 1px solid #ced4da;
  padding-top: 20px;
  margin-bottom: 30px; }
  @media (max-width: 767px) {
    .filter-page-full-width .network-filter-page .network-letter-list {
      -webkit-flex-wrap: wrap;
              flex-wrap: wrap;
      -webkit-justify-content: flex-start;
              justify-content: flex-start; } }
  .filter-page-full-width .network-filter-page .network-letter-list .network-letter-list__letter {
    color: #ff7442;
    background: #e2e2e2;
    text-transform: uppercase;
    width: 30px;
    height: 30px;
    font-weight: 600;
    cursor: pointer;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    margin-right: 5px;
    border-radius: 5px; }
    @media (max-width: 767px) {
      .filter-page-full-width .network-filter-page .network-letter-list .network-letter-list__letter {
        margin-bottom: 5px; } }
    .filter-page-full-width .network-filter-page .network-letter-list .network-letter-list__letter:hover {
      color: white;
      background: #ff7442; }
    .filter-page-full-width .network-filter-page .network-letter-list .network-letter-list__letter.is-active {
      background: #161a2f; }

.filter-page-full-width .network-filter-page .user-detail-list {
  margin-bottom: 30px; }
  .filter-page-full-width .network-filter-page .user-detail-list .user-detail-list__header {
    font-weight: 600;
    font-size: 20px; }
    .filter-page-full-width .network-filter-page .user-detail-list .user-detail-list__header a {
      margin-left: 5px;
      font-weight: 100;
      font-size: 16px; }
  .filter-page-full-width .network-filter-page .user-detail-list .user-detail-list__items {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    margin-top: 10px; }
  .filter-page-full-width .network-filter-page .user-detail-list .user-detail-list__item {
    border-radius: 5px;
    background: #ff7442;
    color: white;
    display: -webkit-flex;
    display: flex;
    margin-bottom: 10px;
    margin-right: 10px; }
    .filter-page-full-width .network-filter-page .user-detail-list .user-detail-list__item .user-detail-list__item-left {
      background: #e2e2e2;
      width: 34px;
      height: 100%;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center;
      -webkit-justify-content: center;
              justify-content: center; }
      .filter-page-full-width .network-filter-page .user-detail-list .user-detail-list__item .user-detail-list__item-left span {
        display: inline-block;
        width: 12px;
        height: 12px;
        background: #ff7442;
        border-radius: 50%; }
    .filter-page-full-width .network-filter-page .user-detail-list .user-detail-list__item .user-detail-list__item-title {
      padding: 5px 10px;
      padding-right: 10px; }
      .filter-page-full-width .network-filter-page .user-detail-list .user-detail-list__item .user-detail-list__item-title a {
        color: white;
        text-decoration: none; }
        .filter-page-full-width .network-filter-page .user-detail-list .user-detail-list__item .user-detail-list__item-title a:hover {
          color: black; }

.filter-page-full-width .network-filter-page .users-list h3 {
  font-size: 20px !important;
  margin-bottom: 10px !important; }

.filter-page-full-width .network-filter-page .users-list a {
  color: white; }
  .filter-page-full-width .network-filter-page .users-list a:hover {
    color: #ff7442; }

.filter-page-full-width .network-filter-page .users-list .filter-page-user-item {
  border-radius: 5px;
  color: white;
  background: #161a2f;
  margin-bottom: 10px;
  height: 40px; }
  .filter-page-full-width .network-filter-page .users-list .filter-page-user-item a {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    height: 100%; }
  .filter-page-full-width .network-filter-page .users-list .filter-page-user-item .filter-page-user-item__image {
    overflow: hidden;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    width: 40px;
    height: 100%; }
    .filter-page-full-width .network-filter-page .users-list .filter-page-user-item .filter-page-user-item__image img {
      height: 40px; }
  .filter-page-full-width .network-filter-page .users-list .filter-page-user-item .filter-page-user-item__name {
    padding-left: 10px; }

.basket-modal h3 {
  color: #ff7442;
  margin-bottom: 30px; }
  @media (max-width: 500px) {
    .basket-modal h3 {
      margin-bottom: 10px; } }

.basket-modal .basket-modal-header {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between; }
  @media (max-width: 500px) {
    .basket-modal .basket-modal-header {
      -webkit-flex-direction: column;
              flex-direction: column;
      margin-bottom: 20px; } }

.basket-modal .basket-item {
  border: 1px solid #e2e2e2;
  border-radius: 5px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  padding: 5px 10px;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  margin-bottom: 10px; }
  .basket-modal .basket-item .basket-item__title {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center; }
    .basket-modal .basket-item .basket-item__title svg {
      color: #ff7442;
      margin-right: 10px; }
  .basket-modal .basket-item .basket-item__remove {
    margin-left: 15px;
    color: white;
    background: #ff7442;
    cursor: pointer;
    border-radius: 5px;
    min-width: 31px;
    min-height: 31px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center; }
    .basket-modal .basket-item .basket-item__remove:hover {
      color: black; }

.basket-modal .modal-header {
  padding: 0px; }

.basket-modal .btn-light {
  border: none;
  background: none; }

.filter-page-header-btn {
  background: #ff7442;
  color: white;
  padding: 7px 10px;
  border-radius: 5px;
  cursor: pointer;
  position: relative;
  margin-bottom: 20px; }
  @media (max-width: 500px) {
    .filter-page-header-btn {
      margin-bottom: 5px;
      margin-left: 0px !important; } }
  .filter-page-header-btn::before {
    content: "";
    display: block;
    position: absolute;
    width: 37px;
    height: 100%;
    background: #cc5024;
    top: 0;
    left: 0;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px; }
  .filter-page-header-btn:hover {
    color: black; }
  .filter-page-header-btn span {
    padding-right: 15px;
    z-index: 1;
    position: relative; }
    .filter-page-header-btn span svg {
      min-width: 20px; }

@media (max-width: 1440px) {
  .column-resources-new .show-filters .filter-page-resources__overview-list .col-12 {
    max-width: 50% !important;
    margin-bottom: 10px;
    -webkit-flex-basis: 50%;
            flex-basis: 50%; } }

@media (min-width: 1440px) and (max-width: 1650px) {
  .column-resources-new .filter-page-resources__overview-list .col-12 {
    max-width: 50% !important;
    margin-bottom: 10px;
    -webkit-flex-basis: 50%;
            flex-basis: 50%; } }

.filter-page__advert {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center; }
  .filter-page__advert img {
    max-width: 430px; }

@media all and (-ms-high-contrast: none) {
  .basket-items .basket-item__remove svg {
    margin-top: 7px; }
  .basket-item .basket-item__title svg {
    min-width: 12px; }
  .filter-page-menu {
    width: 125px; }
  .courses-title-page .main-column-content .title-page .button-wrap.row {
    display: block; } }

.intro-modal .modal-content {
  padding: 20px; }

.intro-modal .intro-modal__header-content {
  display: -webkit-flex;
  display: flex;
  margin-right: 30px; }
  .intro-modal .intro-modal__header-content .intro-modal__logo {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    margin-right: 20px; }
    .intro-modal .intro-modal__header-content .intro-modal__logo img {
      width: 120px; }
    @media (max-width: 576px) {
      .intro-modal .intro-modal__header-content .intro-modal__logo {
        display: none; } }
  .intro-modal .intro-modal__header-content h2 {
    font-size: 50px;
    margin-bottom: 0px; }
  .intro-modal .intro-modal__header-content p {
    font-size: 24px; }

.intro-modal .close-button {
  font-size: 17px; }

