.intro-modal {
    .modal-content {
        padding: 20px;
    }
    
    .intro-modal__header-content {
        display: flex;
        margin-right: 30px;

        .intro-modal__logo  {
            display: flex;
            align-items: center;
            margin-right: 20px;

            img {
                width: 120px;
            }

            @media (max-width: 576px) {
                display: none;
            }
        }

        h2 {
            font-size: 50px;
            margin-bottom: 0px;
        }

        p {
            font-size: 24px;
        }
    }
    
    .close-button {
        font-size: 17px;
    }
}