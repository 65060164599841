.column-resources {
    .main-column-content-inner {
        padding-top: 130px;
    }
    .data-index-view {
        width: 50%;
    }
    .search-input-save {
        bottom: -4rem;
    }
    .search-input-reset{
        bottom: -5.25rem;
    }
    .show-resource-tree-btn {
        bottom: -2.75rem;
    }
}

.resource-tree-view {
    display: none;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    background:white;
    padding: 80px;

    h3 {
        color: $orange;
    }

    .resource-tree-full-width & {
        display: block;
        overflow-y: auto;
        width: 50%;
    }

    &::after {
        content: '';
        width: 1px;
        height: 80%;
        background: $light-grey;
        display: block;
        position: absolute;
        right: 0;
        top: 10%;
    }

    .resource-tree-node {
        background: $light-grey;
        margin-bottom: 10px;
        padding: 5px 10px;

        .resource-tree-node-title {
            font-weight: 600;
            font-size: 16px;
            display: inline-block;
            cursor: pointer;
            transition: color .3s;

            &:hover {
                color: $orange;
            }
        }
        .resource-tree-node-icon {
            padding-right: 10px;
            cursor: pointer;
        }
        .resource-tree-node-desc {
            color: $orange;
        }
        .loading-node {
            position: absolute;
            margin-top: 4px;
            margin-left: 10px;
        }

        &.node-has-children {
            .resource-tree-node-desc {
                padding-left: 22px;
            }
        }
    }
}

.resource {
    display: flex;
    justify-content: space-between;
    align-items: center;
    
    a {
        color: inherit;
        text-decoration: none;

        &:hover {
            color: $primary;
        }
    }
    
    .resource__user-favourite {
        font-size: 30px;
        position: absolute;
        bottom: 6px;
        right: 6px;
        color: #ededee;
        z-index: 1;
        cursor: pointer;
        padding-left: 10px;

        &.fav {
            color: $primary;
        }
    }

    .resource-category {
        color: $primary;
        font-size: 16px;
        font-weight: 600;
        display:flex;
        align-items: center;
        align-items: center;
        justify-content: space-between;
    }

    &.resource--search {
        flex-direction: column;
        align-items: flex-start;

        .column-saved-searches & {
            span {
                display: block;
            }
            
            a {
                display: flex;
                flex-wrap: wrap;
            }
        }

        a:hover {
            span {
                color: $orange;
            }
        }

        span {
            margin-right: 5px;
            background: rgba(#b1b9cb, 0.3);
            color: #240708;
            border-radius: 4px;
            font-family: "acumin-pro-semi-condensed", $font-family-sans-serif;
            font-weight: 500;
            letter-spacing: 0.01em;
            font-size: 14px;
        }

        .resource__user-favourite {
            &:hover {
                color: $orange;
            }
        }

        .resource-search-wrap {
            display: flex;
            width: 100%;
            justify-content: space-between;
        }
    }

    .resource-search__timestamp {
        font-size: 14px;
    }
}

.resources-search-result {
    margin-top: 20px;

    p {
        font-weight: 600;
        font-size: 24px;    
    }
}

.resource-matrix {
    color: $primary;
    cursor: pointer;
}

.category-criteria {
    display: flex;
    margin-top: 30px;
    margin-bottom: 15px;

    @media (max-width: 575px) {
        margin-top: 80px;
    }

    h4 {
        margin-right: 5px;
        margin-bottom: 0px;
    }

    .resource-category-selector {
        display: flex;
        justify-content: flex-end;
    
        button {
            background: none !important;
            color: $primary !important;
            border: 0px;
            padding: 0px;
            font-size: 16px;
        }

        .dropdown-menu {
            -webkit-box-shadow: 0px 10px 22px 0px rgba(0,0,0,0.75);
            -moz-box-shadow: 0px 10px 22px 0px rgba(0,0,0,0.75);
            box-shadow: 0px 10px 22px 0px rgba(0,0,0,0.75);
        }
    }
}
