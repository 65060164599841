.data-wrap .search-input-wrapper {
    margin-bottom: 50px;

    .search-input-save {
        bottom: -2.75rem;
    }

    .search-input-reset{
        bottom: -4rem;
    }
}
.heatmap-btn {
    width: 150px;
}
.data-results {
    display: flex;

    .data-functions-wrap {
        display: flex;
        flex-direction: column;
    }

    .map-wrap {
        display: flex;
        margin-top: 20px;
    }

    @media (max-width: 1380px) {
        flex-direction: column;

        h4 {
            margin-bottom: 30px;
        }
    }
}

.data-common-searches {
    margin-top: 80px;
}

.data-index-view {
    display: none;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    background:white;
    padding: 80px;

    h3 {
        color: $orange;
    }

    @media (max-width: 991px) {
        padding: 20px;
        margin-top: 150px;
    }

    @media (max-width: 575px) {
        margin-top: 90px;
    }
}

.mobile-filter-close {
    color: $orange;
    cursor: pointer;
    display: none;

    @media (max-width: 991px) {
        display: block;
    }

    &:hover {
        color: black;
    }
}

.mobile-map-close {
    color: $orange;
    cursor: pointer;
    display: none;
    position: absolute;
    top: 160px;
    left: 10px;
    z-index: 20000;
    background: white;
    padding: 5px 10px;
    border-radius: 5px;

    @media (max-width: 991px) {
        display: block;
    }

    @media (max-width: 767px) {
        top: 90px;
    }

    &:hover {
        color: black;
    }
}

.data-index-full-width {
    .column-profile {
        .data-index-view {
            width: 50%;

            @media (max-width: 991px) {
                width: 100%;
                z-index: 1;
            }
        }
    }
    .data-index-view {
        width: 67%;
        display: block;
        overflow-y: auto;

        &::after {
            content: '';
            width: 1px;
            height: 80%;
            background: $light-grey;
            display: block;
            position: absolute;
            right: 0;
            top: 10%;
        }

        .data-index-profile-type {
            &.data-index-open {
                .data-index-profile-wrap {
                    display: block !important;
                }
            }

            h4 {
                background: $light-grey;
                padding: 5px 10px;
                cursor: pointer;
                transition: all .3s;

                &:hover {
                    opacity: 0.8;
                }
            }

            .data-index-profile-wrap {
                margin-bottom: 20px;
                display: none;
            }

            .data-index-profile {
                margin-left: 20px;
                font-size: 18px;
                cursor: pointer;
                color: $orange;
                transition: all .3s;

                &.is-criteria {
                    cursor: initial;
                    color: $light-grey;
                }

                &:hover {
                    opacity: 0.8;
                }
            }
        }
    }
}

.data-full-width {
    .data-heatmap {
        width: 67%;
        height: 100%;
        display: block;

        @media (max-width: 991px) {
            width: 50%;
        }

        @media (max-width: 767px) {
            width: 100%;
        }

        &::after{
            content: "";
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
            display: block;
            background: white;
            z-index: 1000;
            opacity: 0;
            transition: opacity 100ms ease-in;
        }
    }
    .data-search {
        padding-top: 54px;
        width: 33%;
        padding-left: 58px;
    
        @media (max-width: 991px) {
            width: 50%;
            padding-left: 30px;
        }
    
        @media (max-width: 767px) {
            width: 100%;
            z-index: 1;
            background:white;
            padding: 10px;
            position: fixed;
            width: 100%;
            top: 0;
            left: 0;
        }
    }

    .data-results {
        @media (max-width: 767px) { 
            display: none;
            
            h4 {
                margin: 0px !important;
            }
        }
    }

    .column-profile {
        .data-heatmap {
            width: 50%;
            padding-right: 0px;

            @media (max-width: 990px) {
                z-index: 1;
                width: 100%;
            }
        }
        .data-search {
            width: 100%;
            padding-left: 0px;
            padding-top: 0px;
        }
        .main-column-content-inner {
            padding: 0px;
        }
        .data-content-wrap {
            display: flex;
        }
    }

    .column-data {
        width: 100% !important;

        .data-content-wrap {
            display: flex;
            justify-content: flex-end;
        }
        .main-column-content {
            width: 100% !important;
        }
        .main-column-content-inner {
            padding: 0px;
        }
        .sidebar-page-title {
            display: none;

            &.show-heatmap {
                display: block;
                margin-bottom: 35px;
                position: relative;
                left: -8px;
                top: 1px;
            }
        }
    }
}

.data-heatmap {
    display: none;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;

    #map {
        width: 100%;
        height: 100%;
    }

    &.map-loading::after {
        opacity: 0.5;
        position: absolute;
    }

    .heatmap-loader {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        
        svg {
            z-index: 1001;
        }
    }
}

.column-profile .data-search {
    h2.text-center {
        display: none !important;
    }

    h3.show-heatmap {
        display: none !important;
    }
}

.data-map-key {
    position: absolute;
    z-index: 1000;
    right: 35px;
    background: white;
    padding: 10px;
    border-radius: 4px;
    width: 200px;
    box-shadow: 0px 0px 4px 1px rgba(0,0,0,0.25);
    bottom: 15px;

    .marker-key-icon-wrap {
        display: flex;
        align-items: center;
        margin-bottom: 5px;
        cursor: pointer;

        &.disabled {
            color: gray;

            .marker-key-icon {
                background:  #eeeaea!important;
                border: 2px solid #cac5c5 !important;
            }
        }

        &.no-filter {
            cursor: initial;
        }

        .marker-key-icon {
            width: 15px;
            height: 15px;
            display: inline-block;
            border-radius: 50%;
            border: 2px solid;
            margin-right: 10px;
        }
    }
}
.leaflet-marker-icon.map-marker {
    background: white;

    .icon {
        color: $orange;
        font-weight: bold;
        padding: 0px 5px;
        border-radius: 5px;
        border: 1px solid $orange;
    }
}
@media (max-width: 767px) {
    .data-full-width .column-data .sidebar-page-title,
    .data-full-width .column-data h2,
    .data-full-width .column-data .search-input-wrapper,
    .data-full-width .column-data .search-criteria,
    .data-full-width .column-data h4:not(.datasets-link),
    .data-full-width .column-data .common-search-list {
        display: none !important;
    }

    .data-full-width .column-profile .data-heatmap {
        width: 100%;
    }
    .data-full-width .column-profile .data-search {
        padding-bottom: 0px;
    }
    .data-full-width .column-profile .data-results {
        padding: 10px;
    }
    .data-full-width .column-profile .search-input-wrapper,
    .data-full-width .column-profile .search-criteria,
    .data-full-width .column-profile .profile-header,
    .data-full-width .column-profile h4:not(.datasets-link),
    .data-full-width .column-profile .profile-tabs {
        display: none !important;
    }
}