.resource-matrix-table {
    h3 {
        color: $primary;
        margin-bottom: 20px;
    }

    .resource-matrix-table__header {
        font-weight: 600;
        margin-bottom: 0px;
    }

    ul {
        list-style: none;
        padding-left: 15px;
    }

    li {
        color: $primary;
        cursor: pointer;

        &::before {
            content: ' - ';
            position: relative;
            left: -10px;
        }
    }

    .resource-matrix-table__selected {
        color: $primary;

        span {
            color: $grey;
            cursor: pointer;
            font-size: 0.75em;
            margin-left: 5px;
        }
    }
}