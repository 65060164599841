.filter-page-menu {
    padding: 30px 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    width: 85px;
    background: #161a2f;

    @media (max-width: 575px) {
        padding: 30px 5px;
        width: 50px;

        .lang-button button {
            font-size: 13px;
            padding: 5px;
        }
    }

    .filter-page-menu-top {
        display: flex;
        flex-direction: column;
        
        .user-button-wrap {
            display: flex;
            justify-content: center;
        }
        .menu-button-wrap {
            margin-bottom: 30px;
            display: flex;
            justify-content: center;

            .menu-button {
                margin-right: 0px !important;
            }
        }
        .filter-button-wrap {
            display: none;

            @media(max-width: 1440px) {
                display: flex;
                justify-content: center;
                font-size: 26px;
                margin-top: 30px;
                cursor: pointer;
            }
        }
    }
}

.filter-page-full-width {
    .app-buttons, #app-search-button {
        display: none !important;
    }
    .resources-filter-page, .data-filter-page, .network-filter-page {
        width: 100%;
        height: 100%;

        &.is-loading {
            .filter-page-content__content {
                opacity: 0.5;
            }
        }
    }
    .filter-page {
        width: 100%;
        height: 100%;
        display: flex;

        @media(max-width: 1440px) {
            &.show-filters {
                .filter-page-filters {
                    display: block;
                }
            }
        }

        .react-datepicker__current-month {
            display: none;
        }

        .search-input-save {
            position: initial;
        }

        .filter-page-filters {
            padding: 18px 25px; 
            background: #e2e2e2;
            min-width: 500px;
            overflow: auto;

            @media(max-width: 1440px) {
                display: none;
                position: absolute;
                z-index: 10010;
                left: 85px;
                height: 100%;
            }
            
            @media (max-width: 575px) {
                left: 50px;
                min-width: 0;
                width: calc(100% - 50px);
            }

            .search-input-reset {
                display: none;
            }
            .search-input-wrapper {
                border-bottom: 1px solid #c5c5c5;
                padding-bottom: 15px;
                margin-bottom: 15px;

               .search-icon {
                    top: 12px;
                } 
            }
            .filter-page-date-filters {
                border-bottom: 1px solid #c5c5c5;
                padding-bottom: 15px;
                margin-bottom: 15px;
                
                .react-datepicker-wrapper {
                    display: block;
                }

                .date-filter-submit {
                    color: white;

                    &:hover {
                        color: black;
                    }
                }
            }            
            .filter-page-profile-types-wrap {
                .filter-page-profile-type  {
                    background: white;
                    padding: 10px;
                    border-radius: 5px;
                    margin-bottom: 15px;

                    .filter-page-profile-wrap {
                        display: none;
                    }
                    h4 {
                        color: #161a2f;
                        margin-bottom: 0px;
                        cursor: pointer;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        
                        .profile-filter-arrow {
                            color: white;
                            background: $orange;
                            padding: 2px 10px;
                            border-radius: 5px;

                            svg {
                                position: relative;
                                top: -1px;
                                transform: rotate(180deg);
                            }
                        }
                        .selected-count {
                            font-weight: 100;
                            color: $orange;
                            margin-right: 10px;
                        }
                    }
                    .filter-page-profile  {
                        color: #161a2f;
                        margin-bottom: 5px;
                        cursor: pointer;
                        display: flex;
                        align-items: center;

                        &.is-criteria {
                            .filter-page-profile__checkbox {
                                background: $orange;
                            }
                        }

                        &.is-disabled {
                            color: #bdbaba;
                            cursor: initial;

                            .filter-page-profile__checkbox {
                                border: 1px solid #bdbaba;
                            }
                        }
                        
                        .filter-page-profile__checkbox {
                            width: 15px;
                            height: 15px;
                            min-width: 15px;
                            border: 1px solid black;
                            margin-right: 10px;
                        }
                    }

                    &.filter-page-open {
                        h4 {
                            padding-bottom: 15px;
                            margin-bottom: 15px;
                            border-bottom: 1px solid #e2e2e2;
                        }
                        .profile-filter-arrow {
                            background: #bdbaba;

                            svg {
                                transform: rotate(0deg);
                                top: -2px;
                            }
                        }
                        .filter-page-profile-wrap {
                            display: block;
                            max-height: 250px;
                            overflow: auto;
                        }
                    }
                }
            }
        }
        .filter-page-content {
            background: white;
            width: 100%;
            padding: 15px 0px;
            overflow: auto;

            .filter-page-content__header {
                padding: 0px 25px; 
                display: flex;
                align-items: center;
                justify-content: space-between;
                flex-wrap:wrap;

                @media (max-width: 767px) {
                    display: block;
                }

                .header-btns-wrap {
                    display: flex;
    
                    @media (max-width: 500px) {
                        flex-direction: column;
                        margin-bottom: 10px;
                    }
                }
                
                @media (max-width: 767px) {
                    flex-wrap: wrap;
                }

                .filter-page-content__header-title {
                    display: flex;
                    align-items: center;
                    margin-bottom: 20px;

                    @media (max-width: 500px) {
                        margin-bottom: 0px;
                    }

                    h1 {
                        font-size: 2rem;
                        margin-right: 10px;
                    }
                    svg {
                        width: 20px;
                    }
                }
            }
            .filter-page-criteria {
                padding: 25px 15px;
                display: flex;
                align-items: center;
                border-bottom: 1px solid #e2e2e2;

                ul {
                    margin-bottom: 0px;
                    margin-left: 20px;
                }
                .label {
                    font-weight: 600;
                    color: #161a2f;
                    font-size: 1.2rem;
                    min-width: 115px;
                }
                .clear-search {
                    color: $orange;
                    cursor: pointer;
                    font-size: 18px;
                    font-weight: bold;

                    svg {
                        margin-right: 5px;
                    }

                    &:hover {
                        color: #161a2f;
                    }
                }
            }
            .filter-page-content__categories {
                background: #161a2f;
                padding-right: 10px;

                @media (max-width: 767px) {
                    background: none;
                    padding: 0px 25px;
                }

                ul {
                    display: flex;
                    list-style: none;
                    padding-left: 0px;
                    margin-bottom: 0px;

                    @media (max-width: 767px) {
                        flex-wrap: wrap;
                    }
                }
                li {
                    text-align: center;
                    padding: 10px 15px;
                    font-weight: 700;
                    color: white;
                    cursor: pointer;
                    border-right: 1px solid #e2e2e2;
                    transition: all .3s;

                    @media (max-width: 767px) {
                        width: 47%;
                        background: #161a2f;
                        margin: 5px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }

                    @media (max-width: 575px) {
                        width: 100%;
                    }
                    
                    &:hover {
                        color: $orange;
                    }
                    &.active {
                        background: $orange;
                        color: white;
                    }
                }
            }
            .filter-page-content__content {
                padding: 15px 25px;
                color: black;
                height: 100%;

                h3 {
                    font-size: 1.5rem;
                    margin-bottom: 30px;
                }
                .filter-page-resources__overview {
                    margin-bottom: 60px;
                    
                    .filter-page-resources__overview-list {
                        .filter-page-resource-item {
                            min-height: 200px;
                        }
                        
                        @media (max-width: 1200px) {
                            .col-12 {
                                margin-bottom: 30px;
    
                                &:last-of-type {
                                    display: none;
                                }
                            }
                        }
                    }
                }
                .resource-filter-page-results {
                    &.grid-view {
                        .filter-page-resource-item {
                            min-height: 200px;
                        }
                        
                    }
                    &.list-view {
                        .col-12 {
                            width: 100% !important;
                            flex: 0 0 100%;
                            max-width: 100%;
                            margin-bottom: 15px;
                        }
                        .filter-page-resource-item {
                            padding: 5px;
                            box-shadow: none;
                            border: 1px solid #e2e2e2;

                            &::after {
                                content: none;
                            }
                            .resource-item__category,
                            .resource-item__image {
                                display: none;
                            }
                            .resource-item__details {
                                flex-direction: initial;
                                align-items: center;
                            }
                            .resource-item__title {
                                color: #161a2f;
                                font-weight: 100;
                            }
                            .resource-item__buttons {
                                margin-top: 0px;
                            }
                            .resource-item__list-icon {
                                display: block;
                                margin-right: 15px;
                            }
                            .resource-item__text {
                                display: flex;
                            }
                        }
                    }
                    .col-12 {
                        margin-bottom: 30px;
                    }
                }
                .filter-page-resource-item {
                    padding: 15px;
                    box-shadow: 2px 2px 10px 2px rgba(0,0,0,0.40);
                    border-radius: 5px;
                    display: flex;
                    height: 100%;
                    position: relative;
                    overflow: hidden;

                    &::after {
                        content: '';
                        background: #161a2f;
                        position: absolute;
                        bottom: 0px;
                        width: 100%;
                        left: 0;
                        height: 40px;
                        z-index: 1;
                    }

                    .resource-item__title {
                        color: $orange;
                        font-weight: 600;
                    }
                    .resource-item__list-icon {
                        display: none;
                    }
                    .resource-item__details {
                        margin-left: 15px;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        width: 100%;
                    }
                    .resource-item__category {
                        color: $black;
                        font-weight: 600;
                    }
                    .resource-item__image {
                        max-height: 120px;
                        max-width: 90px;

                        &.has-image {
                            overflow: hidden;
                            border: 1px solid #e2e2e2;
                            box-shadow: 1px 1px 5px 1px rgba(0,0,0,0.40);
                            width: 125px;
                        }                        
                        img {
                            height: 120px;
                        }
                    }
                    .resource-item__no-image {
                        height: 100%;
                        min-height: 120px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 90px;
                        background: $orange;
                        color: white;
                        border: 1px solid #e2e2e2;
                        box-shadow: 1px 1px 5px 1px rgba(0,0,0,0.40);
                        font-size: 44px;
                        position: relative;
                        z-index: 2;
                    }
                    .resource-item__buttons {
                        display: flex;
                        justify-content: flex-end;
                        margin-top: 10px;
                        position: relative;
                        z-index: 2;

                        .resource-item__button {
                            background: $orange;
                            color: white;
                            width: 40px;
                            height: 40px;
                            border-radius: 5px;
                            border: 1px solid white;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            font-size: 20px;
                            margin-left: 5px;
                            transition: color .3s;
                            cursor: pointer;

                            a {
                                transition: color .3s;
                                color: white;
                            }
                            &:hover, &:hover a {
                                color: #161a2f;
                            }
                            &.button-fav {
                                background: #bdbdbd;
                                cursor: pointer;

                                &.is-fav {
                                    background: $orange;
                                }
                            }
                        }
                    }
                }
            }
        }
        .resource-filter-page-list-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 1.5rem;
            font-weight: 600;
            margin-bottom: 20px;

            @media (max-width: 991px) {
                flex-direction: column;
                align-items: initial;
            }

            .total-count {
                color: $orange;
                font-size: 1.2rem
            }
            .filter-page-header__results {
                display: flex;
                flex-direction: column;

                @media (max-width: 991px) {
                    flex-direction: initial;
                    align-items: center;
                    margin-bottom: 10px;

                    span {
                        margin-left: 10px;
                        margin-top: 2px;
                    }
                }
            }
            .filter-page-header__controls {
                display: flex;

                @media (max-width: 991px) {
                    flex-wrap: wrap;
                }
            }
            .filter-page-header__view {
                display: flex;
                align-items: center;
                font-size: 18px;
                font-weight: 100;

                @media (max-width: 991px) {
                    min-width: 100%;
                    margin-top: 15px;
                }

                .pipe {
                    border-left: 1px solid #e2e2e2;
                    height: 28px;
                }
            }
            .filter-page-header__view-btn {
                margin-left: 10px;
                margin-right: 10px;
                color: #e2e2e2;
                display: flex;
                align-items: center;
                cursor: pointer;

                &.active {
                    color: $orange;
                }
                svg {
                    font-size: 28px;
                    margin-right: 5px;
                }
            }
            .filter-page-header__sort,
            .filter-page-header__sort-dir {
                display: flex;
                align-items: center;
                font-size: 18px;
                font-weight: 100;
                margin-right: 50px;

                @media (max-width: 991px) {
                    flex-direction: column;
                    align-items: initial;
                }

                @media (max-width: 575px) {
                    margin-right: 20px;
                    margin-bottom: 10px;
                }
               
                span {
                    margin-right: 10px;
                }
                .dropdown {
                    button {
                        border: 1px solid #e2e2e2;
                        background: 0px;
                        font-size: 18px;
                        font-weight: 100;
                        font-family: acumin-pro-condensed, sans-serif;
                        width: 150px;
                        text-align: left;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        
                        &::after {
                            border: 0px;
                            content: none;
                        }
                        &:hover, &:focus {
                            color: #161a2f;
                        } 
                        svg {
                            border-left: 1px solid #e2e2e2;
                            padding-left: 10px;
                            height: 25px;
                            width: 25px;
                        }
                    }
                }
            }
            .filter-page-header__sort-dir {
                button {
                    width: 100px;
                }
            }
        }
    }

    .resource-tree-index-list {
        margin-bottom: 100px;

        @media (max-width: 1200px) {
            .col-12 {
                margin-bottom: 10px;
            }
        }
        
        .resource-tree-node {
            box-shadow: 2px 2px 10px 2px rgba(0,0,0,0.40);
            border-left: 1px solid #e2e2e2;
            border-radius: 5px;
            overflow: hidden;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .resource-tree-node__title {
                background: $orange;
                color: white;
                font-weight: 600;
                padding: 5px 10px;
            }
            .resource-tree-node__desc {
                padding: 5px 10px;
            }
            .resource-tree-node__link-wrap {
                display: flex;
                justify-content: flex-end;
                padding: 10px;
            }
            .resource-tree-node__link {
                background: $orange;
                color: white;
                font-weight: 600;
                width: 70px;
                text-align: center;
                border-radius: 5px;
                padding: 3px 5px;
                cursor: pointer;
                transition: all .3s;

                &:hover {
                    color: black;
                }
            }
        }
    }

    .filter-page-content__pre-filters {
        .resource-index-intro {
            color: black;
            padding: 15px;
            border-bottom: 1px solid #e2e2e2;

            .resource-tree-index-list {
                margin-bottom: 30px;

                @media (max-width: 1200px) {
                    .col-12  {
                        margin-bottom: 30px;
                    }
                }
            }
            .resource-index-sub-list {
                margin-top: 30px;
            }
        }
    }

    .resources-filter-page {
        .filter-page-content {
            padding-top: 0px;
        }
        .filter-page-content__header {
            position: sticky;
            top: 0;
            z-index: 10000;
            background: white;
            padding-top: 15px !important;

            @media (max-width: 767px) {
                position: initial;
            }
        }
    }

    .data-filter-page {
        .filter-page-content {
            padding-top: 0px;
        }
        .filter-page-content__header {
            position: sticky;
            top: 0;
            z-index: 10000;
            background: white;
            padding-top: 15px !important;

            @media (max-width: 990px) {
                position: initial;
            }
        }
        
        @media (max-width: 767px) {
            .filter-page-content .filter-page-content__header {
                flex-direction: column;
                align-items: initial;
            }
        }

        .filter-page-criteria {
            border-top: 1px solid #e2e2e2;
        }
        .result-count {
            font-weight: 600;
            text-decoration: underline;
            font-size: 1.5rem;
        }

        .map-container {
            position: relative;

            &.is-locked::after {
                position: absolute;
                z-index: 2;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                background: white;
                opacity: 0.5;
                
                content: "";
                display: block;
            }
        }

        #map {
            width: 100%;
            height: 700px;
            position: relative;
            z-index: 1;
        }
        .data-header-btns {
            display: flex;
            flex-wrap: wrap;

            @media (max-width: 500px) {
                display: block;
            }

            .filter-page-header-btn {
                margin-left: 10px;
                min-width: 115px;

                @media (max-width: 767px) {
                    margin-left: 0px;
                    margin-right: 10px;
                    margin-bottom: 10px;
                }

                @media (max-width: 500px) {
                    display: block;
                    margin-right: 0px;
                }
            }
            select {
                height: 38px;
                padding: 0px 5px;

                @media (max-width: 767px) {
                    margin-right: 10px;
                }

                @media (max-width: 575px) {
                    margin-bottom: 5px;
                }

                @media (max-width: 500px) {
                    width: 100%;
                }
            }
        }
        .data-upload-cta {
            border: 1px solid #161a2f;
            border-radius: 5px;
            max-width: 500px;

            @media (max-width: 767px) {
                max-width: 100%;
            }

            &.no-form {
                .data-upload-cta__content {
                    padding: 20px;
                    display: flex;
                    justify-content: center;
                }
            }

            .data-upload-cta__header {
                background: #161a2f;
                color: white;
                border-top-left-radius: 5px;
                border-top-right-radius: 5px;
                padding: 8px;
                font-size: 20px;
                font-weight: 600;
            }

            .data-upload-cta__content {
                padding: 8px;

                label {
                    color: black;
                    font-weight: 600;
                }
                select {
                    border: 1px solid #ced4da;
                }
                .show-form-btn {
                    background: $orange;
                    color: white;
                    border-radius: 5px;
                    cursor: pointer;
                    padding: 5px;
                    width: 200px;
                    text-align: center;

                    &:hover {
                        color: black;
                    }
                }
                button[type=submit] {
                    font-weight: 100;
                    font-size: 16px;
                    border-color: white;

                    @media (max-width: 575px) {
                        font-size: 12px;
                    }

                    svg {
                        font-size: 16px;
                    }
                }
            }
        }
    }

    .network-filter-page {
        .filter-page-filters {
            height: 100%;

            @media (max-width: 575px) {
                width: 100%;
            }
        }
        .filter-page-content {
            padding-top: 0px;
        }
        .filter-page-content__header {
            position: sticky;
            top: 0;
            z-index: 10000;
            background: white;
            padding-top: 15px !important;

            @media (max-width: 990px) {
                position: initial;
            }
        }
        .filter-page-content__header-title {
            margin-bottom: 0px !important;
        }
        .network-letter-list {
            display: flex;
            justify-content: space-between;
            border-top: 1px solid #ced4da;
            padding-top: 20px;
            margin-bottom: 30px;

            @media (max-width: 767px) {
                flex-wrap: wrap;
                justify-content: flex-start;
            }

            .network-letter-list__letter {
                color: $orange;
                background: #e2e2e2;
                text-transform: uppercase;
                width: 30px;
                height: 30px;
                font-weight: 600;
                cursor: pointer;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-right: 5px;
                border-radius: 5px;

                @media (max-width: 767px) {
                    margin-bottom: 5px;
                }

                &:hover {
                    color: white;
                    background: $orange;
                }
                &.is-active {
                    background: #161a2f;
                }
            }
        }
        .user-detail-list {
            margin-bottom: 30px;

            .user-detail-list__header {
                font-weight: 600;
                font-size: 20px;

                a {
                    margin-left: 5px;
                    font-weight: 100;
                    font-size: 16px;             
                }
            }
            .user-detail-list__items {
                display: flex;
                flex-wrap: wrap;
                margin-top: 10px;
            }
            .user-detail-list__item {
                border-radius: 5px;
                background: $orange;
                color: white;
                display: flex;
                margin-bottom: 10px;
                margin-right: 10px;

                .user-detail-list__item-left {
                    background: #e2e2e2;
                    width: 34px;
                    height: 100%;
                    border-top-left-radius: 5px;
                    border-bottom-left-radius: 5px;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    span {
                        display: inline-block;
                        width: 12px;
                        height: 12px;
                        background: $orange;
                        border-radius: 50%;
                    }
                }
                .user-detail-list__item-title {
                    padding: 5px 10px;
                    padding-right: 10px;

                    a {
                        color: white;
                        text-decoration: none;
                        
                        &:hover {
                            color: black;
                        }
                    }
                }
            }
        }

        .users-list {
            h3 {
                font-size: 20px !important;
                margin-bottom: 10px !important;
            }
            a {
                color: white;

                &:hover {
                    color: $orange;
                }
            }
            .filter-page-user-item {
                border-radius: 5px;
                color: white;
                background: #161a2f;
                margin-bottom: 10px;
                height: 40px;

                a {
                    display: flex;
                    align-items: center;
                    height: 100%;
                }
                .filter-page-user-item__image {
                    overflow:hidden;
                    border-top-left-radius: 5px;
                    border-bottom-left-radius: 5px;
                    width: 40px;
                    height: 100%;

                    img {
                        height: 40px;
                    }
                }
                .filter-page-user-item__name {
                    padding-left: 10px;
                }
            }
        }
    }
}

.basket-modal {
    h3 {
        color: $orange;
        margin-bottom: 30px;

        @media (max-width: 500px) {
            margin-bottom: 10px;
        }
    }
    .basket-modal-header {
        display: flex;
        justify-content: space-between;

        @media (max-width: 500px) {
            flex-direction: column;
            margin-bottom: 20px;
        }
    }
    .basket-item {
        border: 1px solid #e2e2e2;
        border-radius: 5px;
        display: flex;
        align-items: center;
        padding: 5px 10px;
        justify-content: space-between;
        margin-bottom: 10px;

        .basket-item__title {
            display: flex;
            align-items: center;
            
            svg {
                color: $orange;
                margin-right: 10px;
            }
        }
        .basket-item__remove {
            margin-left: 15px;
            color: white;
            background: $orange;
            cursor: pointer;
            border-radius: 5px;
            min-width: 31px;
            min-height: 31px;
            display: flex;
            align-items: center;
            justify-content: center;
            
            &:hover {
                color: black;
            }
        }
    }
    .modal-header {
        padding: 0px;
    }
    .btn-light {
        border: none;
        background: none;
    }
}

.filter-page-header-btn {
    background: $orange;
    color: white;
    padding: 7px 10px;
    border-radius: 5px;
    cursor: pointer;
    position: relative;
    margin-bottom: 20px;

    @media (max-width: 500px) {
        margin-bottom: 5px;
        margin-left: 0px !important;
    }

    &::before {
        content: "";
        display: block;
        position: absolute;
        width: 37px;
        height: 100%;
        background: #cc5024;
        top: 0;
        left: 0;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
    }
    &:hover {
        color: black;
    }
    span {
        padding-right: 15px;
        z-index: 1;
        position: relative;
        
        svg {
            min-width: 20px;
        }
    }
}

.column-resources-new {
    @media (max-width: 1440px) {
        .show-filters {
            .filter-page-resources__overview-list {
                .col-12 {
                    max-width: 50% !important;
                    margin-bottom: 10px;
                    flex-basis: 50%;
                }
            }
        }
    }

    @media (min-width: 1440px) and (max-width: 1650px) {
        .filter-page-resources__overview-list {
            .col-12 {
                max-width: 50% !important;
                margin-bottom: 10px;
                flex-basis: 50%;
            }
        }
    }
}

.filter-page__advert {
    display: flex;
    justify-content: center;
    
    img {
        max-width: 430px;
    }
}
