.sidemenu{
    color: $body-color;
    display: flex;
    z-index: $zindex-fixed;

    .side-menu-content {
        .btn {
            min-width: 110px;
        }
    }

    .btn {
        color: white;
    }
}

.menu-list {
    counter-reset: a;
    list-style-type: decimal;
    margin-bottom: 6rem;
    margin-left: 40px;
    margin-top: 40px;
    padding: 0;

    @media (max-width: 768px) and (min-width: 576px) {
        margin-top: 60px;
    }

    li {
        @include font-size(32px);
        display: block;
        float: none;
        letter-spacing: (10 / 1000) * 1em;
        list-style: none;
        margin-bottom: 0.5rem;
        position: relative;

        a {
            color: #383838;
            font-weight: 600;

            &:hover {
                color: #f86029;
                text-decoration: none;
            }
        }

        &:before {
            @include font-size(14px);
            box-sizing: border-box;
            content: counter(a,decimal-leading-zero);
            counter-increment: a;
            color: #f86029;
            font-weight: 600;
            letter-spacing: (20 / 1000) * 1em;
            margin-right: 8px;
            padding: 4px;
            text-align: center;
            position: absolute;
            left: -2.5rem;
            top: .5rem;
            width: 2rem;
        }
    }
}

.social-links-list {
    margin-left: 4.25rem;
    width: 250px;

    li a {
        @include font-size(14px);
        color: #90939a;
        font-weight: 700;
        letter-spacing: (80 / 1000) * 1em;
        text-transform: uppercase;
    }
}

