
$primary:   #ff7442;
$secondary: #dfe1e0;
$dark:      #303449;
$light:     #dfe1e0;
$ternary:   #f86029;

$theme-colors: (
    "primary":   $primary,
    "secondary": $secondary,
    "dark":      $dark,
    "light":     $light,
    'ternary' :  $ternary,
    'darker':    #161a2f
);

$dark-blue: #161a2f;
$light-grey: #ebebeb;
$grey: #90939a;

$orange: #ff7442;
$blue: #00b0ff;
$green: #00cf69;

$enable-responsive-font-sizes: true;

$body-color: $dark-blue;

// $h1-font-size:   $font-size-base * 2.5;
$h2-font-size:      2.5rem; // 40px
$h3-font-size:      1.625rem; // 26px
$h4-font-size:      1.125rem; // 18px
// $h5-font-size:   $font-size-base * 1.25 !default;
// $h6-font-size:   $font-size-base !default;

$font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$headings-font-family: "acumin-pro-condensed", $font-family-sans-serif;

$headings-font-weight:  600;

$border-radius: .625rem;

$input-btn-font-size:    18px;
$input-btn-font-size-lg: 18px;
$input-btn-padding-y-lg: 1.25rem;

$btn-font-size:       18px;
$btn-font-weight:     600;

$form-group-margin-bottom: 1.5rem;

$custom-checkbox-indicator-border-radius: 3px;

// $btn-padding-y:               $input-btn-padding-y !default;
// $btn-padding-x:               $input-btn-padding-x !default;
// $btn-font-family:             $input-btn-font-family !default;
// $btn-font-size:               $input-btn-font-size !default;
// $btn-line-height:             $input-btn-line-height !default;

// $btn-padding-y-sm:            $input-btn-padding-y-sm !default;
// $btn-padding-x-sm:            $input-btn-padding-x-sm !default;
// $btn-font-size-sm:            $input-btn-font-size-sm !default;
// $btn-line-height-sm:          $input-btn-line-height-sm !default;

// $btn-padding-y-lg:            $input-btn-padding-y-lg !default;
// $btn-padding-x-lg:            $input-btn-padding-x-lg !default;
// $btn-font-size-lg:            $input-btn-font-size-lg !default;
// $btn-line-height-lg:          $input-btn-line-height-lg !default;

// $btn-border-width:            $input-btn-border-width !default;

// $btn-font-weight:             $font-weight-normal !default;
// $btn-box-shadow:              inset 0 1px 0 rgba($white, .15), 0 1px 1px rgba($black, .075) !default;
// $btn-focus-width:             $input-btn-focus-width !default;
// $btn-focus-box-shadow:        $input-btn-focus-box-shadow !default;
// $btn-disabled-opacity:        .65 !default;
// $btn-active-box-shadow:       inset 0 3px 5px rgba($black, .125) !default;

// $btn-link-disabled-color:     $gray-600 !default;

// $btn-block-spacing-y:         .5rem !default;

$btn-border-radius:     .25rem; // 4px
$btn-border-radius-lg:  .25rem; // 4px
$btn-border-radius-sm:  .25rem; // 4px
$btn-font-family: "acumin-pro-semi-condensed", $font-family-sans-serif;

$nav-link-padding-x: 2rem;
$nav-link-padding-y: 1.5rem;
$nav-tabs-border-width: 0;
$nav-tabs-border-radius: 0;
$nav-tabs-link-active-color: $body-color;

$input-placeholder-color: $grey;

$input-font-weight: 500;
$input-padding-y-lg: 2rem;
$input-padding-x-lg: 1rem;
$input-font-family: "acumin-pro-semi-condensed", $font-family-sans-serif;
$input-font-size-lg: 1.25rem;
$input-line-height-lg: 1;
$input-border-radius-lg: .25rem;

$modal-content-color: $dark-blue;

// $dropdown-bg:                       $dark-blue;
$dropdown-border-color:             1px solid rgba(255, 255, 255, 0.4);
$dropdown-color:                    #fff;
$dropdown-border-radius:            8px;
$dropdown-border-width:             1px;
// $dropdown-inner-border-radius:      0;
// $dropdown-divider-bg:               $gray-200;
// $dropdown-divider-margin-y:         $nav-divider-margin-y;
// $dropdown-box-shadow:               none;
