.App {
    height: 100vh;
    overflow: hidden;

    .background-canvas {
        background: black;
        width: 100%;
        height: 100%;
        padding: 0;
        display: block;
        top: 0;
    }

    .progress .progress-bar {
        transition: none !important;
    }

    .main-content {
        height: 100vh;
        overflow: hidden;
        white-space: nowrap;
    }

    .main-column {
        overflow: hidden;
        display: block;
        transition: left 1s, right 1s;
        // transition: left 1s, right 1s, width 250ms;
        overflow: hidden;
        margin: 0;
        position: fixed;
        top: 0;
        bottom: 0;
        z-index: 2;

        &.title-page-column {
            z-index: 1;
        }

        @media (max-width: 768px) {
            &:not(.title-page-column) {
                z-index: 2000;
            }
        }
    }

    .main-column .main-column-content {
        white-space: normal;
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        // transition: width 250ms 250ms;
    }

    .main-column-content-inner {
        background: #fff;
        border-top-left-radius: $border-radius;
        flex-grow: 1;
        flex-shrink: 0;
        height: 100vh;
        //overflow-y: scroll;
        overflow-y: auto;
        width: 100%;

        @media (max-width: 576px) {
            padding-bottom: 85px !important;
        }

        &.sidemenu {
            @media (max-width: 576px) {
                padding-bottom: 0px !important;
            }
        }

        &.sidemenu,
        &.sidebar-left {
            border-top-left-radius: 0;
            border-bottom-right-radius: $border-radius;
            border-top-right-radius: $border-radius;
        }

        &.sidebar-right {
            border-bottom-left-radius: $border-radius;
            border-top-left-radius: $border-radius;
        }
    }

    .top-menu {
        position: fixed;
        width: 100%;
        top: 0;
        z-index: 20;
    }

    .app-buttons {
        position: fixed;
        flex-grow: 0;
        flex-shrink: 0;
        left: 25px;
        top: 20px;
        z-index: 2;

        @media (max-width: 768px) {
            top: 25px;
            left: 25px;
        }
    }

    &.menu-open, &.data-full-width, &.data-index-full-width, &.resource-tree-full-width {
        .app-buttons {
            z-index: 0;
        }
    }

    .menu-button {
        border: none;
        border-radius: 0;
        cursor: pointer;
        display: inline-block;
        height: 14px;
        width: 35px;

        span {
            display: block;
            border-top: 2px solid #fff;
            margin-bottom: 5px;
            width: 100%;
        }
    }

    .lang-button {
        color: #fff;
        cursor: pointer;
        font-size: 16px;
        max-width: 70px;

        button {
            max-width: 70px;
        }

        span {
            font-weight: 700;
            letter-spacing: (80 / 1000) * 1em;
        }
    }

    .user-button {
        color: #fff;
        font-size: 26px;
        letter-spacing: (20 / 1000) * 1em;
        line-height: 1;
        position: relative;

        &.has-notifications {
            &::after {
                content: "";
                border-radius: 50%;
                width: 8px;
                height: 8px;
                background: #bf2323;
                display: block;
                position: absolute;
                right: -3px;
                bottom: -2px;
            }
        }
    }

    #app-search-button {
        top: 20px;

        @media (max-width: 575px) {
            z-index: 2;
        }
    }

    .search-button,
    .close-button {
        background: #ededee;
        border: 0;
        font-weight: 300;
        height: 60px;
        line-height: 60px;
        padding: 0;
        position: absolute;
        top: 40px;
        width: 60px;

        &.left {
            left: 40px;
        }

        &.right {
            right: 40px;
        }

        &:hover {
            background: #fff;
        }

        @media (max-width: 575px) {
            font-size: 15px !important;
            width: 40px;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;

            &.right {
                right: 20px !important;
            }
        }
    }

    .search-button {
        font-size: 22px;
    }

    .close-button {
        font-size: 26px;
    }

    .sidebar-left .close-button {
        left: 40px;
    }

    .sidebar-right .close-button {
        right: 40px;
    }

    .page-col {
        height: 100vh;
        display: inline-block;
    }

    .globe-tooltip {
        position: fixed;
        border-radius: $border-radius;
        background: map-get($theme-colors, "primary");
    }

    .globe-tooltip .triangle {
        position: absolute;
        width: 0;
        height: 0;
        left: 50%;
        bottom: -10px;
        margin-left: -15px;
        border-style: solid;
        border-width: 10px 15px 0 15px;
        border-color: map-get($theme-colors, "primary") transparent transparent transparent;
    }
}

.splash {
    background: $dark-blue;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1050;
    transition: opacity 1s ease;

    &.has-message {
        position: initial;
        margin-top: 250px;

        @media (max-width: 500px) {
            margin-top: 150px;
        }
    }

    .splash-message {
        width: 500px;
        margin-top: 30px;
        padding: 10px;
    }

    .main-title {
        font-size: 6.2vw;
        letter-spacing: -0.32px;
        background: linear-gradient(to right, rgb(247, 78, 80), map-get($theme-colors, "primary"));
        display: inline;
        background-clip: text;
        text-fill-color: transparent;
        -webkit-text-fill-color: transparent;
        font-weight: 700;
        padding-left: 3rem;

        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
            background: none;
            color: map-get($theme-colors, "primary");
        }

        @media (max-width: 1380px) {
            padding-left: 0px;
        }

        @media (max-width: 1200px) {
            font-size: 5vw;
        }

        @media (max-width: 768px) {
            font-size: 60px;
        }

        @media (max-width: 576px) {
            font-size: 45px;
        }
    }
}

.pill-list {
    .list-inline-item {
        border: 1px solid #ebebeb;
        border-radius: 19px;
        color: #383838;
        font-weight: 500;
        letter-spacing: (10 / 1000) * 1em;
        padding: 0.5rem 1rem;
        cursor: pointer;
        transition: all 200ms ease-in;

        &:hover {
            background: $orange;
            color: white;
        }
    }
}

.news-tag {
    z-index: 1;

    &:hover {
        z-index: 5;
    }
}

.pulse {
    z-index: 1;

    &:hover {
        & + .news-tag {
            visibility: visible;
            z-index: 1;
        }
    }

    a {
        box-shadow: 0 0 0 0 rgba($primary, 1);
        transform: scale(1);
        animation: pulse 2s infinite;
    }
}

@keyframes pulse {
    0% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba($primary, 0.7);
    }

    70% {
        transform: scale(1);
        box-shadow: 0 0 0 10px rgba($primary, 0);
    }

    100% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba($primary, 0);
    }
}

.modal {
    z-index: 3000;
}

.api-information {
    @media (max-width: 575px) {
        .main-column-content-inner {
            padding-left: 20px !important;
            padding-right: 20px !important;
        }
    }

    @media (max-width: 500px) {
        .border.p-3.mb-2 {
            border: none !important;
            padding: 0px !important;
        }
    }
}

.country-picker {
    .main-column-content {
        background: #161a2f;

        .country-picker-wrap {
            margin-top: 10px;
            width: 75%;
            display: flex;
            flex-direction: column;
        }
    }
}

.page-content-wrap {
    &.has-logo.width-75 {
        margin-top: 60px;
    }
}
.page-back svg {
    font-size: 12px;
}
.page-logo {
    width: 140px;
    position: absolute;
    top: 35px;

    img {
        max-width: 100%;
    }
}
.page-banner-image {
    position: relative;

    img {
        max-width: 100%;
    }

    .page-banner-caption {
        position: absolute;
        bottom: 0;
        left: 0;
        padding: 10px 15px;
        margin: 20px;
        min-width: 350px;
        max-width: 350px;

        h4 {
            margin-bottom: 0px;
        }
    }
}
.page-children {
    .row {
        justify-content: center;
    }
    .page-child-section {
        margin-bottom: 15px;

        img {
            object-fit: cover;
            width: 100%;
            height: 150px;
            margin-bottom: 10px;
        }
        a {
            color: black;

            h3 {
                margin-bottom: 5px;
            }

            &:hover {
                color: $orange;
                text-decoration: none;
            }
        }
    }
}
.page-accordion {
    padding: 20px 0px;

    .card-header {
        p {
            margin-bottom: 0px;
        }
    }
}
.test123 {
    position:absolute;
    right: 100;
    top:0;
}

.resource-item__overlay {
    position: absolute;
    top: 40%;
    right: 10%;
    transform: translate(-0%, -60%);
    // z-index: -1; /* Ensures it's behind content */
    pointer-events: none; /* Prevents interaction */
}

.overlay-lock-icon {
    color: gray;
    font-size: 8rem; /* Adjust size as needed */
    opacity: 0.3; /* Slight transparency for subtle effect */
}


.resource-item__locked {
    width: 100%;
    position: absolute;
    left: 10px;
    bottom: 5px;
    z-index: 99;
    .resource-item__locked-inner {
        display: flex;
        align-items: center;

        .resource-item__locked-icon{
            background: #ff7442;
            border-radius: 50%;
            width: 30px;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 10px;
        }
    }
    .resource-item__locked-text{
        color: white;
        font-weight: bold;
    }
  }

  .fav-resource-item__locked {
    width: 100%;
    .resource-item__locked-inner {
        display: flex;
        align-items: center;

        .resource-item__locked-icon{
            background: #ff7442;
            border-radius: 50%;
            width: 30px;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 10px;
        }
    }
    .resource-item__locked-text{
        color: black;
        font-weight: bold;
    }
  }

  .resource-fav-container {
    margin-bottom: 10px;;
  }
  
