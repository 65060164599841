.institutions, .collaborators {
    .institutions__list, .collaborators__list {
        padding-bottom: 30px;
    }

    .institutions__delete-btn, .collaborators__delete-btn {
        margin: 10px 0px;
        font-size: 14px;
    }
    
    .institutions__list-item, .collaborators__list-item {
        background-color: #e0e0e0;
        display: inline-flex;
        transition: background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        align-items: center;
        white-space: nowrap;
        border-radius: 16px;
        justify-content: center;
        margin-right: 5px;
        margin-bottom: 5px;
        height: 32px;
        padding: 0px 15px;

        &.to-delete {
            background: #dc3545;
            color: white;

            svg {
                color: white;
            }
        }

        .institutions__list-remove, .collaborators__list-remove {
            color: #a6a6a6;
            padding-left: 10px;
            cursor: pointer;
        }
    }

    .institutions__add, .collaborators__add {
        padding-bottom: 40px;
        border-bottom: 1px solid #ced4da;
        margin-bottom: 40px;

        .institutions__add-btn, .collaborators__add-btn {
            margin-top: 20px;
            font-size: 14px;
            color: white;
            min-width: 120px;
            min-height: 35px;

            svg {
                color: white;
            }
        }
        
        .select-search {
            .select-search__value {
                input {
                    display: block;
                    width: 100%;
                    font-size: 1.125rem;
                    font-weight: 500;
                    color: #495057;
                    background-color: #fff;
                    background-clip: padding-box;
                    border: 1px solid #ced4da;
                    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
                    padding: 5px;
                }
            }

            .select-search__select {
                border: 1px solid #ced4da;
                position: relative;
                top: -1px;
                padding: 10px 10px;
            }

            .select-search__options {
                margin-bottom: 0px;
                list-style: none;
                padding-left: 0px;

                button {
                    background: none;
                    border: 0px;
                    padding: 5px 0px;
                    transition: 150ms ease-in-out;

                    &:hover {
                        color: #a6b1bb;
                    }

                    &.is-selected {
                        color: $primary;
                    }
                }
            }
        }
    }

    .institutions__create, .collaborators__create {
        a {
            color: white;
            margin-bottom: 40px;
            font-size: 14px;
        }
    }
}

.select-search {
    .select-search__value {
        input {
            display: block;
            width: 100%;
            font-size: 1.125rem;
            font-weight: 500;
            color: #495057;
            background-color: #fff;
            background-clip: padding-box;
            border: 1px solid #ced4da;
            transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
            padding: 5px;
        }
    }

    .select-search__select {
        border: 1px solid #ced4da;
        position: relative;
        top: -1px;
        padding: 10px 10px;
    }

    .select-search__options {
        margin-bottom: 0px;
        list-style: none;
        padding-left: 0px;

        button {
            background: none;
            border: 0px;
            padding: 5px 0px;
            transition: 150ms ease-in-out;

            &:hover {
                color: #a6b1bb;
            }

            &.is-selected {
                color: $primary;
            }
        }
    }
}

.institutions__create-form, .user-profile-form, .collaborators__create-form {
    .submit-btn {
        color: white;
        min-width: 205px;
        min-height: 41px;
        
        svg {
            color: white;
        }
    }

    .relationships__header {
        display: flex;
        margin-bottom: 15px;

        .relationships-add {
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            color: white;
            font-size: 12px;
            width: 20px;
            height: 26px;
            border-radius: 50%;
            top: 5px;
            margin-left: 10px;
        }
    }

    .relationships__type {
        margin-bottom: 10px;

        .relationships__type-header {
            font-weight: 600;
            margin-bottom: 5px;
        }

        .relationships__profile {
            display: flex;
            align-items: center;
            background: #e0e0e0;
            border-radius: 15px;
            padding: 0px 15px;
            margin-right: 5px;
            margin-bottom:5px;

            &.to-delete {
                color: #721c24;
                background-color: #f8d7da;
                border-color: #f5c6cb;

                svg {
                    font-size: 13px;
                }
            }

            .relationshios__profile-delete {
                margin-left: 10px;
                cursor: pointer;
                font-size: 12px;
            }
        }
    }

    .relationships__current-profiles {
        display: flex;
        margin-bottom: 15px;
        flex-wrap: wrap;

        .relationships__profile {
            background: #e0e0e0;
            border-radius: 15px;
            padding: 0px 15px;
            margin-right: 5px;
            margin-bottom: 5px;
        }
    }
}

.tox-notifications-container {
    display: none;
}

.institutions-modal {
    input:focus {
        border: 0px;
    }

    .select-search__select {
        height: 150px;
        overflow-y: scroll;
    }

    .select-search__row button {
        text-align: left;
    }

    .select-search.is-disabled {
        .select-search__value input {
            background: #e0e0e0;
        }
    }

    .institutions-modal__type {
        .select-search {
            margin-bottom: 25px;
        }
    }

    .institutions-modal__profiles {
        p {
            margin-bottom: 5px;
        }

        .select-search {
            margin-bottom: 25px;
        }
    }

    .btn {
        color: white;
        // min-width: 205px;
        min-height: 41px;
        
        svg {
            color: white;
        }
    }
}