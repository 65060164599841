.news-item-link,
.news-item-link:hover {
    text-decoration: none;
}

.news-page-heading {
    color: $primary;
    font-weight: 600;
}

.news-page-region {
    font-weight: 500;
}

.news-page-text {
    color: rgba($body-color,.85);
    font-family: "acumin-pro-semi-condensed", $font-family-sans-serif;
    opacity: .85;
}

.news-page-link {
    font-family: "acumin-pro-semi-condensed", $font-family-sans-serif;
}

.related-article {
    @include font-size(18px);
    border: 1px solid rgba(#b1b9cb,.6);
    border-radius: 4px;
    padding: 1rem;
    position: relative;

    a:hover {
        text-decoration: none !important;

        h3 {
            text-decoration: underline;
        }
    }

    h5 {
        @include font-size(18px);
        color: $primary;
        font-weight: 600;
        margin-bottom: .25rem;
    }

    h6 {
        @include font-size(18px);
        color: $body-color;
        font-weight: 500;
        opacity: .85;
        margin-bottom: 1rem;
    }
    
    a {
        color: $body-color;
    }
}

.news-archive {
    .news-archive__item {
        border: 1px solid rgba(177, 185, 203, 0.6);
        border-radius: 4px;
        padding: 1rem;
        margin-bottom: 1rem;

        .date {
            text-align: right;
            margin-bottom: 0px;
        }
    }
}