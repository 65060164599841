.four-oh-four {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    .main-title {
        font-size: 6.2vw;
        letter-spacing: -0.32px;
        background: linear-gradient(to right, rgb(247, 78, 80), map-get($theme-colors, "primary"));
        display: inline;
        background-clip: text;
        text-fill-color: transparent;
        -webkit-text-fill-color: transparent;
        font-weight: 700;
        padding-left: 3rem;
    }
}
