.form-control {
    &::placeholder {
        @include font-size(18px);
        color: $input-placeholder-color;
        font-family: "acumin-pro-semi-condensed", $font-family-sans-serif;
        font-weight: 500;
        letter-spacing: 0;
    }

    &:focus {
        border-color: transparent !important;
    }
}

.custom-control-label {
    color: #240708;
    font-family: "acumin-pro-semi-condensed", $font-family-sans-serif;
    opacity: .85;
}

.valid-feedback,
.invalid-feedback {
    @include font-size(14px);
    font-family: "acumin-pro-semi-condensed", $font-family-sans-serif;
    font-style: italic;
}

label .btn-link {
    border-radius: 0;
    display: inline;
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
    margin: 0;
    padding: 0;
    text-align: left;
    text-decoration: underline;
    vertical-align: baseline;

    &:hover {
        text-decoration: none;
    }
}

.modal-50w {
    width: 50%;
    max-width: none!important;
}

// .was-validated .custom-control-input:invalid ~ .custom-control-label,
// .custom-control-input.is-invalid ~ .custom-control-label {
//     color: inherit;
// }

.contact-form,
.network-create-account-form {
    .form-control {
        // border-bottom-left-radius: 0;
        // border-bottom-right-radius: 0;
        border-radius: 0;
        border-left: 0;
        border-right: 0;
        border-top: 0;
    }

    .input-with-icon {
        padding-right: 2rem;
    }

    .svg-inline--fa {
        @include font-size(22px);
        color: white;
    }

    label:not(.custom-control-label) {
        @include font-size(16px);
        color: #b1b9cb;
        font-family: "acumin-pro-semi-condensed", $font-family-sans-serif;
        font-weight: 500;
        letter-spacing: (20 / 1000) * 1em;
    }

    .custom-control-label {
        font-weight: 500;
        letter-spacing: 0;

        a {
            color: $primary;
            text-decoration: underline;

            &:hover {
                text-decoration: none;
            }
        }
    }
}

.alert.alert-success a {
    text-decoration: underline;
    color: #006c37;
}

#create-account-field-research-units label, 
#update-profile-field-research-units label {
    width: 100%;
}
